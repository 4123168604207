import { get, isEmpty, toLower } from 'lodash';
import axios from '../axios';

import { getRefreshToken, LOCAL_STORAGE } from '../helpers/general.helpers';
import { userServices } from '../services/user.services';

export const loginUser = async (payload) => {
  try {
    const { email, password } = payload;
    const dataToSend = {
      email: toLower(email),
      password,
    };
    const { data } = await userServices.loginUser(dataToSend);
    if (data.status === 2) {
      return false;
    }
    const tokens = JSON.stringify(data.tokens);
    localStorage.setItem(LOCAL_STORAGE.TOKEN, tokens);
    axios.defaults.headers.Authorization = `Bearer ${data.tokens.access_token}`;
    return true;
  } catch (error) {
    return false;
  }
};

export const refreshToken = async () => {
  try {
    const refreshToken = await getRefreshToken();
    const dataToSend = {
      token: refreshToken,
    };
    const { data } = await userServices.getTokenWithRefreshToken(dataToSend);
    const newToken = data.access_token;
    if (isEmpty(newToken)) {
      return false;
    }
    axios.defaults.headers.Authorization = `Bearer ${newToken}`;
    const localDataToStore = {
      access_token: newToken,
      refresh_token: refreshToken,
    };
    localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON.stringify(localDataToStore));
    return true;
  } catch (error) {
    // console.log('error', error);
    return false;
  }
};

export const fetchUser = async () => {
  try {
    const { data } = await userServices.getUserInfo();
    const paymentData = await fetchPaymentDetails();
    return {
      ...data,
      bank: paymentData.bank,
      branch: paymentData.branch,
      account: paymentData.account,
      owner_name: paymentData.owner_name,
      bank_acc_holder_id: paymentData.identifier_id,
    };
  } catch (error) {
    return {};
  }
};

export const resentOTPAction = async (token) => {
  try {
    const { data } = await userServices.resentOTP({ otp_token: token });
    return get(data, 'token', '');
  } catch (error) {
    return '';
  }
};

export const passwordReset = async (email) => {
  try {
    const { data } = await userServices.passwordReset({
      email: toLower(email),
    });
    return get(data, 'token', '');
  } catch (error) {
    return '';
  }
};

export const logoutUser = async () => {
  try {
    localStorage.clear();
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteUser = async () => {
  try {
    await userServices.deleteUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const updateUser = async (payload) => {
  try {
    await userServices.updateUser(payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const checkDataExists = async (payload) => {
  try {
    const { data } = await userServices.isDataExists(payload);
    return data.used;
  } catch (error) {
    return true;
  }
};

export const registerUser = async (payload) => {
  try {
    const { data } = await userServices.signUpUser(payload);
    return data;
  } catch (error) {
    return {};
  }
};

export const verfiyEmail = async (payload) => {
  try {
    await userServices.verifyEmail(payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchPaymentDetails = async () => {
  try {
    const { data } = await userServices.fetchPaymentDetails();
    return { ...data, bank_acc_holder_id: data.identifier_id };
  } catch (error) {
    return {};
  }
};

export const setPaymentDetails = async (payload) => {
  try {
    await userServices.setPaymentDetails(payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const passwordResetEmailVerification = async (payload) => {
  try {
    const { data } = await userServices.passwordResetEmailVerification(payload);
    return get(data, 'token', '');
  } catch (error) {
    return '';
  }
};

export const resetPassword = async (payload) => {
  try {
    await userServices.passwordChange(payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const paymentWithdraw = async () => {
  try {
    const { data } = await userServices.paymentWithdraw();
    return data;
  } catch (err) {
    // console.log(err);
    return err;
  }
};

export const addBirthdayPointsAction = async () => {
  try {
    const { data } = await userServices.addBirthdayPoints({});
    return data;
  } catch (err) {
    // console.log(err);
  }
};

export const addCharacterPropAction = async (index) => {
  try {
    const { data } = await userServices.addCharacterProp({
      prop_id: index,
    });
    return data;
  } catch (err) {
    // console.log(err);
  }
};

export const fetchInviteCodeAction = async () => {
  try {
    const { data } = await userServices.fetchInviteCode();
    return data;
  } catch (err) {
    // console.log(err);
  }
};

export const sabhatBlockUserAction = async (payload) => {
  try {
    const { data } = await userServices.sabhatBlockUser(payload);
    return get(data, 'closed', false);
  } catch (err) {
    // console.log(err);
  }
};

export const fechtiFormUrlAction = async () => {
  try {
    const { data } = await userServices.fetchiFormUrl();
    return get(data, 'url', '');
  } catch (error) {
    //
  }
};
