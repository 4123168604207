import { create } from 'zustand';

interface FilterSection {
  isVisible: boolean;
  currentScreen: 'Home' | 'Stats' | '';
  domainIndex: string;
  sortOption: string;
  menuItem: string;
  rangeMinValue: number;
  rangeMaxValue: number;
  homeScreenFilter?: {
    searchKey: string;
    // domainIndex: string;
    rangeMinValue: number;
    rangeMaxValue: number;
    sortOption: string;
  };

  statsScreenFilter?: {
    // domainIndex: string;
    searchKey: string;
    rangeMinValue: number;
    rangeMaxValue: number;
    sortOption: string;
  };

  setCurrentScreen: (data: 'Home' | 'Stats') => void;
  setIsVisible: (data: boolean) => void;
  setDomainIndex: (index: string) => void;
  setSortOptionIndex: (index: string) => void;
  setMenuItem: (index: string) => void;
  setRangeMaxValue: (index: number) => void;
  setRangeMinValue: (index: number) => void;

  setHomeScreenFilter: (data: {
    searchKey: string;
    // domainIndex: string;
    rangeMinValue: number;
    rangeMaxValue: number;
    sortOption: string;
  }) => void;

  setStatsScreenFilter: (data: {
    // domainIndex: string;
    searchKey: string;
    rangeMinValue: number;
    rangeMaxValue: number;
    sortOption: string;
  }) => void;
}

const filterSectionStore = create<FilterSection>((set) => ({
  isVisible: false,
  isHomeScreen: false,
  currentScreen: '',
  domainIndex: '',
  sortOption: '',
  menuItem: '',
  rangeMinValue: 0,
  rangeMaxValue: 2000,

  homeScreenFilter: {
    searchKey: '',
    // domainIndex: '',
    rangeMinValue: 0,
    rangeMaxValue: 2000,
    sortOption: '',
  },

  statsScreenFilter: {
    // domainIndex: '',
    searchKey: '',
    rangeMinValue: 0,
    rangeMaxValue: 2000,
    sortOption: '',
  },

  setCurrentScreen: (data) => {
    set((state) => ({
      ...state,
      currentScreen: data,
    }));
  },

  setIsVisible: (data) => {
    set((state) => ({
      ...state,
      isVisible: data,
    }));
  },

  setDomainIndex: (index) => {
    set((state) => ({
      ...state,
      domainIndex: index,
    }));
  },

  setSortOptionIndex: (index) => {
    set((state) => ({
      ...state,
      sortOption: index,
    }));
  },

  setMenuItem: (index) => {
    set((state) => ({
      ...state,
      menuItem: index,
    }));
  },

  setRangeMinValue: (index) => {
    set((state) => ({
      ...state,
      rangeMinValue: index,
    }));
  },

  setRangeMaxValue: (index) => {
    set((state) => ({
      ...state,
      rangeMaxValue: index,
    }));
  },

  setHomeScreenFilter: (data) => {
    set((state) => ({
      ...state,
      homeScreenFilter: { ...state.homeScreenFilter, ...data },
    }));
  },

  setStatsScreenFilter: (data) => {
    set((state) => ({
      ...state,
      statsScreenFilter: { ...state.statsScreenFilter, ...data },
    }));
  },
}));

export default filterSectionStore;
