import React from 'react';

import cx from 'classnames';

import Icons from '../../../components/icons';
import { ICONS } from '../../../constants/icons.constanst';

import styles from './levels.module.scss';

const Levels = ({ img, title, level, imgStyle, isLocked, opacity = 1, onClick }) => {
  return (
    <div className={styles.Levels} style={{ opacity: opacity }} onClick={onClick}>
      <div className={cx(styles.level, imgStyle)}>
        <img src={img} alt="" />
        <div className={styles.lock}>{isLocked && <Icons type={ICONS.LOCK} />}</div>
      </div>
      <p>{title}</p>
      <p>{level}</p>
    </div>
  );
};

export default Levels;
