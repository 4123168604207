import React from 'react';

import cx from 'classnames';

// import { Icon } from '..';

import styles from './button.module.scss';
import { CircularProgress } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  type?: 'Primary' | 'Secondary';
  disable?: boolean;
  prefixIcon?: string;
  suffixIcon?: string;
  isLoading?: boolean;
  buttonClassName?: string;
  prefixIconClassName?: string;
  suffixIconClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  //   onIconCLick?: () => void;
}

const Button: React.FC<Props> = (props) => {
  const {
    children,
    type,
    prefixIcon,
    suffixIcon,
    disable,
    isLoading,
    buttonClassName,
    prefixIconClassName,
    suffixIconClassName,
    onClick,
    // onIconCLick,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) onClick(e);
  };

  //   const handlePreFixIconClick = () => {
  //     if (onIconCLick) onIconCLick();
  //   };

  //   const handleSuffixIconClick = () => {
  //     if (onIconCLick) onIconCLick();
  //   };

  return (
    <button disabled={disable} className={cx(styles.button, buttonClassName)} onClick={handleClick}>
      {/* {prefixIcon && !isLoading && (
        <div
          className={cx(styles.prefixIcon, prefixIconClassName)} 
        >
          <Icon type={prefixIcon} />
        </div>
      )} */}

      {!isLoading ? (
        children
      ) : (
        <CircularProgress size={20} thickness={2.5} sx={{ color: '#000000' }} />
      )}

      {/* {suffixIcon && !isLoading && (
        <div className={cx(styles.suffixIcon, suffixIconClassName)}>
          <Icon type={suffixIcon} />
        </div>
      )} */}
    </button>
  );
};

export default Button;
