import React, { useState, useEffect, useMemo } from 'react';

import { get, orderBy } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import cx from 'classnames';

import {
  BackdropScreen,
  Button,
  FilterPopup,
  LeadWrapper,
  Popup,
  PopupType2,
} from '../../components';
import Icons from '../../components/icons';
import { ICONS } from '../../constants/icons.constanst';
import { chartData } from './leads.helpers';
import snackBarStore from '../../zustand/snackBarStore';
import useUserStore from '../../zustand/userStore';
import leadsStore from '../../zustand/leadsStore';
import filterSectionStore from '../../zustand/filterSection';
import { paymentWithdraw } from '../../actions/user.actions';
import { fetchCurrentState, fetchLinkToShare } from '../../actions/leads.actions';

import styles from './leads.module.scss';

const Graph = () => {
  const { showSnackBar, closeSnackBar } = snackBarStore((state) => state);

  const { myleads, setLeadData, setLeadLink } = leadsStore();

  const { setCurrentScreen } = filterSectionStore();

  const [selected, setSelected] = useState(1);

  const [stats, setStats] = React.useState({});
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalRegistered, setTotalRegistered] = useState(0);

  const [firstModalVisible, setFirstModalVisible] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);

  const [filterpopup, setFilterpopup] = useState(false);
  const [sort, setSort] = useState(true);
  const [currentSelectedLead, setCurrentSelectedLead] = useState({});
  const [loader, setLoader] = useState(false);

  const stringVsId = {
    1: 'day',
    2: 'week',
    3: 'month',
  };

  const data = chartData(stats, stringVsId[selected]);

  const filterSort = () => {
    setSort((prev) => !prev);
    const data = orderBy(myleads, (lead) => lead.created_at, sort ? 'asc' : 'desc');
    setLeadData(data);
  };

  const handleFilterIconClick = () => {
    setFilterpopup(true);
    setCurrentScreen('Stats');
  };

  const handleShareIconClick = (currentSelectedLead) => {
    setCurrentSelectedLead(currentSelectedLead);
    setFirstModalVisible(true);
  };

  const handleFilterClose = () => {
    setFilterpopup(false);
  };

  const handleSecondPopUp = () => {
    setSecondModalVisible(false);
  };

  const handlebtnClick = () => {
    setFirstModalVisible(false);
  };

  const handleClosed = async (data) => {
    setLoader(true);
    const response = await fetchLinkToShare(data);
    setLeadLink(response.data.link);
    setLoader(false);
    setFirstModalVisible(false);
    setSecondModalVisible(true);
  };

  const handleSecondPopupClose = () => {
    setSecondModalVisible(false);
    // setLinkCopied(true);
    showSnackBar();
    setTimeout(() => {
      closeSnackBar();
    }, 3000);
  };

  useEffect(() => {
    const fetchStatsData = async () => {
      const { data, totalProfit, totalRegistered } = await fetchCurrentState(stringVsId[selected]);
      setTotalProfit(totalProfit);
      setTotalRegistered(totalRegistered);
      setStats(data);
    };
    fetchStatsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const apexchart = useMemo(
    () => (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="line"
        height={200}
        key={`${selected}`}
      />
    ),
    [data.options, data.series, selected],
  );

  return (
    <div style={{ marginTop: '35px' }}>
      <div style={{ marginBottom: '40px' }}>
        <div className={styles.graphContainer}>
          <div className={styles.dataLayer}>
            <div className={styles.registered}>
              <p className={styles.registeredNumber}>{totalRegistered}</p>
              <p className={styles.text}>רשומים</p>
            </div>
            <div className={styles.earnings}>
              <div className={styles.earningsNumberConatiner}>
                <p className={styles.earningsNumber}>{totalProfit} </p>
                <p className={styles.sign}>₪</p>
              </div>
              <p className={styles.earningsText}>הרווחים שלי</p>
            </div>
          </div>
          <div className={styles.filterLayer}>
            <div className={styles.filterTypes}>
              <p
                className={cx(styles.filterText, { [styles.activeFilterText]: selected === 1 })}
                onClick={() => setSelected(1)}
              >
                יום
              </p>
              <p
                className={cx(styles.filterText, {
                  [styles.activeFilterText]: selected === 2,
                })}
                onClick={() => setSelected(2)}
              >
                שבוע
              </p>
              <p
                className={cx(styles.filterText, { [styles.activeFilterText]: selected === 3 })}
                onClick={() => setSelected(3)}
              >
                חודש
              </p>
            </div>

            <div className={styles.labeslContainer}>
              <div className={styles.label}>
                <p className={styles.graphText}>לחיצות</p>
                <div className={styles.dottedline}>---</div>
              </div>
              <div className={styles.label}>
                <p className={styles.graphText}>רשומים</p>
                <div className={styles.line}></div>
              </div>
            </div>
          </div>

          {/* <ReactApexChart
            options={data.options}
            series={data.series}
            type="line"
            height={200}
            key={`${selected}`}
          /> */}
          {apexchart}
        </div>
        <LeadWrapper
          Linevisible
          handleFilterOptionClick={handleFilterIconClick}
          handleClick={handleShareIconClick}
          leadsScreen={true}
        />
      </div>
      {firstModalVisible && (
        <BackdropScreen onClick={handlebtnClick}>
          <Popup
            isLoading={loader}
            handleClose={handlebtnClick}
            ClosedNextPage={handleClosed}
            data={currentSelectedLead}
          />
        </BackdropScreen>
      )}

      {secondModalVisible && (
        <BackdropScreen onClick={handleSecondPopUp}>
          <PopupType2
            data={currentSelectedLead}
            handleClosePopup={handleSecondPopUp}
            SecondPopupCloseMoveNext={handleSecondPopupClose}
          />
        </BackdropScreen>
      )}
      {filterpopup && (
        <BackdropScreen onClick={handleFilterClose}>
          <FilterPopup handleFilterPopupClose={handleFilterClose} handlesort={filterSort} />
        </BackdropScreen>
      )}
    </div>
  );
};

export default Graph;
