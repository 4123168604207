import React from 'react';

import { Checkbox } from '@mui/material';

const CheckBox = ({ setValue, value }) => {
  return (
    <div>
      <Checkbox
        sx={{
          color: '#DADADA',
          '&.Mui-checked': {
            color: '#3FE2D2',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
        }}
        onChange={() => setValue((prev) => !prev)}
        value={value}
      />
    </div>
  );
};

export default CheckBox;
