import * as React from 'react';
const SVGComponent = (props) => (
  <svg
    width={138}
    height={244}
    viewBox="0 0 138 244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.0113 242.342V187.011L52.6198 190.899V228.372L41.5308 238.802C39.912 240.325 40.9895 243.043 43.2118 243.043H63.3103C63.6975 243.043 64.0113 242.729 64.0113 242.342Z"
      fill="#222222"
    />
    <path
      d="M74.5276 242.342V187.011L85.9191 190.899V228.372L97.0082 238.802C98.6269 240.325 97.5494 243.043 95.3271 243.043H75.2286C74.8415 243.043 74.5276 242.729 74.5276 242.342Z"
      fill="#222222"
    />
    <path
      d="M19.4668 156.564C11.7257 173.539 7.29065 189.692 7.16284 199.495"
      stroke="#222222"
      strokeWidth={7.29725}
      strokeLinecap="round"
    />
    <path
      d="M117.41 156.564C125.151 173.539 129.586 189.692 129.714 199.495"
      stroke="#222222"
      strokeWidth={7.29725}
      strokeLinecap="round"
    />
    <path
      d="M15.7163 56.9987C15.7163 43.8822 26.3493 33.2492 39.4658 33.2492H96.4645C109.581 33.2492 120.214 43.8822 120.214 56.9987V156.408C120.214 182.828 98.7963 204.245 72.3764 204.245H63.5539C37.1339 204.245 15.7163 182.828 15.7163 156.408V56.9987Z"
      fill="#A573FE"
    />
    <path
      d="M15.7163 153.716H120.214V198.826C120.214 207.334 113.317 214.231 104.809 214.231H31.1216C22.6135 214.231 15.7163 207.334 15.7163 198.826V153.716Z"
      fill="#222222"
    />
    <path
      d="M49.4387 111.558C53.1139 115.463 60.0883 118.1 68.0873 118.1C76.0862 118.1 83.0606 115.463 86.7358 111.558"
      stroke="black"
      strokeWidth={5.60814}
      strokeLinecap="round"
    />
    <ellipse cx={49.1619} cy={85.8431} rx={15.4053} ry={15.5375} fill="white" />
    <ellipse cx={49.3745} cy={86.2262} rx={6.08104} ry={6.13324} fill="black" />
    <ellipse cx={87.2724} cy={85.8408} rx={15.4053} ry={15.5375} fill="white" />
    <ellipse cx={87.485} cy={86.2262} rx={6.08104} ry={6.13324} fill="black" />
  </svg>
);
export default SVGComponent;
