import { get } from 'lodash';
import { leadsService } from '../services/leads.services';

export const fetchLeadsData = async (searchKey, valuation) => {
  try {
    const response = await leadsService.fetchLeadData(searchKey, valuation);

    return response;
  } catch (err) {
    // console.log(err);
  }
};

export const fetchLinkToShare = async (data) => {
  try {
    const response = await leadsService.fetchLink(data);

    return response;
  } catch (err) {
    // console.log(err);
    return {};
  }
};

export const fetchMyLeads = async (searchKey = '', valuation = '') => {
  try {
    const { data } = await leadsService.fetchMyLeads(searchKey, valuation);
    return get(data, 'leads', []);
  } catch (err) {
    return [];
    // console.log(err);
  }
};
export const generateRandomData = (n) => {
  const random = [];
  for (let i = 0; i < n; i++) {
    const randomNumber = parseInt(Math.random() * 5);
    random.push(randomNumber);
  }
  return random;
};

export const fetchCurrentState = async (id) => {
  try {
    const { data } = await leadsService.fetchCurrentState(id);

    return {
      data: get(data, 'report.data', []),
      totalProfit: data.total_profit,
      totalRegistered: data.total_registered,
    };
  } catch (err) {
    return {};
    // console.log(err);
  }
};
