import React, { useEffect, useState } from 'react';

import { map, size } from 'lodash';
import cx from 'classnames';

import { Button, Input } from '../../components';
import { ICONS } from '../../constants/icons.constanst';
import { BANK_LIST } from '../../constants/banklist';
import useUserStore from '../../zustand/userStore';
import { setPaymentDetails } from '../../actions/user.actions';
import { ROUTES } from '../../constants/routes.constant';
import { useNavigate } from 'react-router-dom';
import { isEmptyError } from '../../helpers/general.helpers';
import publico from '../../assets/publico.png';

import styles from './paymentTransfer.module.scss';

const getBankLable = (number) => {
  const bank = BANK_LIST.find((item) => item.value === `${number}`);
  return bank?.label;
};

const PaymentTransfer = () => {
  const navigate = useNavigate();
  const {
    updateUser,
    user: { userData },
  } = useUserStore((state) => state);

  const [isConfirm, setIsConfirm] = useState(false);

  const [open, setOpen] = useState(false);

  const [bank, setBank] = useState(null);
  const [branch, setBranch] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [name, setName] = useState('');
  //
  const [bankConfirm, setBankConfirm] = useState(null);
  const [branchConfirm, setBranchConfirm] = useState('');
  const [accountNumberConfirm, setAccountNumberConfirm] = useState('');
  //
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');

  const handleCancel = () => {
    if (isConfirm) {
      setIsConfirm(false);
      setBankConfirm(null);
      setBranchConfirm('');
      setAccountNumberConfirm('');
      //
      setErrors({});
    } else navigate(ROUTES.PROFILE);
  };

  const handleSave = async () => {
    //* update the data here for backend

    if (isConfirm) {
      // felds doesnt match error, ex: bank does not match
      const err = {
        bank: bankConfirm !== bank ? 'לא מתאים' : '',
        branch: branchConfirm !== branch ? 'לא מתאים' : '',
        accountNumber: accountNumberConfirm !== accountNumber ? 'לא מתאים' : '',
      };
      // console.log('err', branch, branchConfirm);
      if (!isEmptyError(err)) {
        setErrors(err);
        return;
      }

      setIsLoading(true);
      const payload = {
        // bank: getBankLable(bank),
        bank,
        branch,
        account: accountNumber,
        identifier_id: idNumber,
        owner_name: name,
      };
      const isSuccess = await setPaymentDetails(payload);
      setIsLoading(false);
      if (isSuccess) {
        updateUser({
          bank,
          branch,
          account: accountNumber,
          bank_acc_holder_id: idNumber,
          owner_name: name,
        });
        navigate(ROUTES.PROFILE);
      } else {
        setApiError('משהו השתבש');
      }
    } else {
      const err = {
        bank: size(bank) > 2 ? 'בנקאי לא חוקי' : '',
        branch: size(branch) > 3 ? 'סניף בנק לא חוקי' : '',
        accountNumber: size(accountNumber) > 9 ? 'מספר חשבון בנק לא חוקי' : '',
      };

      if (!isEmptyError(err)) {
        setErrors(err);
        return;
      }
      setIsConfirm(true);
    }
  };

  useEffect(() => {
    setBank(parseInt(userData.bank || 0));
    setBankConfirm(parseInt(userData.bank || 0));

    setBranch(`${userData.branch || ''}`);
    // const accountNumber = userData.account;
    // const sub = accountNumber?.length - 4;
    // setAccountToShow(accountNumber?.substring(0, accountNumber?.length - sub));
    setAccountNumber(userData.account);
    setIdNumber(userData.identity_id);
    setName(userData.username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {open && <div className={styles.backdrop} onClick={() => setOpen(!open)}></div>}

      <div className={styles.PaymentTransfer}>
        <div className={styles.img}>
          <img src={publico} alt="" />
        </div>

        <p className={styles.paymentMethod}>
          {!isConfirm ? 'בחר אמצעי לקבלת תשלום' : 'אנא מלא פעם נוספת כדי לאמת את הנתונים'}
        </p>

        <div className={styles.dropdownContainer}>
          <Input
            placeholder="בחר בנק"
            endIcon={ICONS.DOWNARROW}
            className={open && styles.input}
            onClick={() => setOpen(!open)}
            value={isConfirm ? getBankLable(bankConfirm) : getBankLable(bank)}
            error={errors.bank}
          />
          {open && (
            <div className={styles.dropdownMenu}>
              {map(BANK_LIST, (item, index) => {
                return (
                  <div
                    className={cx(styles.dropdownItem, {
                      [styles.active]: isConfirm ? bankConfirm === item.value : bank === item.value,
                    })}
                    key={`${item.label}`}
                    onClick={() => {
                      isConfirm ? setBankConfirm(item.value) : setBank(item.value);
                      setOpen(false);
                    }}
                  >
                    {/* <img src={item.image} alt="bank" /> */}
                    {item.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <Input
          placeholder="סניף"
          value={isConfirm ? branchConfirm : branch}
          setValue={isConfirm ? setBranchConfirm : setBranch}
          error={errors.branch}
        />
        <Input
          placeholder="מס׳ חשבון"
          value={isConfirm ? accountNumberConfirm : accountNumber}
          setValue={(v) => {
            isConfirm ? setAccountNumberConfirm(v) : setAccountNumber(v);
          }}
          error={errors.accountNumber}
        />
        <Input placeholder="מס׳ ת.ז." value={idNumber} setValue={setIdNumber} disable />
        <Input placeholder="שם בעל חשבון" value={name} setValue={setName} disable />
        {apiError && <p className={styles.apiError}>{apiError}</p>}

        {
          // isConfirm ? ( SaveButton ) : ( Next Button)
          // isConfirm ? ( Back Button ) : ( Cancel Button)
        }
        <Button onClick={handleSave} buttonClassName={styles.btn}>
          {isConfirm ? 'שמירה' : 'המשך'}
        </Button>

        <Button onClick={handleCancel} buttonClassName={styles.btn2}>
          {/* לבטל ולחזור */}
          {isConfirm ? 'לחזור' : 'לבטל ולחזור'}
        </Button>
      </div>
    </div>
  );
};

export default PaymentTransfer;
