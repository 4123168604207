import React from 'react';
import styles from './Menu.module.scss';
import BackdropScreen from '../backdrop/BackdropScreen';

const Menu = ({ arr = [], setValue }) => {
  return (
    <div>
      {/* <BackdropScreen /> */}
      <div className={styles.Menu}>
        {arr.map((items, index) => (
          <>
            <div
              className={styles.description}
              onClick={() => {
                setValue(items.company_name);
              }}
            >
              <p className={styles.value1} key={index}>
                {items.company_name}
              </p>
              <div className={styles.colorLine}></div>
              <p className={styles.value2} key={items.id}>
                {items.category}
              </p>
            </div>

            <div className={styles.ColorBorder}></div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Menu;
