import * as React from 'react';
const Message = (props) => (
  <svg
    width={200}
    height={32}
    viewBox="0 0 80 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H35.3812L39.567 31.25C39.7594 31.5833 40.2406 31.5833 40.433 31.25L44.6188 24H76C78.2091 24 80 22.2091 80 20V4C80 1.79086 78.2091 0 76 0H4Z"
      fill="#9EA6B4"
    />
  </svg>
);
export default Message;
