import * as React from 'react';
const User = (props) => (
  <svg
    width={13}
    height={15}
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4841 11.5316C12.4606 11.2139 12.4202 10.8977 12.3629 10.5843C12.3062 10.2619 12.2284 9.94368 12.1299 9.63155C12.0303 9.32258 11.899 9.02474 11.7381 8.74279C11.5796 8.459 11.3807 8.19976 11.1476 7.97321C10.902 7.74222 10.6139 7.56112 10.2992 7.43996C9.95371 7.30684 9.58612 7.24036 9.21587 7.24403C8.99985 7.26724 8.79555 7.35392 8.62876 7.49315C8.45303 7.60761 8.247 7.74092 8.0174 7.8877C7.76912 8.0362 7.50338 8.15335 7.22628 8.23647C6.59788 8.44501 5.91894 8.44501 5.29054 8.23647C5.01365 8.15335 4.74814 8.0362 4.50009 7.8877C4.27251 7.74227 4.06648 7.60963 3.88806 7.49315C3.72133 7.35382 3.51699 7.26712 3.30094 7.24403C2.93091 7.24034 2.56354 7.30683 2.21827 7.43996C1.90361 7.56106 1.61548 7.74216 1.36992 7.97321C1.13683 8.19977 0.937927 8.45901 0.779434 8.74279C0.618554 9.02476 0.487237 9.3226 0.387574 9.63155C0.289167 9.94369 0.211346 10.2619 0.154612 10.5843C0.0972404 10.8979 0.056784 11.2143 0.0334181 11.5323C0.0125458 11.8184 0.00244635 12.1167 0.00244635 12.417C-0.0130127 12.7656 0.0449272 13.1135 0.172513 13.4383C0.300098 13.763 0.494483 14.0574 0.743076 14.3022C1.26172 14.7766 1.94691 15.0266 2.64919 14.9978H9.86898C10.571 15.0265 11.256 14.7765 11.7744 14.3022C12.023 14.0574 12.2174 13.763 12.345 13.4383C12.4726 13.1135 12.5305 12.7656 12.515 12.417C12.515 12.1147 12.5049 11.8171 12.4847 11.5309L12.4841 11.5316Z"
      fill="#9EA6B4"
    />
    <path
      d="M6.16581 7.22394C6.64075 7.23017 7.11198 7.13953 7.55072 6.95755C7.98947 6.77557 8.38651 6.50607 8.71762 6.16551C9.05842 5.83417 9.32807 5.4368 9.51005 4.99769C9.69204 4.55858 9.78255 4.08697 9.77604 3.61168C9.78251 3.13641 9.69198 2.6648 9.51 2.2257C9.32801 1.7866 9.05839 1.38922 8.71762 1.05786C8.38225 0.722478 7.9841 0.456438 7.54592 0.274929C7.10773 0.0934212 6.63808 0 6.16379 0C5.6895 0 5.21985 0.0934212 4.78166 0.274929C4.34347 0.456438 3.94533 0.722478 3.60996 1.05786C3.26915 1.3892 2.99952 1.78657 2.81753 2.22568C2.63554 2.66479 2.54503 3.1364 2.55154 3.61168C2.54468 4.08677 2.63477 4.55827 2.81629 4.99737C2.99781 5.43647 3.26695 5.83394 3.60727 6.16551C3.93918 6.50687 4.33733 6.77683 4.77729 6.95883C5.21724 7.14083 5.68974 7.23105 6.16581 7.22394Z"
      fill="#9EA6B4"
    />
  </svg>
);
export default User;
