import React from 'react';

import cx from 'classnames';

import CrossIcon from '../icons/CrossIcon';
import Button from '../button/Button';
import Gift from '../icons/Gift';
import NewSubscriber from '../../assets/NewSubscriber.png';

import styles from './newSubscriberPopup.module.scss';

const NewSubscriberPopup = ({ difference, onClick }) => {
  return (
    <div className={cx(styles.BirthdayPopup)}>
      <div className={styles.avtar}>
        <img src={NewSubscriber} alt="" />
      </div>

      <div className={styles.main}>
        <div className={styles.navbar}>
          <p className={styles.title}>שאפו!</p>
          <div className={styles.crossIcon} onClick={onClick}>
            <CrossIcon />
          </div>
        </div>
        <p className={styles.information}>{`הצטרפו לך ${difference.leadsDiff} רשומים חדשים`}</p>
        <p className={styles.information1}>רק על כל הטוב הזה קיבלת:</p>
        <div className={styles.birthdayDetails}>
          <p className={styles.count}>{difference.leadsDiff * 2}</p>
          <p className={styles.wishes}>נקודות!</p>
        </div>

        <div className={styles.btn}>
          <Button onClick={onClick}>מעולה, תודה</Button>
        </div>
      </div>
    </div>
  );
};

export default NewSubscriberPopup;
