import * as React from 'react';

const Lock = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3158 9.91667H17.6842V7.625C17.6842 6.13316 17.0853 4.70242 16.0193 3.64752C14.9533 2.59263 13.5075 2 12 2C10.4925 2 9.04665 2.59263 7.98066 3.64752C6.91466 4.70242 6.31579 6.13316 6.31579 7.625V9.91667H5.68421C5.23753 9.91667 4.80914 10.0923 4.49329 10.4048C4.17744 10.7174 4 11.1413 4 11.5833V20.3333C4 20.7754 4.17744 21.1993 4.49329 21.5118C4.80914 21.8244 5.23753 22 5.68421 22H18.3158C18.7625 22 19.1909 21.8244 19.5067 21.5118C19.8226 21.1993 20 20.7754 20 20.3333V11.5833C20 11.1413 19.8226 10.7174 19.5067 10.4048C19.1909 10.0923 18.7625 9.91667 18.3158 9.91667ZM10.3158 14.9167C10.3158 14.4746 10.4932 14.0507 10.8091 13.7382C11.1249 13.4256 11.5533 13.25 12 13.25C12.4467 13.25 12.8751 13.4256 13.1909 13.7382C13.5068 14.0507 13.6842 14.4746 13.6842 14.9167C13.6824 15.2075 13.6038 15.4929 13.456 15.7443C13.3083 15.9958 13.0967 16.2046 12.8421 16.35V18.25C12.8421 18.471 12.7534 18.683 12.5955 18.8393C12.4375 18.9955 12.2233 19.0833 12 19.0833C11.7767 19.0833 11.5625 18.9955 11.4045 18.8393C11.2466 18.683 11.1579 18.471 11.1579 18.25V16.35C10.9033 16.2046 10.6917 15.9958 10.544 15.7443C10.3962 15.4929 10.3176 15.2075 10.3158 14.9167ZM8.42105 7.625C8.42105 6.68569 8.79812 5.78485 9.4693 5.12066C10.1405 4.45647 11.0508 4.08333 12 4.08333C12.9492 4.08333 13.8595 4.45647 14.5307 5.12066C15.2019 5.78485 15.5789 6.68569 15.5789 7.625V9.5C15.5789 9.61051 15.5346 9.71649 15.4556 9.79463C15.3767 9.87277 15.2696 9.91667 15.1579 9.91667H8.84211C8.73044 9.91667 8.62334 9.87277 8.54438 9.79463C8.46541 9.71649 8.42105 9.61051 8.42105 9.5V7.625Z"
      fill="#B7BBC4"
    />
  </svg>
);
export default Lock;
