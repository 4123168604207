export const ROUTES = {
  HOME_PAGE: '/',
  SIGN_UP: '/sign-up',
  PERSONAL_INFO: '/personal-info',
  EMAIL_VERIFICATION: '/email-verification',
  OTP_SCREEN: '/otp-screen',
  USERNAME: '/username',
  'TERMS&CONDITION': '/T&C',

  SIGN_IN: '/sign-in',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',

  SIGN_UP_SUCCESS: '/sign-up-success',

  LEADS: '/leads',

  PROFILE: '/profile',
  UPDATE_PERSONAL_INFO: '/update-personal-info',
  PAYMENT_TRANSFER: '/payment-transfer',

  ERROR: '/error',
};
