import { size } from 'lodash';

export const validatePassword = (val) => {
  const checkNumber = /^(?=.*\d).*$/;
  const checkLetter = /^(?=.*[a-zA-Z]).*$/;
  const checkSpecialCharacter = /^(?=.*[!@#$%^&*]).*$/;
  const isLengthOk = size(val) >= 8;
  return [
    checkLetter.test(val),
    checkNumber.test(val),
    checkSpecialCharacter.test(val),
    isLengthOk,
  ];
};
