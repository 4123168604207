import * as React from 'react';
const Search = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={11} cy={11.6895} r={7} stroke="#3FE2D2" strokeWidth={2} />
    <line
      x1={19.535}
      y1={20.6392}
      x2={15.9995}
      y2={17.1036}
      stroke="#3FE2D2"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default Search;
