import * as React from 'react';
const Background = (props) => (
  <svg
    width={169}
    height={41}
    viewBox="0 0 169 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M168.131 0.0512695H0.50708C23.0238 32.5105 37.5067 40.9998 83.5687 40.9998C130.103 40.9998 148.617 31.0123 168.131 0.0512695Z"
      fill="#40E0D0"
    />
  </svg>
);
export default Background;
