import React, { useState } from 'react';

import { every, isEmpty, isEqual, map, toLower } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';

import { Input, Button, Header1, PasswordChecker } from '../../components';
import { ROUTES } from '../../constants/routes.constant';
import { ICONS } from '../../constants/icons.constanst';
import { validatePassword } from '../../helpers/signUp.helpers';
import { validateEmail } from '../../helpers/general.helpers';
import { checkDataExists } from '../../actions/user.actions';

import styles from './SignUp.module.scss';

const passowardErrorLabel = ['אותיות באנגלית', 'מספרים', 'תווים מיוחדים @#$%^', '8 תווים'];

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inviteCode = searchParams.get('invite');

  console.log('inviteCode', inviteCode);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [canSeePassword, setCanSeePassword] = useState(false);
  const [canSeeConfirmPassword, setCanSeeConfirmPassword] = useState(false);

  const [emailErr, setEmailErr] = useState('');
  const [passwordErros, setPasswordErros] = useState(Array(4).fill(false));
  const [confirmPasswordError, setCofirmPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmail = (value) => {
    setEmail(value);
    setEmailErr('');
  };

  const handlePasswordChange = (val) => {
    setPasswordErros(validatePassword(val));
    setPassword(val);
  };

  const checkButtonDisabled = () => {
    if (isEmpty(email) || isEmpty(password) || isEmpty(confirmPassword)) return true;
    if (!every(passwordErros, (item) => item)) return true;
    return false;
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      // console.log('object');
      setEmailErr('הקלד כתובת אימייל תקינה');
      return;
    }

    if (!isEqual(password, confirmPassword)) {
      setCofirmPasswordError('error');
      return;
    }

    setIsLoading(true);
    const isEmailExists = await checkDataExists({ email: toLower(email) });
    setIsLoading(false);
    if (isEmailExists) {
      setGeneralError('האימייל כבר קיים');
      return;
    }

    setGeneralError('');

    navigate(ROUTES.PERSONAL_INFO, {
      state: { email, password, confirmPassword, inviteCode: inviteCode || '' },
    });
  };

  return (
    <div className={styles.signup}>
      <Header1 title={'הרשמה'} description={'באמצעות אימייל וסיסמה'} />
      <Input placeholder={'אימייל'} setValue={handleEmail} value={email} error={emailErr} />
      <Input
        type={canSeePassword ? 'text' : 'password'}
        placeholder={'סיסמה'}
        endIcon={canSeePassword ? ICONS.CLOSEEYE : ICONS.EYE}
        handleIconClick={() => setCanSeePassword((prev) => !prev)}
        setValue={handlePasswordChange}
        value={password}
        error={confirmPasswordError && true}
      />
      <Input
        type={canSeeConfirmPassword ? 'text' : 'password'}
        placeholder={'אישור סיסמה'}
        endIcon={canSeeConfirmPassword ? ICONS.CLOSEEYE : ICONS.EYE}
        handleIconClick={() => setCanSeeConfirmPassword((prev) => !prev)}
        className={styles.input}
        setValue={(value) => {
          setConfirmPassword(value);
          setCofirmPasswordError('');
        }}
        value={confirmPassword}
        error={confirmPasswordError && true}
      />

      <div className={styles.checking}>
        {map(passowardErrorLabel, (label, index) => (
          <div key={label}>
            {passwordErros[index] ? (
              <PasswordChecker text={label} iconType={ICONS.SUCCESS} success={true} />
            ) : (
              <PasswordChecker text={label} />
            )}
          </div>
        ))}

        {!isEmpty(confirmPasswordError) && (
          <PasswordChecker text={'הסיסמאות לא זהות'} iconType={ICONS.Failure} error={true} />
        )}

        {generalError && (
          <PasswordChecker text={generalError} iconType={ICONS.Failure} error={true} />
        )}
      </div>

      <Button disable={checkButtonDisabled()} onClick={handleSubmit} isLoading={isLoading}>
        המשך
      </Button>
      <p className={styles.existingAcc}>
        {'יש כבר חשבון? '}
        <span className={styles.login} onClick={() => navigate(ROUTES.SIGN_IN)}>
          התחבר עכשיו
        </span>
      </p>
    </div>
  );
};

export default SignUp;
