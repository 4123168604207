import * as React from 'react';
const ShareIcon = (props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.31714 8.29213C6.03714 7.73238 5.57598 7.28343 5.0084 7.01805C4.44082 6.75266 3.8001 6.68639 3.19007 6.82998C2.58005 6.97357 2.03648 7.3186 1.64746 7.80915C1.25844 8.2997 1.04678 8.90702 1.04678 9.53267C1.04678 10.1583 1.25844 10.7656 1.64746 11.2562C2.03648 11.7467 2.58005 12.0918 3.19007 12.2354C3.8001 12.3789 4.44082 12.3127 5.0084 12.0473C5.57598 11.7819 6.03714 11.333 6.31714 10.7732"
      stroke="#222222"
      strokeWidth={1.37046}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2634 7.10314C14.8706 7.10462 15.4616 6.90827 15.9468 6.54391C16.4319 6.17954 16.7847 5.66708 16.9515 5.08434C17.1184 4.5016 17.0901 3.88042 16.8711 3.31516C16.6521 2.7499 16.2543 2.27144 15.738 1.95244C15.2218 1.63345 14.6154 1.49134 14.0109 1.54772C13.4064 1.60409 12.8368 1.85586 12.3887 2.26479C11.9406 2.67372 11.6384 3.21746 11.528 3.81344C11.4177 4.40942 11.5052 5.02506 11.7773 5.56687"
      stroke="#222222"
      strokeWidth={1.37046}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7776 13.4975C11.507 14.038 11.4204 14.6518 11.531 15.2459C11.6415 15.8399 11.9432 16.3818 12.3901 16.7892C12.8371 17.1966 13.4049 17.4474 14.0076 17.5035C14.6103 17.5596 15.2148 17.418 15.7295 17.1001C16.2442 16.7822 16.641 16.3053 16.8597 15.7419C17.0784 15.1785 17.107 14.5593 16.9412 13.9782C16.7754 13.3971 16.4243 12.8858 15.9411 12.5219C15.4579 12.1581 14.869 11.9614 14.2637 11.9619"
      stroke="#222222"
      strokeWidth={1.37046}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2632 7.10316C13.7468 7.10269 13.2407 6.95874 12.8016 6.68741C12.3625 6.41608 12.0078 6.02809 11.7771 5.56689L6.31729 8.29164C6.51207 8.67638 6.61356 9.10144 6.61356 9.53252C6.61356 9.96361 6.51207 10.3887 6.31729 10.7734L11.7771 13.4988C12.0078 13.0376 12.3625 12.6497 12.8016 12.3783C13.2407 12.107 13.7468 11.963 14.2632 11.9626"
      stroke="#222222"
      strokeWidth={1.37046}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ShareIcon;
