import * as React from 'react';

const DownArrow = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="icon/dropdown">
      <path
        id="Vector"
        d="M18 10L12 16L6 10"
        stroke="#9EA6B4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default DownArrow;
