import React, { useState, useEffect } from 'react';

import { get, isEmpty, size } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import 'dayjs/locale/he';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Button, Header1, Input } from '../../components';
import Icons from '../../components/icons';
import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constant';
import { containsOnlyNumbers, isEmptyObject } from '../../helpers/general.helpers';
import { checkDataExists } from '../../actions/user.actions';

import styles from './personalInfo.module.scss';

const useStyles = makeStyles((theme) => ({
  textField: {
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
}));

const PersonalInfo = () => {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const [personalInfo, setPersonalInfo] = useState({});
  const [calendar, setCalendar] = useState(dayjs(new Date()));
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (id) => (value) => {
    let isError = false;
    const err = {};

    //
    if (id === 'firstName' || id === 'lastName') {
      // remove numbers from

      if (!/^[a-zA-Z\u0590-\u05fe ]*$/.test(value)) {
        return;
      }
      setPersonalInfo({
        ...personalInfo,
        [id]: value,
      });
      if (id === 'firstName' && size(value) <= 1) {
        err['firstName'] = 'הזן לפחות 2 תווים';
        isError = true;
      }
      if (id === 'lastName' && size(value) <= 1) {
        err['lastName'] = 'הזן לפחות 2 תווים';
        isError = true;
      }
    } else {
      setPersonalInfo({
        ...personalInfo,
        [id]: value,
      });
    }

    if (isError) {
      setErrors(err);
      return;
    }

    setErrors({
      ...errors,
      [id]: '',
    });
  };

  const checkButtonDisabled = () => {
    if (
      isEmpty(personalInfo.firstName) ||
      isEmpty(personalInfo.lastName) ||
      isEmpty(personalInfo.id)
    )
      return true;
    return false;
  };

  const validateName = (value) => {
    if (size(value) <= 1) return 'הזן לפחות 2 תווים';
    return '';
  };

  const validateId = async (value) => {
    if (size(value) !== 9) return 'מספר לא תקין';
    if (!containsOnlyNumbers(value)) return 'נא להזין רק מספרים';

    const isIdExists = await checkDataExists({ identity_id: personalInfo?.id });
    console.log('====================================');
    console.log('isIdExists', isIdExists);
    console.log('====================================');
    if (isIdExists) return 'המזהה הזה כבר קיים';
    return '';
  };

  const validatePhone = (value) => {
    if (size(value) !== 10) return 'מספר לא תקין';
    if (!containsOnlyNumbers(value)) return 'נא להזין רק מספרים';
    return '';
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let isError = false;

    const idError = await validateId(personalInfo.id);
    const err = {
      firstName: validateName(personalInfo.firstName),
      lastName: validateName(personalInfo.lastName),
      id: idError,
      phone: validatePhone(personalInfo.phone),
    };

    if (new Date(calendar) > new Date()) {
      err['calendar'] = 'תאריך לא חוקי';

      isError = true;
    }

    if (new Date().getFullYear() - new Date(calendar)?.getFullYear() < 18) {
      alert('תאריך לידה לא חוקי');
      isError = true;
    }

    setIsLoading(false);

    if (isError || !isEmptyObject(err)) {
      setErrors(err);
      return;
    }

    navigate(ROUTES['TERMS&CONDITION'], {
      state: { ...location.state, ...personalInfo, dob: calendar.format('DD/MM/YYYY') },
    });
  };

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate(ROUTES.SIGN_UP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className={styles.PersonalInfo}>
      <Header1 title={'פרטים אישיים'} description={'יש למלא את כל השדות'} />
      <Input
        placeholder={'שם פרטי'}
        setValue={handleChange('firstName')}
        value={get(personalInfo, 'firstName', '')}
        error={errors.firstName}
      />
      <Input
        placeholder={'שם משפחה'}
        setValue={handleChange('lastName')}
        value={get(personalInfo, 'lastName', '')}
        error={errors.lastName}
      />
      <Input
        placeholder={'ת.ז'}
        setValue={handleChange('id')}
        value={get(personalInfo, 'id', '')}
        error={errors.id}
      />
      <Input
        placeholder={'מס׳ נייד'}
        setValue={handleChange('phone')}
        value={get(personalInfo, 'phone', '')}
        error={errors.phone}
      />
      {/* <Input
        type="calendar"
        placeholder={'תאריך לידה'}
        endIcon={ICONS.CALENDAR}
        className={styles.input}
      /> */}

      <LocalizationProvider adapterLocale={'he-IL'} dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={{ ':root': { padding: 0 } }}>
          {/* <ThemeProvider theme={theme}> */}
          <DatePicker
            openTo="month"
            views={['year', 'month', 'day']}
            format="DD/MM/YYYY"
            label="תאריך לידה"
            onChange={(val) => setCalendar(val)}
            slotProps={{
              textField: {
                className: { classes },
                size: 'small',
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  borderColor: '#B7BBC4',
                  width: 428,
                  // marginBottom: '70px',
                },
                InputLabelProps: {
                  sx: {
                    '&.Mui-focused': {
                      color: '#222222',
                      borderColor: '#222222',
                    },

                    fontFamily: 'Open Sans',
                    fontstyle: 'normal',
                    fontweight: 400,
                    lineHeight: '16px',
                    fontSize: '14px',
                    letterSpacing: 1,
                    color: '#B7BBC4',

                    '&:hover': {
                      color: '#222222',
                      borderColor: '#222222',
                    },
                  },
                },

                InputProps: {
                  sx: {
                    fontFamily: 'Open Sans',
                    fontstyle: 'normal',
                    fontweight: 400,
                    lineHeight: '16px',
                    caretColor: '#3FE2D2',
                    caretWidth: '1.5px',
                  },
                  // endAdornment: (
                  //   // <div style={{ svg: { path: { fill: 'red' } } }}>
                  //   <Icons type={ICONS.CALENDAR} />
                  //   // </div>
                  // ),
                },
              },
            }}
          />
          {/* </ThemeProvider> */}
        </DemoContainer>
      </LocalizationProvider>
      <p className={styles.error}>{errors.calendar}</p>

      <Button
        isLoading={isLoading}
        buttonClassName={styles.btn}
        disable={checkButtonDisabled()}
        onClick={!isLoading && handleSubmit}
      >
        המשך
      </Button>
    </div>
  );
};

export default PersonalInfo;
