import React from 'react';
import Header2 from '../../components/Header2/Header2';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';

import styles from './emailVerification.module.scss';

const EmailVerification = () => {
  return (
    <div className={styles.EmailVerification}>
      <Header2 title={'אימות משתמש'} description={'יש למלא מספר נייד לקבלת קוד אימות'} />
      <Input placeholder={'מס׳ נייד'} className={styles.input} />
      <Button>שלח קוד</Button>
    </div>
  );
};

export default EmailVerification;
