import axios from '../axios';

const signUpUser = async (payload) => axios.post('/v1/user/sign-up', payload);

const verifyEmail = async (payload) => axios.post('/v1/user/email/verify', payload);

const loginUser = async (payload) => axios.post('/v1/user/login', payload);

const deleteUser = async () => axios.delete('v1/user/delete');

const isDataExists = async (params) => axios.get('/v1/user/in-use', { params });

const getUserInfo = async () => axios.get('/v1/user/user-info');

const paymentWithdraw = () => axios.post('/v1/user/payment/withdraw');

const getTokenWithRefreshToken = (payload) => axios.post('/v1/refresh-token', payload);

const updateUser = async (payload) => axios.post('/v1/user', payload);

const fetchPaymentDetails = async () => axios.get('/v1/user/payment/bank-account');

const setPaymentDetails = async (payload) => axios.post('/v1/user/payment/bank-account', payload);

const passwordReset = async (payload) => axios.post('/v1/user/password/reset', payload);

const passwordResetEmailVerification = (payload) => axios.post('/v1/user/password/verify', payload);

const passwordChange = async (payload) => axios.post('/v1/user/password/change-password', payload);

const addBirthdayPoints = async (payload) => axios.post('/v1/user/birthday', payload);

const resentOTP = async (payload) => axios.post('/v1/user/sign-up-otp', payload);

const addCharacterProp = async (payload) => axios.post('/v1/user/prop', payload);

const fetchInviteCode = async () => axios.get('/v1/user/invite/code');

const sabhatBlockUser = async (payload) => axios.post('/v1/is-shabat', payload);

const fetchiFormUrl = async () => axios.get('/v1/user/iform-url');

export const userServices = {
  signUpUser,
  verifyEmail,
  loginUser,
  deleteUser,
  isDataExists,
  getUserInfo,
  getTokenWithRefreshToken,
  passwordReset,
  passwordResetEmailVerification,
  passwordChange,
  updateUser,
  fetchPaymentDetails,
  setPaymentDetails,
  paymentWithdraw,
  addBirthdayPoints,
  resentOTP,
  addCharacterProp,
  fetchInviteCode,
  sabhatBlockUser,
  fetchiFormUrl,
};
