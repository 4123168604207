import React, { memo } from 'react';
import styles from './ImageWrap.module.scss';
import Avtar from '../icons/Avtar';
import Avtarone from '../icons/Avtartone';
import Avtart2 from '../icons/Avtart2';
import Avtart3 from '../icons/Avtart3';
import Avtart4 from '../icons/Avtart4';
import Avtart5 from '../icons/Avtart5';
import { get, map } from 'lodash';
import zIndex from '@mui/material/styles/zIndex';

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}
const ImageWrap = ({ ...props }) => {
  const { data } = props;

  const arrAvtar = [<Avtarone />, <Avtart2 />, <Avtart4 />, <Avtart5 />, <Avtart5 />];
  const indexAvtar = [0, 1, 2, 3, 4];
  const arrayAvtar = shuffle(arrAvtar);

  const renderIcons = (randomData) => {
    return map(randomData.slice(0, 5), (value, index) => {
      const Avatar = arrAvtar[value];

      if (randomData.length > 4 && index === 0)
        return (
          <div
            className={styles.container}
            style={{ backgroundColor: '#000', color: '#fff', zIndex: 5 - index }}
            key={`outer${index}`}
          >
            <p className={styles.number}>{randomData.length - 4}+</p>
          </div>
        );

      return (
        <>
          {/* {randomData.length > 4 && index === 0 && (
            <>
              <div className={styles.container} key={index}>
                <p className={styles.number}>+{randomData.length - 4}</p>
              </div>
            </>
          )} */}
          <div className={styles.icon1} key={`inner${index}`}>
            {' '}
            {Avatar}{' '}
          </div>
        </>
      );
    });
  };

  return <div className={styles.ImageWrap}>{renderIcons(get(data, 'randomData', ''))} </div>;
};

export default memo(ImageWrap);
