import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Header2 } from '../../components';
import resetPasswordSuccess from '../../assets/resetPasswordSuccess.png';
import { ROUTES } from '../../constants/routes.constant';

import styles from './restePasswordSuccess.module.scss';

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.ResetPasswordSuccess}>
      <Header2 isUser={true} title={'יששש!'} description={'הסיסמה שונתה בהצלחה!'} />
      <div className={styles.img}>
        <img src={resetPasswordSuccess} alt="" />
      </div>
      <p className={styles.resetPasssword}>אז למה אתה מחכה? תלחץ על הכפתור למטה ותתחיל להרוויח.</p>
      <Button
        onClick={() => {
          navigate(ROUTES.HOME_PAGE);
        }}
      >
        שלח
      </Button>
    </div>
  );
};

export default ResetPasswordSuccess;
