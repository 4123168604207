import { filter, isEmpty } from 'lodash';

export const BANK_LIST = filter(
  [
    { value: '4', label: 'בנק יהב' },
    { value: '6', label: 'בנק מזרחי טפחות' },
    { value: '9', label: 'בנק הדואר' },
    { value: '10', label: 'בנק לאומי' },
    { value: '11', label: 'בנק דיסקונט' },
    { value: '12', label: 'בנק הפועלים' },
    { value: '13', label: 'בנק איגוד' },
    { value: '14', label: 'בנק אוצר החייל' },
    { value: '17', label: 'בנק מרכנתיל דיסקונט' },
    { value: '20', label: 'בנק מזרחי טפחות' },
    { value: '22', label: 'בנק CitiBank' },
    { value: '22', label: '' },
    { value: '23', label: 'בנק HSBC' },
    { value: '25', label: 'בנק BNP Paribas' },
    { value: '26', label: 'בנק יובנק' },
    { value: '27', label: '' },
    { value: '31', label: 'בנק בינלאומי' },
    { value: '34', label: 'בנק ערבי ישראלי' },
    { value: '39', label: 'בנק SBI State of India' },
    { value: '43', label: '' },
    { value: '46', label: 'בנק מסד' },
    { value: '48', label: 'בנק קופת העובד הלאומי' },
    { value: '50', label: 'מרכז סליקה בנקאי (מס"ב)' },
    { value: '52', label: 'בנק פועלי אגודת ישראל' },
    { value: '54', label: 'בנק ירושלים' },
    { value: '59', label: 'שב"א' },
    { value: '67', label: 'בנק Arab Land' },
    { value: '68', label: 'בנק אוצר השלטון המקומי' },
    { value: '69', label: 'בנק דקסיה' },
    { value: '77', label: 'בנק לאומי למשכנתאות' },
    { value: '90', label: 'בנק דיסקונט למשכנתאות' },
    { value: '99', label: 'בנק ישראל' },
    { value: '18', label: 'בנק הבנק הדיגיטלי בע"מ - One Zero' },
  ],
  (i) => !isEmpty(i.label),
);
