import React, { useMemo } from 'react';

import cx from 'classnames';
import { InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import Icons from '../icons';

import styles from './input.module.scss';

const Input = ({
  type,
  value,
  setValue,
  placeholder,
  className,
  startIcon,
  endIcon,
  handleIconClick,
  onEnterPress,
  handleClick,
  labelColor = '#ffffff',
  labelColorFocused = '#ffffff',
  isError,
  error,
  setErrors,
  search,
  disable,
  ...rest
}) => {
  const CssTextField = useMemo(
    () =>
      styled(TextField)({
        '& label': {
          fontFamily: 'Open Sans',
          fontstyle: 'normal',
          fontweight: 400,
          lineHeight: '14px',
          fontSize: '14px',
          letterSpacing: 1,
          color: '#B7BBC4',
        },
        '&:hover label': {
          color: '#222222',
        },
        '& label.Mui-focused': {
          fontSize: '0.88rem',
          // lineHeight: '20 px',
          letterSpacing: 1,
          margin: 0,
          color: '#222222',
          backgroundColor: '#fff',
        },
        '.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FF3B79',
        },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#B7BBC4' },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#222222',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#222222',
            borderWidth: '1px',
          },
        },
      }),
    [],
  );

  const handleChange = (e) => {
    if (setValue) setValue(e.target.value);
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (onEnterPress) onEnterPress();
    }
  };

  return (
    <div className={cx(styles.container)}>
      <CssTextField
        disabled={disable}
        type={type}
        style={{
          height: 42,
          marginBottom: error ? '30px' : '20px',
          border: 'none',
        }}
        onClick={handleClick}
        label={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyPress}
        variant="outlined"
        className={cx(styles.input, className)}
        error={error}
        helperText={error}
        InputProps={{
          style: {
            fontFamily: 'Open Sans',
            fontstyle: 'normal',
            fontweight: 400,
            lineHeight: '16px',
            height: 42,
            padding: 0,
            margin: 0,
            caretColor: '#3FE2D2',
            caretWidth: '1.5px',
            paddingRight: '10px',
          },
          endAdornment: (
            <InputAdornment position="end">
              <div className={styles.icon} onClick={handleIconClick}>
                <Icons type={endIcon} />
              </div>
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          style: {
            margin: 0,
            color: '#FF3B79',
            fontSize: '12px',
          },
        }}
        {...rest}
      />
      {/* {error && <div className={styles.error}>{error}</div>} */}
    </div>
  );
};

export default Input;
