import * as React from 'react';
const Lead = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6664 8.66573H9.00017C8.9373 8.66582 8.87573 8.68366 8.82257 8.71723C8.76941 8.7508 8.72681 8.79873 8.6997 8.85545C8.67259 8.91218 8.66208 8.97542 8.66936 9.03787C8.67664 9.10032 8.70141 9.15944 8.74084 9.20842L13.0738 14.573C13.1025 14.6088 13.1383 14.6382 13.1789 14.6595C13.2195 14.6808 13.2641 14.6934 13.3098 14.6967H13.3331C13.4175 14.6969 13.4988 14.6651 13.5607 14.6077C14.3289 13.8893 14.9417 13.021 15.3612 12.0565C15.7808 11.092 15.9981 10.0517 15.9999 8.99996C16 8.9561 15.9915 8.91265 15.9747 8.8721C15.958 8.83156 15.9335 8.7947 15.9025 8.76366C15.8715 8.73261 15.8347 8.70798 15.7942 8.69118C15.7537 8.67437 15.7103 8.66573 15.6664 8.66573Z"
      fill="#9EA6B4"
    />
    <path
      d="M11.9298 14.1278L7.33681 8.21901V0.996668C7.33681 0.908212 7.30168 0.82338 7.23913 0.760832C7.17658 0.698284 7.09175 0.663144 7.00329 0.663144C3.14021 0.666678 0 4.10579 0 8.33278C0.00224405 10.3653 0.810618 12.314 2.24778 13.7513C3.68495 15.1886 5.63355 15.9972 7.6661 15.9996C8.42666 16.0587 9.19136 15.9645 9.91481 15.7225C10.6383 15.4804 11.3057 15.0955 11.8775 14.5906C11.9449 14.5355 11.9881 14.4563 11.9979 14.3698C12.0076 14.2833 11.9832 14.1965 11.9298 14.1278Z"
      fill="#9EA6B4"
    />
    <path
      d="M8.33319 0C8.24473 0 8.15989 0.035139 8.09734 0.0976868C8.03479 0.160235 7.99965 0.245068 7.99965 0.333524V7.6661C7.99965 7.75456 8.03479 7.83939 8.09734 7.90194C8.15989 7.96448 8.24473 7.99962 8.33319 7.99962H15.6665C15.7549 7.99962 15.8398 7.96448 15.9023 7.90194C15.9649 7.83939 16 7.75456 16 7.6661C15.9976 5.63355 15.189 3.68495 13.7517 2.24778C12.3144 0.810618 10.3657 0.00224405 8.33319 0Z"
      fill="#9EA6B4"
    />
  </svg>
);
export default Lead;
