import React from 'react';

import cx from 'classnames';

import CrossIcon from '../icons/CrossIcon';
import Button from '../button/Button';
import Decoration from '../icons/Decoration';
import Gift from '../icons/Gift';
import Avtar from '../icons/Avtar';

import styles from './BirthdayPopup.module.scss';

const BirthdayPopup = ({ heading, text, btnText, gift, handleClosePopup }) => {
  return (
    <div className={cx(styles.BirthdayPopup, { [styles.birthdayGift]: gift })}>
      <div className={styles.avtar}>
        <Decoration />
      </div>
      <div className={styles.avtar2}>
        <Avtar />
      </div>
      <div className={styles.main}>
        <div className={styles.navbar}>
          <p className={styles.title}>{heading}</p>
          <div className={styles.crossIcon} onClick={handleClosePopup}>
            <CrossIcon />
          </div>
        </div>
        <p className={styles.information}>{text}</p>
        <div className={styles.birthdayDetails}>
          <p className={styles.count}>100</p>
          <p className={styles.wishes}>נקודות!</p>
        </div>
        <div className={styles.boxSvg}>
          <Gift />
        </div>

        <div className={styles.btn} onClick={handleClosePopup}>
          <Button>{btnText}</Button>
        </div>
      </div>
    </div>
  );
};

export default BirthdayPopup;
