import { create } from 'zustand';
import { User, UserData } from '../types/types';
import { EMPTY_USER } from '../types/user.types';

interface USER {
  user: User;
  setUser: (data: { isNewUser?: boolean; isAuthenticated?: boolean }) => void;
  flushUserData: () => void;
  updateUser: (data: UserData) => void;
}

const useUserStore = create<USER>((set) => ({
  user: EMPTY_USER,

  setUser: (data) => {
    set((state) => {
      return { user: { ...state.user, ...data } };
    });
  },

  updateUser: (data) => {
    set((state) => {
      return {
        user: { ...state.user, userData: { ...state.user.userData, ...data } },
      };
    });
  },

  flushUserData: () => {
    set(() => {
      return { user: EMPTY_USER };
    });
  },
}));

export default useUserStore;
