import React, { useState, useEffect } from 'react';

import { size } from 'lodash';

import { BackdropScreen, Loader, NewSubscriberPopup, WithdrawPopup } from '../../components';
import NoLeads from './noLeads/NoLeads';

import Graph from './Graph';
import TopContainer from './TopContainer/TopContainer';

import { LOCAL_STORAGE } from '../../helpers/general.helpers';
import leadsStore from '../../zustand/leadsStore';
import useUserStore from '../../zustand/userStore';
import filterSectionStore from '../../zustand/filterSection';
import { fetchMyLeads } from '../../actions/leads.actions';

import styles from './leads.module.scss';
import { fetchUser } from '../../actions/user.actions';
import { useLocation } from 'react-router-dom';

const Leads = () => {
  const { myleads: leads, setMyLeads, setAllMyLeads } = leadsStore();

  const { statsScreenFilter } = filterSectionStore();

  // url params
  const { search } = useLocation();

  const { user: userData, setUser } = useUserStore((state) => state);
  const [difference, setDifference] = useState({ leadsDiff: 0, scoreDiff: 0 });
  const [withdrawDiff, setWithdrawDiff] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubscriberPopupVisible, setIsSubscriberPopupVisible] = useState(false);
  const [isWithdrawPopupVisible, setIsWithdrawPopupVisible] = useState(false);
  const [isFormSigned, setIsFormSigned] = useState(false);

  const handleCloseWithdrawPopup = () => {
    setIsWithdrawPopupVisible(false);
  };

  const handleCloseSubscribePopup = () => {
    setIsSubscriberPopupVisible(false);
  };

  useEffect(() => {
    const updatedWithdraw = userData?.userData?.withdraw;
    const updatedTotalLeads = userData?.userData?.total_leads_count;
    const updatedScore = userData?.userData?.score;

    const withdraw = localStorage.getItem(LOCAL_STORAGE.WITHDRAW);
    const totalLeads = localStorage.getItem(LOCAL_STORAGE.NO_OF_LEADS);
    const prevScore = localStorage.getItem(LOCAL_STORAGE.SCORE);

    if (prevScore === null || withdraw === null || totalLeads === null) {
      localStorage.setItem(LOCAL_STORAGE.WITHDRAW, updatedWithdraw);
      localStorage.setItem(LOCAL_STORAGE.NO_OF_LEADS, updatedTotalLeads);
      localStorage.setItem(LOCAL_STORAGE.SCORE, updatedScore);
      return;
    }

    if (updatedTotalLeads > totalLeads) {
      setIsSubscriberPopupVisible(true);
      setDifference({
        leadsDiff: updatedTotalLeads - totalLeads,
        scoreDiff: updatedScore - prevScore,
      });
    } else if (updatedWithdraw > withdraw) {
      setIsWithdrawPopupVisible(true);
      setWithdrawDiff(updatedWithdraw - withdraw);
    }

    localStorage.setItem(LOCAL_STORAGE.WITHDRAW, userData?.userData?.withdraw);
    localStorage.setItem(LOCAL_STORAGE.NO_OF_LEADS, userData?.userData?.total_leads_count);
    localStorage.setItem(LOCAL_STORAGE.SCORE, userData?.userData?.score);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await fetchMyLeads(
        statsScreenFilter.searchKey,
        statsScreenFilter.rangeMaxValue !== 2000
          ? `${statsScreenFilter.rangeMinValue || '0'}-${statsScreenFilter.rangeMaxValue || ''}`
          : '',
      );
      const userData = await fetchUser();
      setUser({ isNewUser: false, isAuthenticated: true, userData });
      setMyLeads(data);
      setAllMyLeads(data);
      // if (!statsScreenFilter.isLeadSelected) setMyLeads(data);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsScreenFilter.isLeadSelected]);

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(search);
      const isFormSigned = searchParams.get('isFormSigned');
      if (isFormSigned) {
        setIsFormSigned(true);
        window.history.replaceState({}, document.title, '/leads');
      }
    }
  }, [search, leads]);

  const hasLeads = size(leads) !== 0;

  if (isLoading) return <Loader isLoading />;

  return (
    <div className={styles.Leads}>
      {hasLeads ? <TopContainer isFormSigned={isFormSigned} /> : <NoLeads />}
      <Graph />
      {isSubscriberPopupVisible && (
        <BackdropScreen onClick={handleCloseSubscribePopup}>
          <NewSubscriberPopup difference={difference} onClick={handleCloseSubscribePopup} />
        </BackdropScreen>
      )}

      {isWithdrawPopupVisible && (
        <BackdropScreen onClick={handleCloseWithdrawPopup}>
          <WithdrawPopup withdrawDiff={withdrawDiff} onClick={handleCloseWithdrawPopup} />
        </BackdropScreen>
      )}
    </div>
  );
};

export default Leads;
