import axios from '../axios';

const fetchLeadData = (searchkey, valuationrange) =>
  axios.get('/v1/user/leads', {
    params: { searchkey: searchkey || '', valuationrange: valuationrange || '' },
  });

const fetchLink = (data) => axios.get(`/v1/user/lead/${data.id}/share`);

const fetchCurrentState = (id) =>
  axios.get(`/v1/user/my-leads/stats`, {
    params: { period: id },
  });

const fetchMyLeads = (searchkey, valuation) =>
  axios.get('/v1/user/my-leads', {
    params: { searchkey, valuationrange: valuation },
  });

export const leadsService = {
  fetchLeadData,
  fetchLink,
  fetchMyLeads,
  fetchCurrentState,
};
