import { get, includes, map } from 'lodash';
// import { getFormattedTime } from '../../helpers/general.helpers';

// const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const days = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'];

export const chartData = (data, type) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  let arr_click = get(data, 'click_time', []);
  let arr_register = get(data, 'register_time', []);

  const arr_click_time = map(arr_click, (item) => item.time);

  if (type === 'week')
    if (!includes(arr_click_time, currentTimestamp + 60)) {
      arr_click = [...arr_click, { time: currentTimestamp + 60, val: 0 }];
      arr_register = [...arr_register, { time: currentTimestamp + 60, val: 0 }];
    }

  if (type === 'month' || type === 'week')
    if (!includes(arr_click_time, currentTimestamp + 24 * 60 * 60)) {
      arr_click = [...arr_click, { time: currentTimestamp + 24 * 60 * 60, val: 0 }];
      arr_register = [...arr_register, { time: currentTimestamp + 24 * 60 * 60, val: 0 }];
    }

  if (type === 'week') {
    //remove first 2 elements from array
    const length = arr_click.length;
    arr_click = arr_click.slice(2, length);
    arr_register = arr_register.slice(2, length);
  }

  if (type === 'day') {
    arr_click = [...arr_click, { time: currentTimestamp + 3600, val: 0 }];
    arr_register = [...arr_register, { time: currentTimestamp + 3600, val: 0 }];
  }

  //take currenttime and create an array of 6 hour difference timestamp for 23 hours in which only 5 values
  // const dayLabelArray = [];
  // for (let i = 0; i < 5; i++) {
  //   const time = currentTimestamp - 6 * 60 * 60 * i;
  //   const milliSeconds = i === 4 ? time * 1000 - 60 : time * 1000;
  //   const date = new Date(milliSeconds);
  //   const hours = date.getHours();

  //   dayLabelArray.push(hours + ':00');
  // }

  let registerData = map(arr_register, (item) => {
    const value = item.time * 1000;
    if (type === 'day') {
      // convert value to a day's hours and minutes
      const date = new Date(value);
      const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const minutes = date.getMinutes();
      return {
        x: `${hours}:${'00'}`,
        y: item.val,
      };
    } else if (type === 'week') {
      // convert value to a week's days
      const date = new Date(value);
      const day = date.getDay();
      // return days[day];
      return {
        x: days[day],
        y: item.val,
      };
    } else if (type === 'month') {
      // convert value to a month's days
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth();
      const xValue = `${day}/${month + 1}`;

      // return ddd/month;
      return {
        x: xValue,
        y: item.val,
      };
    }
    // return item.val;
  });

  let clicksData = map(arr_click, (item) => {
    const value = item.time * 1000;
    if (type === 'day') {
      // convert value to a day's hours and minutes
      const date = new Date(value);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      // const minutes = date.getMinutes();
      return {
        x: `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
        y: item.val,
      };
    } else if (type === 'week') {
      // convert value to a week's days
      const date = new Date(value);
      const day = date.getDay();

      return {
        x: days[day],
        y: item.val,
      };
    } else if (type === 'month') {
      // convert value to a month's days
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth();
      const xValue = `${day}/${month + 1}`;
      // return ddd/month;
      return {
        x: xValue,
        y: item.val,
      };
    }
  });

  // if (type === 'month') {
  //   registerData = [
  //     {
  //       x: 3,
  //       y: 0,
  //     },
  //     {
  //       x: 1,
  //       y: 0,
  //     },
  //     {
  //       x: 2,
  //       y: 1,
  //     },
  //   ];

  //   clicksData = [
  //     {
  //       x: 1,
  //       y: 1,
  //     },
  //     {
  //       x: 3,
  //       y: 1,
  //     },
  //     {
  //       x: 2,
  //       y: 2,
  //     },
  //   ];
  // }

  // const time = map(data.register_time, (item) => {
  //   // timstamp is in seconds item.time
  //  return
  // });

  // const min =
  //   type === 'day'
  //     ? // today's first hour's timestamp in seconds
  //       new Date().setHours(0, 0, 0, 0) / 1000
  //     : type === 'week'
  //     ? // this week's first day's timestamp in seconds
  //       new Date().setHours(0, 0, 0, 0) / 1000 - (new Date().getDay() - 1) * 86400
  //     : // this month's first day's timestamp in seconds
  //       new Date().setHours(0, 0, 0, 0) / 1000 - (new Date().getDate() - 1) * 86400;
  // const max =
  //   type === 'day'
  //     ? // today's last hour's timestamp in seconds
  //       new Date().setHours(23, 59, 59, 999) / 1000
  //     : type === 'week'
  //     ? // this week's last day's timestamp in seconds
  //       new Date().setHours(23, 59, 59, 999) / 1000 + (7 - new Date().getDay()) * 86400
  //     : // this month's last day's timestamp in seconds
  //       new Date().setHours(23, 59, 59, 999) / 1000 + (new Date().getDate() - 1) * 86400;
  // console.log('min', min);
  // console.log('max', max);
  return {
    series: [
      {
        name: 'Register',
        data: registerData,
        // .sort((a, b) => a.x - b.x),
      },
      {
        name: 'Clicks',
        data: clicksData,
        // .sort((a, b) => a.x - b.x),
      },
    ],

    options: {
      legend: {
        show: false,
      },
      colors: ['#2ACAE6', '#6666FF'],

      chart: {
        foreColor: '#7c7c7c',

        dataLabels: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },

        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350,
          },
        },

        type: 'line',
        zoom: {
          enabled: false,
        },
      },

      stroke: {
        curve: 'smooth',
        width: 3,
        dashArray: [0, 8, 5],
      },

      grid: {
        show: true,
        borderColor: ' rgba(235, 235, 235, 0.61)',
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        // tickAmount: 10,

        axisTicks: {
          show: false,
        },
        // min,
        // max,
        // categories: type === 'week' && ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],

        categories: type === 'week' && ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],

        labels: {
          show: true,

          style: {
            colors: ['#7C7C7C'],
            fontSize: '12px',
            fontFamily: 'Open Sans, Arial, sans-serif',
            fontWeight: 400,
            lineHeight: 16,
          },
        },
      },
    },
  };
};
