import React, { useState, useEffect } from 'react';

import { every, get, isEmpty, isEqual, map } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Header2, Input, PasswordChecker } from '../../components';
import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constant';
import { validatePassword } from '../../helpers/signUp.helpers';
import { resetPassword } from '../../actions/user.actions';

import styles from './resetPassword.module.scss';

const passowardErrorLabel = ['אותיות באנגלית', 'מספרים', 'תווים מיוחדים @#$%^', '8 תווים'];

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [canSeePassword, setCanSeePassword] = useState(false);
  const [canSeeConfirmPassword, setCanSeeConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [passwordErros, setPasswordErros] = useState(Array(4).fill(false));
  const [confirmPasswordError, setCofirmPasswordError] = useState('');

  const handlePasswordChange = (val) => {
    setPasswordErros(validatePassword(val));
    setPassword(val);
  };

  const checkButtonDisabled = () => {
    if (isEmpty(password) || isEmpty(confirmPassword)) return true;
    if (!every(passwordErros, (item) => item)) return true;
    return false;
  };

  const handleSubmit = async () => {
    if (!isEqual(password, confirmPassword)) {
      setCofirmPasswordError('error');
      return;
    }
    setCofirmPasswordError('');

    setIsLoading(true);
    const dataToSend = {
      token: get(location, 'state.token', ''),
      new_password: password,
    };

    const isSucess = await resetPassword(dataToSend);

    if (isSucess) {
      setIsLoading(false);
      navigate(ROUTES.RESET_PASSWORD_SUCCESS);
    } else {
      setIsLoading(false);
      alert('Something Went Wrong Try Again');
    }
  };

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate(ROUTES.SIGN_UP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className={styles.ResetPassword}>
      <Header2 isUser={true} title={'איפוס סיסמה'} description={'יש למלא את כל השדות'} />
      <Input
        type={canSeePassword ? 'text' : 'password'}
        placeholder={'סיסמה'}
        endIcon={canSeePassword ? ICONS.CLOSEEYE : ICONS.EYE}
        handleIconClick={() => setCanSeePassword((prev) => !prev)}
        setValue={handlePasswordChange}
        value={password}
      />
      <Input
        type={canSeeConfirmPassword ? 'text' : 'password'}
        placeholder={'אישור סיסמה'}
        endIcon={canSeeConfirmPassword ? ICONS.CLOSEEYE : ICONS.EYE}
        handleIconClick={() => setCanSeeConfirmPassword((prev) => !prev)}
        className={styles.input}
        setValue={setConfirmPassword}
        value={confirmPassword}
      />

      <div className={styles.checking}>
        {map(passowardErrorLabel, (label, index) => (
          <div key={label}>
            {passwordErros[index] ? (
              <PasswordChecker text={label} iconType={ICONS.SUCCESS} success={true} />
            ) : (
              <PasswordChecker text={label} />
            )}
          </div>
        ))}

        {!isEmpty(confirmPasswordError) && (
          <PasswordChecker text={'הסיסמאות לא זהות'} iconType={ICONS.Failure} error={true} />
        )}
      </div>

      <Button disable={checkButtonDisabled()} onClick={handleSubmit} isLoading={isLoading}>
        שלח
      </Button>
    </div>
  );
};

export default ResetPassword;
