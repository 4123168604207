import * as React from 'react';

const SVGComponent = (props) => {
  return (
    <svg
      width={150}
      height={275}
      viewBox="0 0 138 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.0133 237.342V182.011L52.6218 185.899V223.372L41.5328 233.802C39.914 235.325 40.9915 238.043 43.2138 238.043H63.3123C63.6995 238.043 64.0133 237.729 64.0133 237.342Z"
        fill="#222222"
      />
      <path
        d="M74.5291 237.342V182.011L85.9206 185.899V223.372L97.0097 233.802C98.6284 235.325 97.5509 238.043 95.3286 238.043H75.2301C74.843 238.043 74.5291 237.729 74.5291 237.342Z"
        fill="#222222"
      />
      <path
        d="M15.7149 56.9987C15.7149 43.8823 26.3479 33.2493 39.4644 33.2493H96.4631C109.58 33.2493 120.213 43.8823 120.213 56.9987V156.408C120.213 182.828 98.7949 204.245 72.375 204.245H63.5525C37.1326 204.245 15.7149 182.828 15.7149 156.408V56.9987Z"
        fill="#A573FE"
      />
      <g clipPath="url(#clip0_2636_102643)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.11191 203.282C9.04192 203.433 10.6286 201.861 10.6559 199.77C10.7729 190.792 14.9478 175.218 22.6153 158.405C23.4514 156.571 22.7188 154.261 20.9791 153.244C19.2394 152.227 17.1513 152.889 16.3152 154.723C8.50042 171.859 3.80525 188.592 3.66668 199.22C3.63941 201.311 5.1819 203.13 7.11191 203.282Z"
          fill="#FF3B79"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.762 203.282C127.832 203.433 126.245 201.861 126.218 199.77C126.101 190.792 121.926 175.218 114.258 158.405C113.422 156.571 114.155 154.261 115.894 153.244C117.634 152.227 119.722 152.889 120.558 154.723C128.373 171.859 133.068 188.592 133.207 199.22C133.234 201.311 131.692 203.13 129.762 203.282Z"
          fill="#FF3B79"
        />
        <path
          d="M15.7148 153.716H120.212V198.826C120.212 207.334 113.315 214.231 104.807 214.231H31.1201C22.612 214.231 15.7148 207.334 15.7148 198.826V153.716Z"
          fill="#FF3B79"
        />
      </g>
      <path
        d="M49.4373 111.557C53.1125 115.463 60.0869 118.1 68.0859 118.1C76.0848 118.1 83.0592 115.463 86.7344 111.557"
        stroke="black"
        strokeWidth={5.60814}
        strokeLinecap="round"
      />
      <ellipse cx={49.164} cy={85.8432} rx={15.4053} ry={15.5375} fill="white" />
      <ellipse cx={49.3766} cy={86.2263} rx={6.08104} ry={6.13324} fill="black" />
      <ellipse cx={87.2745} cy={85.8409} rx={15.4053} ry={15.5375} fill="white" />
      <ellipse cx={87.4871} cy={86.2263} rx={6.08104} ry={6.13324} fill="black" />
      <defs>
        <clipPath id="clip0_2636_102643">
          <rect width={129} height={60} fill="white" transform="translate(4.00031 154)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SVGComponent;
