import { map, size, split } from 'lodash';
import { LOCAL_STORAGE, isEmptyError, validateEmptyField } from './general.helpers';

// 2 - 38
// 3 - 53
// 4 - 67
// 5 - 82
// 6 - 98
// 7 - 111
// 8 - 126
// 9 - 154

export const getGraphProgress = (score) => {
  let currentStep = 0;
  if (score <= 200) currentStep = Math.min((score / 200) * 38 + 14, 38);
  else if (score <= 400) currentStep = 38 + ((score - 200) / 200) * 15;
  else if (score <= 800) currentStep = 53 + ((score - 400) / 400) * 14;
  else if (score <= 1000) currentStep = 67 + ((score - 800) / 200) * 15;
  else if (score <= 1400) currentStep = 82 + ((score - 1000) / 400) * 14;
  else if (score <= 1800) currentStep = 98 + ((score - 1400) / 400) * 13;
  else if (score <= 2200) currentStep = 111 + ((score - 1800) / 400) * 15;
  else currentStep = 126 + ((score - 2200) / 400) * 28;
  return currentStep;
};

export const getPointsForNextLevel = (score) => {
  if (score <= 200) return 200 - score;
  else if (score <= 400) return 400 - score;
  else if (score <= 800) return 800 - score;
  else if (score <= 1000) return 1000 - score;
  else if (score <= 1400) return 1400 - score;
  else if (score <= 1800) return 1800 - score;
  else if (score <= 2200) return 2200 - score;
  else return 2600 - score;
};

export const validateFields = (personalDetails) => {
  const { firstName, lastName } = personalDetails;
  const errors = {
    firstName: validateEmptyField(firstName) ? '' : 'נדרש שם פרטי',
    lastName: validateEmptyField(lastName) ? '' : 'נדרש שם משפחה',
    // email: validateEmail(email) ? '' : 'Email is not valid',
    // password: every(validatePassword(password), (i) => i)
    //   ? ''
    //   : 'Password is not valid',
    // id: validateId(id) ? '' : 'ID is not valid',
    // birthDate: validateBirthDate(birthDate) ? '' : 'Birth date is not valid',
  };

  return {
    canMove: isEmptyError(errors),
    errors,
  };
};

export const shouldShowBirhtDateModal = async (birthDateString, createdAtTimeStamp) => {
  try {
    const arr = map(split(birthDateString, '/'), (i) => parseInt(i));
    if (size(arr) !== 3) return false;

    const birthDay = arr[0];
    const birthMonth = arr[1];
    const birthYear = arr[2];

    const todayDate = new Date();
    const next30DaysDate = new Date(todayDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    next30DaysDate.setHours(0, 0, 0, 0);

    const userCreatedDate = new Date(parseInt(createdAtTimeStamp) * 1000);
    userCreatedDate.setHours(0, 0, 0, 0);

    const userBirhthDate = new Date(birthYear, birthMonth - 1, birthDay);
    userBirhthDate.setHours(0, 0, 0, 0);

    // return true if user's birhtday in next 30 days and created date is more than user's birthday
    if (
      userBirhthDate.getTime() < next30DaysDate.getTime() &&
      userBirhthDate.getTime() >= userCreatedDate.getTime()
    ) {
      const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE.IS_BIRHTDAY_SHOWN));

      if (data) return false;
      localStorage.setItem(LOCAL_STORAGE.IS_BIRHTDAY_SHOWN, true);
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};
