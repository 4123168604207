import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-web';

import { BackdropScreen, Button, NewRankPopup, ProfileLinkPopup } from '../../components';
import Icons from '../../components/icons';
import Levels from './levels/Levels';
import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constant';
import StepsAnimation from '../../assets/StepsAnimation.json';
import { isEmpty, map } from 'lodash';
import useUserStore from '../../zustand/userStore';
import { getGraphProgress, getPointsForNextLevel } from '../../helpers/profile.helpers';

import styles from './profile.module.scss';
import { LOCAL_STORAGE } from '../../helpers/general.helpers';
import UserAvtar from '../../components/NewRankPopup/UserAvtar';
import { fetchInviteCodeAction, fetchUser } from '../../actions/user.actions';

import { branch } from '../../axios';

const dataArr = [
  {
    title: 'חולצה',
    image: require('../../assets/achievements/shirt.png'),
    style: styles.img1,
  },
  {
    title: 'כובע',
    image: require('../../assets/achievements/hat.png'),
    style: styles.img2,
  },
  {
    title: 'עניבה',
    image: require('../../assets/achievements/tie.png'),
    style: styles.img3,
  },
  {
    title: 'שיער',
    subTitle: 'שלב 4',
    image: require('../../assets/achievements/hair.png'),
    isLocked: true,
    style: styles.img4,
  },
  {
    title: 'גלימה',
    subTitle: 'שלב 5',
    image: require('../../assets/achievements/cape.png'),
    isLocked: true,
    style: styles.img5,
  },
  {
    title: 'משקפיים',
    image: require('../../assets/achievements/sunglasses.png'),
    style: styles.img6,
  },
  {
    title: 'נעליים',
    image: require('../../assets/achievements/legs.png'),
    style: styles.img7,
  },
];

const Profile = () => {
  const {
    user: { userData },
    setUser,
  } = useUserStore();
  const navigate = useNavigate();
  const lottieRef = useRef(null);
  const playerRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [isBankAccountVisible, setIsBankAccountVisible] = useState(false);
  //
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInviteCode, setUserInviteCode] = useState('');

  const handlePopup = () => {
    setIsVisible((prev) => !prev);
  };

  const handleClosePopup = () => {
    setIsModalVisible(false);
  };

  const handleShareIconClick = async () => {
    setIsLoading(true);
    const inviteCode = await fetchInviteCodeAction();
    //

    branch.link(
      {
        channel: 'website',
        feature: 'referral',
        stage: 'new user',
        data: {
          // $fallback_url: `https://youtube.com/sign-up?invite=${inviteCode.code}`,
          $og_title: 'Publico',
          $og_description: 'Publico',
          // $og_image_url: "",
          // $desktop_url: `https://app.publico-app.com/sign-up?invite=${inviteCode.code}`,
          // $ios_url: `https://app.publico-app.com/sign-up?invite=${inviteCode.code}`,
          // $android_url: `https://app.publico-app.com/sign-up?invite=${inviteCode.code}`,
          inviteCode: inviteCode.code,
        },
      },
      function (err, link) {
        //
        setUserInviteCode(link);
        console.log(link, 'inviteCode');
        if (isEmpty(link)) {
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setIsModalVisible(true);
        //
      },
    );
  };

  const accountNumber = useMemo(() => {
    const accountNumber = userData?.account;
    const sub = accountNumber?.length - 4;
    return accountNumber?.substring(0, accountNumber?.length - sub);
  }, [userData?.account]);

  useEffect(() => {
    lottieRef.current = Lottie.loadAnimation({
      container: playerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: StepsAnimation,
    });

    const progress = getGraphProgress(userData.score);
    Lottie.goToAndStop(progress, true);

    return () => {
      if (lottieRef.current) lottieRef.current.destroy();
    };
  }, []);

  //

  useEffect(() => {
    const updatedDegree = userData?.degree;

    const degree = localStorage.getItem(LOCAL_STORAGE.DEGREE);

    if (degree === null) {
      localStorage.setItem(LOCAL_STORAGE.DEGREE, degree);
      return;
    }

    if (updatedDegree > degree) {
      setIsVisible(true);
    }

    const userInfo = async () => {
      const userInfoData = await fetchUser();
      setUser({ isNewUser: false, isAuthenticated: true, userData: userInfoData });
    };

    localStorage.setItem(LOCAL_STORAGE.DEGREE, userData?.degree);
    userInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.Profile}>
      <div className={styles.iconContinaer}>
        <div style={{ height: '350px', width: '350px' }} ref={playerRef}></div>
        {/* <img src={ProfileIcon} alt="" /> */}
        <div style={{ position: 'absolute' }}>
          <UserAvtar height={'200px'} />
        </div>
      </div>

      <div className={styles.userInfo}>
        <p className={styles.name}>{userData.username}</p>

        <div className={styles.Container}>
          <p className={styles.text}>פבליקו מתחיל </p>
          <p className={styles.levelNumber}>
            {` דרגה ${userData.degree} | ${userData.score} נק׳ `}
          </p>
        </div>
        <p className={styles.text}>
          {' '}
          {`נשארו לך רק עוד ${getPointsForNextLevel(userData.score)} נק׳ לשלב הבא`}
        </p>
      </div>

      <div className={styles.levelsContainer}>
        {map(dataArr, (item, index) => {
          return (
            <div key={item.style}>
              <Levels
                title={item.title}
                img={item.image}
                level={`שלב ${index + 2}`}
                imgStyle={item.style}
                opacity={userData.degree <= index + 1 ? 0.5 : 1}
                isLocked={userData.degree <= index + 1}
              />
            </div>
          );
        })}
      </div>
      <div
        className={styles.personalInfo}
        onClick={() => {
          navigate(ROUTES.UPDATE_PERSONAL_INFO);
        }}
      >
        <p className={styles.info}>פרטים אישיים</p>
        <Icons type={ICONS.LEFTARROW} />
      </div>

      <div className={styles.line}></div>

      {!isEmpty(userData.bank_acc_holder_id) ? (
        <div className={styles.bankAccount}>
          <p className={styles.payment}>אמצעי לקבלת תשלום</p>
          <p
            className={styles.addAcc}
            onClick={() => {
              if (!isBankAccountVisible) setIsBankAccountVisible(true);
              else {
                navigate(ROUTES.PAYMENT_TRANSFER);
                setIsBankAccountVisible(false);
              }
            }}
          >
            {isBankAccountVisible ? `עדכון` : `עדכן אמצעי תשלום`}
          </p>
        </div>
      ) : (
        <p
          className={styles.addAcc2}
          onClick={() => {
            navigate(ROUTES.PAYMENT_TRANSFER);
          }}
        >
          לא נמצא חשבון בנק, הוסף חדש
        </p>
      )}

      {isBankAccountVisible && (
        <div className={styles.bankAccountInfo}>
          <p className={styles.bankAccountText}>חשבון בנק</p>
          <p className={styles.bankAccountText}>{`${accountNumber}***`}</p>
        </div>
      )}

      <div className={styles.line}></div>

      <div
        style={{
          marginBottom: '40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p className={styles.shareApp}>שתף את האפליקציה וקבל 100 נקודות</p>
        <Button isLoading={isLoading} onClick={handleShareIconClick}>
          <div className={styles.btn}>
            <Icons type={ICONS.SHAREICON} />
            <p className={styles.btntext}>אני רוצה לקבל קישור</p>
          </div>
        </Button>
      </div>

      {isModalVisible && (
        <BackdropScreen onClick={handleClosePopup}>
          <ProfileLinkPopup inviteCode={userInviteCode} handleClosePopup={handleClosePopup} />
        </BackdropScreen>
      )}

      {isVisible && (
        <BackdropScreen onClick={handlePopup}>
          <NewRankPopup onClick={handlePopup} />
        </BackdropScreen>
      )}
    </div>
  );
};

export default Profile;
