import * as React from 'react';
const Filter = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.39986 2.33317L7.80153 9.084C8.03797 9.37969 8.1667 9.74707 8.16652 10.1257V15.6665L9.83319 14.4165V10.1248C9.8332 9.74652 9.96192 9.37947 10.1982 9.084L15.5999 2.33317H2.40069H2.39986ZM2.39986 0.666504H15.5999C15.9138 0.666543 16.2214 0.755274 16.4872 0.922475C16.7529 1.08968 16.9661 1.32855 17.102 1.61158C17.2379 1.8946 17.2912 2.21028 17.2556 2.52223C17.2199 2.83419 17.0969 3.12974 16.9007 3.37484L11.4999 10.1248V14.4165C11.4999 14.6752 11.4396 14.9304 11.3239 15.1619C11.2082 15.3933 11.0402 15.5946 10.8332 15.7498L9.16652 16.9998C8.91891 17.1856 8.62447 17.2986 8.3162 17.3264C8.00793 17.3542 7.69801 17.2956 7.42117 17.1572C7.14433 17.0188 6.9115 16.806 6.74877 16.5427C6.58605 16.2794 6.49986 15.976 6.49986 15.6665V10.1248L1.09902 3.37484C0.902786 3.12974 0.779774 2.83419 0.744157 2.52223C0.70854 2.21028 0.761768 1.8946 0.897708 1.61158C1.03365 1.32855 1.24677 1.08968 1.51253 0.922475C1.77829 0.755274 2.08588 0.666543 2.39986 0.666504V0.666504Z"
      fill="#222222"
    />
  </svg>
);
export default Filter;
