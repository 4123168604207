import React, { useState, useEffect } from 'react';

import { get, isEmpty, size, toLower } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Header2, Input, PasswordChecker } from '../../components';
import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constant';
import { checkDataExists, registerUser } from '../../actions/user.actions';

import styles from './username.module.scss';

const Username = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [firstValidation, setfirstValidation] = useState(false);
  const [secondValidation, setsecondValidation] = useState(false);
  const [thirdValidation, setThirdValidation] = useState(false);

  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetUserName = (val) => {
    setUsername(val);
    const regex = /^[A-Za-z][A-Za-z0-9]*$/;
    const firstVal = regex.test(val);
    const secondVal = size(val) <= 15;
    const thirdVal = size(val) >= 4;
    setfirstValidation(firstVal);
    setsecondValidation(secondVal);
    setThirdValidation(thirdVal);
  };

  const handleSubmit = async () => {
    const dataToSend = {
      firstname: get(location, 'state.firstName', ''),
      lastname: get(location, 'state.lastName', ''),
      identity_id: get(location, 'state.id', ''),
      email: toLower(get(location, 'state.email', '')),
      phone: get(location, 'state.phone', ''),
      username: username,
      password: get(location, 'state.password', ''),
      birthday: get(location, 'state.dob', '1234567'),
      invite_code: get(location, 'state.inviteCode', ''),
    };

    setIsLoading(true);
    const isUsernameExists = await checkDataExists({
      username,
    });

    if (isUsernameExists) {
      setGeneralError('שם משתמש כבר קיים');
      setIsLoading(false);

      return;
    }
    setGeneralError('');

    const data = await registerUser(dataToSend);

    if (isEmpty(data) || data.status === 2) {
      setGeneralError('המשתמש כבר רשום');
      setIsLoading(false);
      return;
    }

    navigate(ROUTES.OTP_SCREEN, {
      state: { ...location.state, username, emailToken: data.token, otpToken: data.otp_token },
    });
  };

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate(ROUTES.SIGN_UP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className={styles.username}>
      <Header2
        usernameIcon={true}
        title={'שם משתמש'}
        description={'יש לבחור שם המשתמש שלך. שם זה לא יהיה ניתן לשינוי בהמשר.'}
      />
      <Input placeholder={'שם המשתמש שלך'} setValue={handleSetUserName} value={username} />

      <div className={styles.checking}>
        <PasswordChecker
          text={'אותיות באנגלית בלבד'}
          iconType={firstValidation ? ICONS.SUCCESS : ICONS.GRAYSUCCESS}
        />
        <PasswordChecker
          text={'מקסימום 15 אותיות'}
          iconType={secondValidation ? ICONS.SUCCESS : ICONS.GRAYSUCCESS}
        />
        <PasswordChecker
          text={'מינימום 4 אותיות'}
          iconType={thirdValidation ? ICONS.SUCCESS : ICONS.GRAYSUCCESS}
        />

        {generalError && (
          <PasswordChecker text={generalError} iconType={ICONS.Failure} error={true} />
        )}
      </div>

      <Button
        disable={!firstValidation || !secondValidation || !thirdValidation}
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        המשך
      </Button>
    </div>
  );
};

export default Username;
