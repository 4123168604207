import React, { useEffect, useState } from 'react';

import { Routes, BrowserRouter as Router, Route, Navigate } from 'react-router-dom';

import { BackdropScreen, Loader, PopupType4, PrimaryLayout } from './components';
import { ROUTES } from './constants/routes.constant';
import SignUp from './pages/signUp/SignUp';
import SignIn from './pages/signIn/SignIn';
import Home from './pages/home/Home';
import Leads from './pages/leads/Leads';
import Profile from './pages/profile/Profile';
import EmailVerification from './pages/emailVerification/EmailVerification';
import PersonalInfo from './pages/personalInfo/PersonalInfo';
import OtpScreen from './pages/otpScreen/OtpScreen';
import ForgetPasssword from './pages/forgetPasssword/ForgetPasssword';
import ResetPassword from './pages/resetPassword/ResetPassword';
import Username from './pages/username/Username';
import UpdatePersonalInfo from './pages/updatepersonalInfo/UpdatePersonalInfo';
import SignUpSuccess from './pages/signUpSuccess/SignUpSuccess';
import TCPage from './pages/TCScreen/TCPage';
import ResetPasswordSuccess from './pages/resetPasswordSuccess/ResetPasswordSuccess';
import PaymentTransfer from './pages/paymentTransfer/PaymentTransfer';
import useUserStore from './zustand/userStore';
import { fetchUser, refreshToken, sabhatBlockUserAction } from './actions/user.actions';
import moment from 'moment-timezone';

import './App.css';
import { get } from 'lodash';

function App() {
  const { user, setUser } = useUserStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const isRefreshTokenSuccess = await refreshToken();
      const currentTimeZone = moment.tz.guess();
      const isShabat = await sabhatBlockUserAction({ timezone_id: currentTimeZone });
      if (isRefreshTokenSuccess) {
        const userData = await fetchUser();
        if (userData) {
          setUser({
            isNewUser: false,
            isAuthenticated: true,
            userData: {
              ...userData,
              score: userData.score,
              degree: userData.degree,
              isShabat,
            },
          });
        }
      } else {
        setUser({
          isAuthenticated: false,
          isNewUser: false,
          userData: {
            score: 0,
            degree: 0,
            isShabat,
          },
        });
      }
      setIsLoading(false);
    })();

    // open app : href="intent://some/path#Intent;scheme=yourapp;package=com.yourapp.package;end"

    // create a tag with href as above and click it
    // const a = document.createElement('a');
    // a.href = 'intent://#Intent;scheme=publicoApp;package=com.publicoApp;end';
    // a.click();
    window.location.href = 'publico://';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isAuthenticated } = user || {};

  if (isLoading) return <Loader isLoading />;

  if (get(user, 'userData.isShabat', false)) return <PopupType4 />;

  return (
    <Router>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
            <Route path={ROUTES.PERSONAL_INFO} element={<PersonalInfo />} />
            <Route path={ROUTES['TERMS&CONDITION']} element={<TCPage />} />
            <Route path={ROUTES.USERNAME} element={<Username />} />
            <Route path={ROUTES.OTP_SCREEN} element={<OtpScreen />} />
            <Route path={ROUTES.SIGN_UP_SUCCESS} element={<SignUpSuccess />} />

            <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
            <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPasssword />} />
            <Route path={ROUTES.EMAIL_VERIFICATION} element={<EmailVerification />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccess />} />
            <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} replace />} />
          </>
        ) : (
          <>
            <Route path={ROUTES.HOME_PAGE} element={<PrimaryLayout />}>
              <Route path={ROUTES.HOME_PAGE} element={<Home />} />
              <Route path={ROUTES.LEADS} element={<Leads />} />
              <Route path={ROUTES.PROFILE} element={<Profile />} />
              <Route path="*" element={<Navigate to={ROUTES.HOME_PAGE} replace />} />
            </Route>
            <Route path={ROUTES.UPDATE_PERSONAL_INFO} element={<UpdatePersonalInfo />} />
            <Route path={ROUTES.PAYMENT_TRANSFER} element={<PaymentTransfer />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
