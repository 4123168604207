import React, { useState, useEffect } from 'react';

import { get, isEmpty, toLower } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Button, CheckBox, Header1 } from '../../components';
import { ROUTES } from '../../constants/routes.constant';
import useUserStore from '../../zustand/userStore';
import { fetchUser, loginUser } from '../../actions/user.actions';

import styles from './signUpSuccess.module.scss';

const SignUpSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useUserStore();

  const [selected, setSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  // const [isVideoCompleted, setIsVideoCompleted] = useState(false);
  // const [loading, setLoading] = useState(true);

  const handleSubmit = async () => {
    setIsLoading(true);
    const isLoginSucess = await loginUser({
      email: toLower(get(location, 'state.email', '')),
      password: get(location, 'state.password', ''),
    });
    if (!isLoginSucess) {
      setGeneralError('אימייל או סיסמה שגויים');
      setIsLoading(false);
      return;
    }
    const userData = await fetchUser();
    setGeneralError('');
    setUser({ isNewUser: true, isAuthenticated: true, userData });
    setIsLoading(false);
    navigate(ROUTES.HOME_PAGE);
  };

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate(ROUTES.SIGN_UP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className={styles.SignUpSuccess}>
      <Header1
        title={'סרטון הסבר'}
        description={'יש לצפות בכלל הסרטון שמסביר על התהליך, ולאשר צפייה.'}
      />
      <div className={styles.video}>
        <iframe
          src="https://www.youtube.com/embed/5MNf9aeoKGc?si=NmRyAuGbFMPYSAmS"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div className={styles.CheckboxContainer}>
        <CheckBox setValue={setSelected} value={selected} />
        <p className={cx(styles.des1, { [styles.selected]: selected })}>
          אני מאשר/ת שקראתי את התקנון
        </p>
      </div>

      {generalError && <p className={styles.errorText}>{generalError}</p>}

      <Button
        disable={!selected}
        buttonClassName={styles.btn}
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        המשך
      </Button>
    </div>
  );
};

export default SignUpSuccess;
