import React, { useEffect, useRef, useState } from 'react';
import styles from './Home.module.scss';
//
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  BackdropScreen,
  BirthdayPopup,
  FilterPopup,
  Loader,
  Popup,
  PopupType2,
  PopupType4,
  TradingPackage,
} from '../../components';

import LeadWrapper from '../../components/LeadWrapper/LeadWrapper';
import useUserStore from '../../zustand/userStore';
import { getFormattedDate, settings } from '../../helpers/general.helpers';
import { fetchLeadsData, fetchLinkToShare } from '../../actions/leads.actions';
import leadsStore from '../../zustand/leadsStore';
import filterSectionStore from '../../zustand/filterSection';
import { filter, get, isEmpty, map, orderBy } from 'lodash';
import snackBarStore from '../../zustand/snackBarStore';
import Snackbar from '../../components/snackbar/Snackbar';
import { shouldShowBirhtDateModal } from '../../helpers/profile.helpers';
import { addBirthdayPointsAction, fetchUser } from '../../actions/user.actions';
import moment from 'moment-timezone';

const PROPS = {
  // date: '24.03.22',
  upperTitle: 'הלידים של היום',
};

const Home = () => {
  const { upperTitle } = PROPS;

  const {
    user: { isNewUser: isNewRegistration },
    setUser,
  } = useUserStore();
  const { todayLeads } = leadsStore((state) => state);
  const { showSnackBar, closeSnackBar, isOpen } = snackBarStore((state) => state);
  const { setLeadData, leads, setLeadLink, setTodayLeads, setAllLeads } = leadsStore();
  const { setCurrentScreen, homeScreenFilter } = filterSectionStore();
  const { user } = useUserStore((state) => state);

  //states
  const [isLoading, setIsLoading] = useState(true);
  const [firstModalVisible, setFirstModalVisible] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [filterpopup, setFilterpopup] = useState(false);
  const [isNewUser, setIsNewUser] = useState(isNewRegistration);
  // const [isCelebrationModal, setIsCelebrationModal] = useState(false);
  const [isUserBirhtDate, setIsUserBirthDate] = useState(false);
  const [currentSelectedLead, setCurrentSelectedLead] = useState({});

  const [activeSlide, setActiveSlide] = useState(0);
  //
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState();
  const [sort, setSort] = useState(true);

  //
  const isBirthday = useRef(false);

  // <BackdropScreen>
  //   <ShabatPopup />
  // </BackdropScreen>;

  //functions
  const handleSort = () => {
    setSort((prev) => !prev);
    const data = orderBy(leads, (lead) => lead.created_at, sort ? 'asc' : 'desc');
    setLeadData(data);
  };

  const filterSort = () => {
    setSort((prev) => !prev);
    const data = orderBy(leads, (lead) => lead.created_at, sort ? 'asc' : 'desc');
    setLeadData(data);
  };

  const handleShareIconClick = (currentSelectedLead) => {
    setCurrentSelectedLead(currentSelectedLead);
    setFirstModalVisible(true);
  };

  const handleFilterIconClick = () => {
    setFilterpopup(true);
    setCurrentScreen('Home');
  };

  const handleSecondPopUp = () => {
    setSecondModalVisible(false);
  };

  const handleClick = () => {
    setFirstModalVisible(false);
  };

  const handleFilter = () => {
    setFilterpopup(false);
  };

  const handleClosed = async (data) => {
    setLoader(true);
    const response = await fetchLinkToShare(data);
    if (isEmpty(response)) {
      setLoader(false);
      return;
    }
    setLoader(false);
    setLeadLink(response.data.link);
    setFirstModalVisible(false);
    setSecondModalVisible(true);
  };

  const handleSecondPopupClose = () => {
    setSecondModalVisible(false);
    // setLinkCopied(true);
    showSnackBar();
    setTimeout(() => {
      closeSnackBar();
    }, 3000);
  };

  const handleFilterClose = () => {
    setFilterpopup(false);
  };

  const handleGiftPopupClose = () => {
    setIsUserBirthDate(false);
  };

  const handleNewRegistrationClose = () => {
    setIsNewUser(false);
    setUser({ isNewUser: false });
  };

  const showBirthDateModel = async () => {
    if (isBirthday.current === true) return;
    const birhtDate = user.userData.birthday;

    // * new registered user or user has not entered birthdate
    if (isNewRegistration || !birhtDate) return;

    const shouldModalShow = await shouldShowBirhtDateModal(
      user.userData.birthday,
      user.userData.created_at,
    );

    // console.log(shouldModalShow, 'shouldModalShow');

    if (shouldModalShow) {
      addBirthdayPointsAction();
    }

    setIsUserBirthDate(shouldModalShow);
  };

  useEffect(() => {
    showBirthDateModel();
    isBirthday.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      let date = new Date();
      setDate(getFormattedDate(date, '.'));
      const response = await fetchLeadsData(
        homeScreenFilter.searchKey,
        homeScreenFilter.rangeMaxValue !== 2000
          ? `${homeScreenFilter.rangeMinValue || '0'}-${homeScreenFilter.rangeMaxValue || ''}`
          : '',
      );

      // console.log(response);
      const allLeads = get(response, 'data.leads', []);

      // const allLeadsData = orderBy(
      //   allLeads.leads,
      //   (lead) => lead[filterOption[homeScreenFilter.sortOption]],
      //   'asc',
      // );

      const todaysLeads = filter(allLeads, (lead) => {
        const leadDate = new Date(lead.created_at * 1000);

        return (
          leadDate.getDate() === date.getDate() &&
          leadDate.getMonth() === date.getMonth() &&
          leadDate.getFullYear() === date.getFullYear()
        );
      });

      setAllLeads(allLeads);
      setTodayLeads(todaysLeads);
      setLeadData(allLeads);
      setIsLoading(false);
    };
    const userData = async () => {
      const userData = await fetchUser();
      setUser({ isNewUser: false, isAuthenticated: true, userData });
    };

    userData();
    fetchLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loader isLoading />;

  return (
    <div className={styles.Home}>
      {todayLeads.length === 0 ? (
        ''
      ) : (
        <div className={styles.section1}>
          <p className={styles.text}>{upperTitle}</p>
          <div className={styles.colorLine}></div>
          <div className={styles.date}>{date}</div>
        </div>
      )}
      <div className={styles.section2} dir="ltr">
        <Slider {...settings(activeSlide, setActiveSlide)}>
          {map(todayLeads, (item) => (
            <div className={styles.slideWrap}>
              <TradingPackage data={item} handleclick={handleShareIconClick} />
            </div>
          ))}
        </Slider>
      </div>

      <div className={styles.section3}>
        <div className={styles.normalPackage}>
          <LeadWrapper
            Linevisible={todayLeads.length ? true : false}
            handlesort={handleSort}
            handleFilterOptionClick={handleFilterIconClick}
            handleClick={handleShareIconClick}
          />
        </div>
      </div>
      {firstModalVisible && (
        <BackdropScreen onClick={handleClick}>
          <Popup
            isLoading={loader}
            handleClose={handleClick}
            ClosedNextPage={handleClosed}
            data={currentSelectedLead}
          />
        </BackdropScreen>
      )}

      {secondModalVisible && (
        <BackdropScreen onClick={handleSecondPopUp}>
          <PopupType2
            data={currentSelectedLead}
            handleClosePopup={handleSecondPopUp}
            SecondPopupCloseMoveNext={handleSecondPopupClose}
          />
        </BackdropScreen>
      )}
      {filterpopup && (
        <BackdropScreen onClick={handleFilter}>
          <FilterPopup handleFilterPopupClose={handleFilterClose} handlesort={filterSort} />
        </BackdropScreen>
      )}
      {isNewUser && (
        <BackdropScreen onClick={handleNewRegistrationClose}>
          <BirthdayPopup
            heading={'נרשמת, זכית!'}
            text={`לכבוד הרשמתך,\nקבל מאיתנו מתנה מגניבה`}
            btnText={'לקבל את המתנה'}
            gift
            handleClosePopup={handleNewRegistrationClose}
          />
        </BackdropScreen>
      )}
      {isUserBirhtDate && (
        <BackdropScreen onClick={handleGiftPopupClose}>
          <BirthdayPopup
            text={`לכבוד החגיגה קבל מאיתנו`}
            handleClosePopup={handleGiftPopupClose}
            btnText={'וואו, תודה!'}
          />
        </BackdropScreen>
      )}
      {isOpen && <Snackbar isOpen={true} />}
    </div>
  );
};

export default Home;
