import React from 'react';
import cx from 'classnames';
import styles from './Navlist.module.scss';
import HomeIcon from '../icons/HomeIcon';

const Navlist = ({ icon, title, active }) => {
  return (
    <div className={cx(styles.Navlist, { [styles.activeState]: active })}>
      <div className={styles.icon}>{icon}</div>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export default Navlist;
