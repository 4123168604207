import React, { useState } from 'react';
import styles from './PopupType5.module.scss';
import Button from '../button/Button';
import CrossIcon from '../icons/CrossIcon';
import { fechtiFormUrlAction } from '../../actions/user.actions';

const PROPS = {
  title: 'אישור תשלום מס',
  description: 'בכדי להמשיך נדרשת חתימה',
  no: 'לא',
  yes: 'כן',
};

const PopupType5 = ({ handleClose, title }) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchiFormUrl = async () => {
    setIsLoading(true);
    const url = await fechtiFormUrlAction();
    window.open(url, '_self');
    setIsLoading(false);
  };

  return (
    <div className={styles.PopupType3}>
      <div className={styles.navbar}>
        <p className={styles.title}>{PROPS.title}</p>
        <div className={styles.crossIcon} onClick={handleClose}>
          <CrossIcon />
        </div>
      </div>
      <div className={styles.leadDescription}>{PROPS.description}</div>
      <div className={styles.btn}>
        <Button buttonClassName={styles.button} disable={isLoading} onClick={handleClose}>
          {PROPS.no}
        </Button>
        <Button buttonClassName={styles.button} isLoading={isLoading} onClick={fetchiFormUrl}>
          {PROPS.yes}
        </Button>
      </div>
    </div>
  );
};

export default PopupType5;
