import React, { useState } from 'react';

import cx from 'classnames';
import { filter, map, size } from 'lodash';

import DirectionIcon from '../../components/icons/DirectionIcon';
import Filter from '../../components/icons/Filter';
import NormalPackage from '../NormalPackage/NormalPackage';
import leadsStore from '../../zustand/leadsStore';

import styles from './LeadWrapper.module.scss';
import filterSectionStore from '../../zustand/filterSection';

const LeadWrapper = ({
  handleClick,
  handleFilterOptionClick,
  handlesort,
  Linevisible,
  leadsScreen,
}) => {
  const [isActive, setIsActive] = useState(true);
  const [isFilter, setIsFilter] = useState(false);

  const { leads, myleads } = leadsStore();

  const { homeScreenFilter, leadScreenFilter, currentScreen } = filterSectionStore();

  return (
    <div className={styles.LeadWrapper}>
      <div className={cx(styles.colorLine2, { [styles.visible]: !Linevisible })}></div>
      <div className={styles.desc}>
        {leadsScreen ? (
          <div className={styles.leadFilterSection}>
            <p
              className={cx(styles.Leads, { [styles.activeLeads]: isActive })}
              onClick={() => setIsActive(true)}
            >
              פעילים
              <span className={cx(styles.number, { [styles.activeNumber]: isActive })}>{` (${size(
                filter(myleads, (lead = {}) => lead.enabled),
              )})`}</span>
            </p>
            <p
              className={cx(styles.Leads, { [styles.activeLeads]: !isActive })}
              onClick={() => setIsActive(false)}
            >
              לא פעילים
              <span className={cx(styles.number, { [styles.activeNumber]: !isActive })}>{` (${size(
                filter(myleads, (lead = {}) => !lead.enabled),
              )})`}</span>
            </p>
          </div>
        ) : (
          <div className={styles.homeFilter}>
            <div
              className={styles.dateInfo}
              onClick={() => {
                handlesort();
                setIsFilter(!isFilter);
              }}
            >
              <div className={cx(styles.icon, { [styles.activeIcon]: isFilter })}>
                <DirectionIcon />
              </div>
              <div className={styles.date}>תאריך</div>
            </div>
            <div className={styles.leadsCount}>
              <p className={styles.lead}>כל הלידים</p>
              <div className={styles.colorBorder}></div>
              <span className={styles.count}>{size(leads)}</span>
            </div>
          </div>
        )}

        <div className={styles.main}>
          <div className={styles.filterSvg} onClick={handleFilterOptionClick}>
            <Filter />
          </div>
          {currentScreen === 'home'
            ? homeScreenFilter?.searchKey && <div className={styles.dot}></div>
            : leadScreenFilter?.searchKey && <div className={styles.dot}></div>}
          {/* {homeScreenFilter.searchKey && <div className={styles.dot}></div>} */}
        </div>
      </div>

      {leadsScreen ? (
        <div className={styles.normalPackage}>
          {map(
            filter(myleads, (lead) => lead.enabled === isActive),
            (lead) => (
              <div className={styles.package} key={`${lead.clicks_count}${lead.id}`}>
                <NormalPackage data={lead} handleClick={handleClick} isStatsScreen={true} />
              </div>
            ),
          )}
        </div>
      ) : (
        <div className={styles.normalPackage}>
          {map(leads, (item, index) => (
            <div className={styles.package} key={`${item.clicks_count}${item.created_at}`}>
              <NormalPackage data={item} handleClick={handleClick} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LeadWrapper;
