import React from 'react';

import publico from '../../assets/publico.png';
import user from '../../assets/user.png';
import bigUser from '../../assets/bigUser.png';

import styles from './header2.module.scss';

const Header2 = ({ isUser, usernameIcon, title, description }) => {
  return (
    <div>
      <div className={styles.img}>
        <img src={publico} alt="" />
      </div>
      {isUser && (
        <div className={styles.userimg}>
          <img src={user} alt="" />
        </div>
      )}
      <p className={styles.title}>{title}</p>
      <p className={styles.description}> {description}</p>

      {usernameIcon && (
        <div className={styles.usernameIcon}>
          <img src={bigUser} alt="" />
        </div>
      )}
    </div>
  );
};

export default Header2;
