import React, { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import SideDrawer from '../sidedrawer/SideDrawer';
import HomeScreenUser from '../icons/HomeScreenUser';
import useUserStore from '../../zustand/userStore';
import { logoutUser } from '../../actions/user.actions';

import styles from './PrimaryLayout.module.scss';

const PrimaryLayout = () => {
  const { flushUserData, user } = useUserStore((state) => state);

  const handleLogout = async () => {
    await logoutUser();
    flushUserData();
  };

  return (
    <div className={styles.PrimaryLayout}>
      <div className={styles.fixedPart}>
        <SideDrawer />
      </div>
      <div className={styles.leftside}>
        <div className={styles.header}>
          <HomeScreenUser />

          <div className={styles.text}>
            <h1 className={styles.title}>בוקר טוב, {user?.userData?.firstname}</h1>
            <p className={styles.logout} onClick={handleLogout}>
              להתנתק
            </p>
          </div>
        </div>
        <div className={styles.children}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PrimaryLayout;
