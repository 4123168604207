import * as React from 'react';
const NextArrow = (props) => (
  <svg
    width={9}
    height={12}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ marginRight: '-2%' }}
  >
    <path
      d="M2.01999 0L0.609985 1.41L5.18999 6L0.609985 10.59L2.01999 12L8.01999 6L2.01999 0Z"
      fill="#9EA6B4"
    />
  </svg>
);
export default NextArrow;
