import React, { useEffect, useState } from 'react';
import styles from './topContainer.module.scss';
import Icons from '../../../components/icons';
import { ICONS } from '../../../constants/icons.constanst';
import { BackdropScreen, Button } from '../../../components';
import { get } from 'lodash';
import useUserStore from '../../../zustand/userStore';
import { paymentWithdraw } from '../../../actions/user.actions';

import PopupType3 from '../../../components/popupType3/PopupType3';
import PopupType5 from '../../../components/popupType5/PopupType5';

const TopContainer = ({ isFormSigned }) => {
  const {
    user: { userData = {} },
  } = useUserStore();

  const [isLoading, setIsLoading] = useState(false);
  const [isWithdrawPopup, setIsWithdrawPopup] = useState(false);
  const [showiFormPopup, setShowiFormPopup] = useState(false);

  const showWithdrawPopup = () => {
    if (userData.form_filled) setIsWithdrawPopup(true);
    else setShowiFormPopup(true);
  };

  const handleClick = async () => {
    setIsLoading(true);
    const balance = get(userData, 'balance', 0);
    const request = get(userData, 'withdraw_request_in_progress', false);

    if (balance !== 0 && request === false) {
      // eslint-disable-next-line no-unused-vars
      const data = await paymentWithdraw();

      if (data?.result === 2) {
        alert('בקשה זו כבר קיימת');
      } else if (data?.result === 3) {
        alert('אין מה לסגת');
      } else if (data?.result === 4) {
        alert('חשבון בנק חסר');
      }
    } else if (request) {
      alert('הבקשה כבר הוגשה.');
      setIsWithdrawPopup(false);
    } else {
      alert('לא מספיק איזון');
      setIsWithdrawPopup(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFormSigned) {
      setIsWithdrawPopup(true);
    }
  }, [isFormSigned]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.firstDiv}>
          <div className={styles.icon}>
            <Icons type={ICONS.PROFILEUPPERLAYERICON} />
          </div>
          <div>
            <p className={styles.wallet}>צברת בארנק</p>
            <div className={styles.number}>
              <p className={styles.value}>{get(userData, 'balance', 0)}</p>
              <p className={styles.sign}>₪</p>
            </div>
          </div>
          <Button buttonClassName={styles.btn} onClick={showWithdrawPopup} isLoading={isLoading}>
            משיכה
          </Button>
        </div>

        {/* 
      <div className={styles.secondLayer}>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ width: `${Math.min(userData.balance / 22, 100)}%` }}
            />
          </div>
          <p className={styles.number}>₪</p>
        </div>
        <p className={styles.text}>* יש לצבור ₪50 כדי למשוך</p>
      </div> */}
      </div>
      {isWithdrawPopup && (
        <BackdropScreen onClick={() => setIsWithdrawPopup(false)}>
          <PopupType3
            title={'אנא אשר את פרטי האשראי'}
            handleClose={() => setIsWithdrawPopup(false)}
            ClosedNextPage={handleClick}
            isLoading={false}
            data={{}}
          />
        </BackdropScreen>
      )}

      {showiFormPopup && (
        <BackdropScreen onClick={() => setIsWithdrawPopup(false)}>
          <PopupType5 handleClose={() => setShowiFormPopup(false)} />
        </BackdropScreen>
      )}
    </>
  );
};

export default TopContainer;
