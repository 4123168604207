/* eslint-disable max-len */
import * as React from 'react';

const Eye = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 11.8182C4 11.8182 6.90909 6 12 6C17.0909 6 20 11.8182 20 11.8182C20 11.8182 17.0909 17.6364 12 17.6364C6.90909 17.6364 4 11.8182 4 11.8182Z"
      stroke="#7B8795"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0002 13.9999C13.2052 13.9999 14.182 13.023 14.182 11.818C14.182 10.6131 13.2052 9.63623 12.0002 9.63623C10.7952 9.63623 9.81836 10.6131 9.81836 11.818C9.81836 13.023 10.7952 13.9999 12.0002 13.9999Z"
      stroke="#7B8795"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Eye;
