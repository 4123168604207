import React, { useState, useEffect } from 'react';
import styles from './SideDrawer.module.scss';
//
import Navlist from '../navlist/Navlist';
import logo from '../../assets/publico.png';
import HomeIcon from '../icons/HomeIcon';

import Lead from '../icons/Lead';
import User from '../icons/User';
import { useLocation } from 'react-router';
import { ROUTES } from '../../constants/routes.constant';
import { Link } from 'react-router-dom';

const SideDrawer = () => {
  // const [activeList, setActiveList] = useState(false);
  const location = useLocation();

  return (
    <div className={styles.SideDrawer}>
      <div className={styles.logo}>
        <img src={logo} alt="" />
      </div>
      <Link to={ROUTES.HOME_PAGE} className={styles.link}>
        <Navlist
          icon={<HomeIcon />}
          title={'בית'}
          active={location.pathname === '/' ? true : false}
        />
      </Link>

      <Link to={ROUTES.LEADS} className={styles.link}>
        <Navlist
          icon={<Lead />}
          title={'הלידים שלי'}
          active={location.pathname === '/leads' ? true : false}
        />
      </Link>

      <Link to={ROUTES.PROFILE} className={styles.link}>
        <Navlist
          icon={<User />}
          title={'הפרופיל שלי'}
          active={location.pathname === '/profile' ? true : false}
        />
      </Link>
    </div>
  );
};

export default SideDrawer;
