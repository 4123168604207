import * as React from 'react';
const Copied = (props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2234_98142)">
      <path
        d="M14.625 5.60059H16.6875C17.1017 5.60059 17.4375 5.93638 17.4375 6.35059V16.8506C17.4375 17.2648 17.1017 17.6006 16.6875 17.6006H6.1875C5.77328 17.6006 5.4375 17.2648 5.4375 16.8506V14.7881"
        stroke="#3FE2D2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5625 1.4751C0.5625 1.06088 0.89829 0.725098 1.3125 0.725098H11.8125C12.2267 0.725098 12.5625 1.06089 12.5625 1.4751V11.9751C12.5625 12.3893 12.2267 12.7251 11.8125 12.7251H1.3125C0.898282 12.7251 0.5625 12.3893 0.5625 11.9751V1.4751Z"
        stroke="#3FE2D2"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2234_98142">
        <rect width={18} height={18} fill="white" transform="translate(0 0.163086)" />
      </clipPath>
    </defs>
  </svg>
);
export default Copied;
