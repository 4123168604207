import * as React from 'react';
const HomeScreenUser = (props) => (
  <svg
    width={50}
    height={53}
    viewBox="0 0 50 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2231_91876)">
      <circle cx={25} cy={23.0894} r={21} fill="#3FE2D2" />
      <mask
        id="mask0_2231_91876"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={2}
        width={42}
        height={43}
      >
        <circle cx={25} cy={23.0894} r={21} fill="white" />
      </mask>
      <g mask="url(#mask0_2231_91876)">
        <path
          d="M24.1683 51.0206V39.3716L21.77 40.1903V48.0795L19.4354 50.2754C19.0946 50.5959 19.3215 51.1682 19.7893 51.1682H24.0207C24.1022 51.1682 24.1683 51.1021 24.1683 51.0206Z"
          fill="#A573FE"
        />
        <path
          d="M26.3821 51.0206V39.3716L28.7803 40.1903V48.0795L31.1149 50.2754C31.4557 50.5959 31.2289 51.1682 30.761 51.1682H26.5296C26.4481 51.1682 26.3821 51.1021 26.3821 51.0206Z"
          fill="#A573FE"
        />
        <path
          d="M14.3086 13C14.3086 10.2386 16.5472 8 19.3086 8H31.3086C34.07 8 36.3086 10.2386 36.3086 13V33.9287C36.3086 39.4909 31.7995 44 26.2373 44H24.3799C18.8177 44 14.3086 39.4909 14.3086 33.9287V13Z"
          fill="#A573FE"
        />
        <path
          d="M12 0.96288C12 6.4976 16.4868 10.9844 22.0215 10.9844L32.8209 10.9844C32.8209 5.44966 28.3341 0.96288 22.7994 0.96288L12 0.96288Z"
          fill="#F8DF2A"
        />
        <path
          d="M14.2062 33.3623H36.3972V42.8592C36.3972 44.6504 34.9451 46.1025 33.1539 46.1025H17.4495C15.6583 46.1025 14.2062 44.6504 14.2062 42.8592V33.3623Z"
          fill="#FF3B79"
        />
        <path
          d="M21.2904 24.4863C22.0641 25.3086 23.5325 25.8636 25.2165 25.8636C26.9005 25.8636 28.3689 25.3086 29.1426 24.4863"
          stroke="black"
          strokeWidth={1.18069}
          strokeLinecap="round"
        />
        <path
          d="M14.4917 34.3108C13.2614 37.6641 12.7033 40.7798 12.9003 42.605"
          stroke="#FF3B79"
          strokeWidth={1.5363}
          strokeLinecap="round"
        />
        <path
          d="M36.065 34.385C37.2952 37.7383 37.8534 40.854 37.6564 42.6792"
          stroke="#FF3B79"
          strokeWidth={1.5363}
          strokeLinecap="round"
        />
        <ellipse cx={21.5546} cy={19.0729} rx={3.2433} ry={3.27114} fill="white" />
        <ellipse cx={21.5993} cy={19.1535} rx={1.28025} ry={1.29124} fill="black" />
        <ellipse cx={29.5774} cy={19.0724} rx={3.2433} ry={3.27114} fill="white" />
        <ellipse cx={29.6222} cy={19.1535} rx={1.28025} ry={1.29124} fill="black" />
        <path
          d="M25.8171 33.9356C25.5582 33.7306 25.5582 33.3377 25.8171 33.1327L27.6808 31.6568C28.0165 31.3909 28.5108 31.63 28.5108 32.0582L28.5108 35.01C28.5108 35.4383 28.0165 35.6773 27.6808 35.4115L25.8171 33.9356Z"
          fill="black"
        />
        <path
          d="M25.486 33.9356C25.7449 33.7306 25.7449 33.3377 25.486 33.1327L23.6223 31.6568C23.2866 31.3909 22.7923 31.63 22.7923 32.0582L22.7923 35.01C22.7923 35.4383 23.2866 35.6773 23.6223 35.4115L25.486 33.9356Z"
          fill="black"
        />
      </g>
      <path
        d="M11 0.96288C11 6.4976 15.4868 10.9844 21.0215 10.9844L31.8209 10.9844C31.8209 5.44966 27.3341 0.96288 21.7994 0.96288L11 0.96288Z"
        fill="#F8DF2A"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2231_91876"
        x={0}
        y={0}
        width={50}
        height={52.0894}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2231_91876" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2231_91876"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default HomeScreenUser;
