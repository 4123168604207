import { create } from 'zustand';
import { generateRandomData } from '../actions/leads.actions';
import { get, map } from 'lodash';
import { Leads, LeadsData } from '../types/leads.types';

interface FilterQuery {
  searchKey?: string;
  valautions?: string;
  isLeadSelected?: boolean;
}

interface LEAD {
  leads: Array<LeadsData>;
  myleads: Array<LeadsData>;
  todayLeads: Array<LeadsData>;
  homeScreenFilter?: FilterQuery;
  allLeads: Array<LeadsData>;
  allMyLeads: Array<LeadsData>;
  statsScreenFilter?: FilterQuery;

  setAllMyLeads: (data: Array<LeadsData>) => void;
  setStatsScreenFilter: (data: FilterQuery) => void;
  setHomeScreenFilter: (data: FilterQuery) => void;
  setAllLeads: (data: Array<LeadsData>) => void;
  setMyLeads: (data: Array<Leads>) => void;
  setLeadData: (data: Array<object>) => void;
  setLeadLink: (data: string) => void;
  setTodayLeads: (data: Array<Leads>) => void;
}

const leadsStore = create<LEAD>((set) => ({
  leads: [],
  leadsLink: '',
  myleads: [],
  todayLeads: [],
  homeScreenFilter: {
    searchKey: '',
    valautions: '',
    isLeadSelected: false,
  },
  allLeads: [],
  statsScreenFilter: {
    searchKey: '',
    valautions: '',
    isLeadSelected: false,
  },
  allMyLeads: [],

  setAllMyLeads: (data) => {
    set((state) => ({
      ...state,
      allMyLeads: data,
    }));
  },

  setStatsScreenFilter: (data) => {
    set((state) => ({
      ...state,
      statsScreenFilter: data,
    }));
  },

  setAllLeads: (data) => {
    set((state) => ({
      ...state,
      allLeads: data,
    }));
  },

  setHomeScreenFilter: (data) => {
    set((state) => ({
      ...state,
      homeScreenFilter: data,
    }));
  },

  setMyLeads: (data) => {
    set((state) => ({
      ...state,
      myleads: map(data, (item) => ({
        ...item,
        randomData: generateRandomData(get(item, 'register_count', 0)),
      })),
    }));
  },

  setLeadData: (data) => {
    set((state) => ({
      ...state,
      leads: map(data, (item) => ({
        ...item,
        randomData: generateRandomData(get(item, 'shares_count', 0)),
      })),
    }));
  },

  setLeadLink: (data) => {
    set((state) => ({
      ...state,
      leadsLink: data,
    }));
  },

  setTodayLeads: (data) => {
    set((state) => ({
      ...state,
      todayLeads: data,
    }));
  },
}));

export default leadsStore;
