import Axios from 'axios';

import branch from 'branch-sdk';

const axios = Axios.create({
  baseURL: 'https://prod.publico-app.com/',
  headers: { 'Content-Type': 'application/json' },
});

branch.init('key_live_erduSoeQjwx8QN0NKmwljaphDEgNdyYI');
const BASE_URL = 'https://prod.publico-app.com/';

export { branch, BASE_URL };

export default axios;

// test-1f02bx7oi@srv1.mail-tester.com
// test-iwxda7wzi@srv1.mail-tester.com
