import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Button, CheckBox, Header1 } from '../../components';
import { ROUTES } from '../../constants/routes.constant';

import styles from './tcScreen.module.scss';

const TCPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [firstTC, setFirstTC] = useState(false);
  const [secondTC, setSecondTC] = useState(false);
  const [thirdTC, setThirdTC] = useState(false);

  const handleSubmit = () => {
    navigate(ROUTES.USERNAME, { state: { ...location.state } });
  };

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate(ROUTES.SIGN_UP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className={styles.TCPage}>
      <Header1 title={'אישור תקנון'} description={'יש לקרוא את התקנון במלואו ולאשר בתחתית המסך.'} />
      <div className={styles.description} dir="ltr">
        <h3>אינפוקול בע"מ – תנאים לשימוש בפלטפורמת השיווק "חבר מביא חבר"</h3>
        <h4>***הכתוב מטה מתייחס באופן שווה לכל מין ומגדר, ומנוסח בלשון זכר מטעמי נוחות בלבד***</h4>
        <h4>
          מתחת לגיל 18? הכתוב מטה עשוי להיות מעט מורכב. אנא היוועץ במבוגר אחראי לפני השימוש
          בפלטפורמה
        </h4>

        <p dir="rtl" style={styles.pointHeading}>{`1. כללי`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`1.1. נרשם יקר! ברוך הבא לאתר/אפליקציית פבליקו (להלן "הפלטפורמה"), המנוהלת ומופעלת על-ידי פבליקו ע.מ בע"מ (להלן "החברה").`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`1.2. מטרת מסמך תנאי שימוש זה הינה להבהיר ולהסדיר את היחסים בינך, המפיץ בפלטפורמה (להלן "המפיץ" או כל התייחסות אלייך בגוף שני), החברות אשר התירו לך לפנות למכרייך על מנת שירשמו לשרותי קבלת הדיוור הפרסומי מהן (להלן "החברות המקושרות") והחברה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`1.3. הפלטפורמה נועדה לאפשר לך, כמשתמש פעיל, לסייע לחברות המקושרות להרחיב את קהל מקבלי התוכן השיווקי מהן, בצורה חברית ויעילה, תוך שמירה על הפרטיות והחירות של מקבלי הדיוור (להלן "הפעילות הרצויה").`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`1.4. שימוש בפלטפורמה, לרבות בכל תוכן ו/או שירות המופיע בה, מהווה הסכמה לתנאי שימוש אלה, לרבות מדיניות הפרטיות בה ניתן לצפות כאן, אשר יעודכנו מעת לעת (להלן ביחד "המסמכים הדיגיטליים"). החברה שומרת לעצמה את הזכות, בהתאם לשיקול דעתה המלא והבלעדי, לשנות, למחוק או להוסיף על תנאי שימוש אלה בכל עת ומבלי להיזקק למתן הודעה מוקדמת מעבר לפרסום ההוראות בתנאי השימוש עצמם. אשר על כן, טרם ביצוע פעולה כלשהי בפלטפורמה, כדוגמת סיוע לחברה מקושרת חדשה בפעילות הרצויה, הנך מתבקש לקרוא בעיון את המסמכים הדיגיטליים, שכן המשך שימוש בפלטפורמה גם במקרה בו יעשו שינויים כאמור, יעיד על הסכמתך לתנאי השימוש. אם אינך מסכים לשינויים כאמור, עליך להימנע מהמשך שימוש בפלטפורמה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`1.5. נוסח תנאי השימוש המחייב והקובע בכל מועד הוא זה המפורסם בפלטפורמה. תנאי השימוש הינם מצטברים ואינם חלופיים, והם יפורשו כמתקיימים זה לצד זה ולא מצמצמים זה את זה או גורעים האחד מן השני.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`2. הרשמה ושימוש במידע`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`2.1. הרישום לפלטפורמה כמפיץ ו/או עשיית שימוש בה, טעונים מסירת מידע אישי. השימוש במידע אשר מסר המפיץ לחברה במסגרת הפלטפורמה ו/או במידע שהצטבר אודותיו בעת השימוש בה, לרבות שם מלא, מספר ת.ז, כתובת מגורים/איסוף, כתובת דואר אלקטרוני, מספר טלפון, תמונות, פרטים מזהים, פרטי חשבון בנק וכיוצא באלו, יתבצע בהתאם להוראות הדין ומדיניות הפרטיות של הפלטפורמה, המהווה כאמור חלק בלתי נפרד מתנאי השימוש.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`2.2. על המפיץ להקפיד על מסירת פרטים אמינים, מדויקים ונכונים. מסירת פרטים שאינם כאלו עשויים לפגום ולפגוע ביכולת החברה לתגמל בעבור הפעילות הרצויה. כך למשל, מסירת מס' ת.ז שאיננו נכון עשוי להיות בעלת השלכות רבות על המפיץ. מובהר בזאת, כי הקלדת פרטים אישיים כוזבים אסורה בהחלט, מהווה עוולה אזרחית ואף עבירה פלילית, והעושה כן צפוי להליכים משפטיים, פליליים ו/או אזרחיים, לרבות תביעות נזיקין בגין נזקים שיגרמו לחברה ו/או מי מטעמה עקב כך.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`3. פעילות הפלטפורמה`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`3.1. הפלטפורמה מתוכננת להיות בפעילות 24 שעות ביממה, למעט מקרים בהם תהא מושבתת לצורך תחזוקתה ו/או מכל סיבה אחרת.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`3.2. מובהר בזאת, כי ייתכן שיחולו שינויים במועדים המצוינים בתנאי שימוש אלה, לרבות משך או שעות פעילות הפלטפורמה, וזמינותה בכלל, בהתאם לשיקול דעתה המלא והבלעדי של החברה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`3.3. הפלטפורמה נועדה לאפשר למפיץ לבחור עבור מי מבין החברות המקושרות ברצונו לקיים את הפעילות הרצויה. לאחר בחירת החברות הרצויות לפעילות הרצויה מבין החברות המקושרות, יקבל המפיץ בהודעה למכשיר הפלאפון שלו לינק עבור כל אחת מהחברות המקושרות עבורן בחר לספק את הפעילות הרצויה (להלן "הלינק").`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`3.4. את הלינק ישלח המפיץ, בכפוף לדף ההנחיות למפיץ, למכריו אשר עימם הוא בקשר שוטף, זהותו ידועה להם והם ידועים לו, והקשר ביניהם מבוסס הודעות המאופיינות כהודעות בין מכרים. לינק זה יוביל לעמוד חברה מקושרת בה יוכל הנמען להשאיר פרטיו לצורך קבלת פנייה טלפונית מהחברה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`3.5. האפשרויות לפעילות הרצויה עם החברות המקושרות המופיעות בפלטפורמה מתוכננות לשקף את סטטוס החברות המקושרות האפשריות לשיתוף פעולה עם המפיץ בפועל. עם זאת, יתכנו פערי זמנים עד לעדכון סטטוס החברות המקושרות המופיעות בפלטפורמה. `}</p>
        <p dir="rtl" style={styles.pointHeading}>{`4. התמורה`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`4.1. בעבור כל נמען אשר יוסיף פרטיו למאגר מקבלי הדיוור הפרסומי, יהיה המפיץ אשר שלח את הלינק לאותו נמען זכאי לתמורה כאמור בהתאם למחיר שרשום באפליקציה על הקמפיין  אותו המפיץ ישתף. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`4.2. בגין חברות מקושרות אשר הפסיקו התקשרותם באמצעות הפלטפורמה לא יקבל המפיץ תגמול החל מיום הפסקת פעילות החברה המקושרת עם החברה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`4.3. התמורה תועבר על בסיס חודשי ובאמצעות   העברה בנקאית או פלטופרמה אחרת אשר תפורסם בממשקהמתבססת על פרטי המפיץ כפי שנרשם בפלטפורמה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`4.4. החברה אחראית להעביר למפיץ את התמורה בהתאם לפעילות הרצויה אותה סיפק ולאחר ניכוי מיסים והורדות על פי דין.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`5. שימושים אסורים בפלטפורמה`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`5.1. ללא מתן הרשאה מפורשת מהחברה, מראש ובכתב, אסור באופן מוחלט על המפיץ לבצע את הפעולות ו/או המעשים הבאים:`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`1. כל שימוש מסחרי בפלטפורמה ו/או בתוכן המופיע בה, כולם או חלקם.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`2. להעתיק, לשחזר, לשנות, לעבד, לתרגם, לבצע הנדסה לאחור (לרבות כל ניסיון להפיק את קוד המקור של הפלטפורמה ו/או התוכן המופיע בה), לפרסם, להפיץ, לשכפל, למכור, לסחור בשירותי הפלטפורמה וכיוצא בזה, כולה או חלקה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`3. לעקוף, להשבית ו/או להפריע בדרך אחרת לאמצעי האבטחה בפלטפורמה ו/או לעבודתה התקינה, לרבות הפעלת כל יישום מחשב, תוכנות מסוגCrawlers, Robots  וכדומה ו/או לפגוע ו/או לשבש את מערך הגישה לאפליקציה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`4. לאפשר לצד שלישי אשר אינו מפיץ לעשות שימוש בפלטפורמה ו/או בתוכן המופיע בה בכלל ולעשות בה שימוש לעיצוב ו/או פיתוח שירות או מוצר מתחרים בפרט, או לעשות זאת עבורו.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`5. לשבש או להפר כל זכות של מפיץ אחר או חברה מקושרת בפלטפורמה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`6. להסיר, להשחית, להסתיר ו/או לשנות את הפלטפורמה ו/או כל תוכן המופיע בה, לרבות זכויות יוצרים, סימנים מסחריים ו/או זכויות קנייניות אחרות, לרבות עשיית שימוש בשמה, סמלה המסחרי או סימני המסחר של החברה, מבלי לקבל לכך את הסכמתה המפורשת, מראש ובכתב.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`7. שימוש בפלטפורמה ו/או בתוכן המופיע בה לשם יצירת מאגר מידע או לקט מכל סוג.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`2. אין לבצע את הפעילות הרצויה תוך שימוש בשפה בלתי הולמת ו/או בצורה מטרידה ו/או בלתי סבירה ו/או בלתי נאותה ו/או שמטרידה בכמותה על המומלץ בדף ההנחיות למפיץ   \n   C:\\Users\\ben.INFOCALLDOM\\OneDrive - InfoCall\\שולחן העבודה\\אינפוקול - דף הנחיות למפיץ - דף נחיתה (002).htm  \n  או בכל דרך אחרת אשר תעיב על הזולת.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`5.2. החברה רשאית, בהתאם לשיקול דעתה המלא והבלעדי, לא לאשר, להשעות או לחסום, מבלי להיזקק למתן הודעה מראש, את הגישה של כל מפיץ ו/או צד שלישי לאפליקציה או לפעילות הרצויה או לשירותים המסופקים על ידי החברה, כולם או חלקם, ו/או לכל תוכן או שירות המוצע במסגרתה, וזאת במקרים בהם סבורה החברה כי בוצעה הפרה של תנאי שימוש אלה ו/או כי קיים חשש כי תיגרם הפרעה מכל סוג שהוא לפעילותה התקינה של הפלטפורמה ו/או אספקת השירותים המוצעים בה כך גם באשר לחשש להפרת הוראות הדין ו/או זכויות של צדדים שלישיים או של חברות מקושרות, ו/או מכל סיבה אחרת בהתאם לשיקול דעתה המלא והבלעדי של החברה, גם ללא כל סיבה או הצדקה, והמפיץ ו/או צד שלישי כלשהו מוותרים על כל טענה ו/או דרישה ו/או תביעה כנגד החברה ו/או כל מי מטעמה בעניין זה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`5.4. אי עמידה במגבלות אלה עלולה להוביל כאמור למניעת גישת המפיץ לאפליקציה, בין אם באמצעות חסימת המפיץ או הסרת הלינקים אשר משויכים לחשבונו ובין אם בכל דרך אחרת, ואף לחשוף אותו לאחריות אזרחית ו/או פלילית, בהתאם להוראות כל דין.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`6. אבטחת החשבון`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`6.1. השימוש בפלטפורמה ו/או בשירותים המוצעים בה, הינו בכפוף לכך שהמכשיר בו נעשה שימוש נמצא בבעלותך / ברשותך הבלעדית והוא מוגן בקוד נעילה הידוע רק לך או אמצעי נעילה אחר הייחודי לך בלבד, וזאת על מנת למנוע שימוש שאינו על ידך בפלטפורמה. כמו כן, על המכשיר בו נעשה שימוש להיות בעל יכולת תמיכה בקבלת ושליחה של מסרונים (הודעות SMS) ובהפעלה של פרוטוקול גלישה אינטרנטית באמצעות רשת סלולר.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`6.2. האחריות לשמירה על החשבון שלך ועל הגישה אליו, חלה עליך בלבד. החברה לא תישא בכל אחריות להתנהלות עצמאית מצדך בפלטפורמה ו/או כל פעולה המבוצעת על-ידך או צד שלישי ו/או ביחס לשימוש כאמור. על כן, שמור על מכשיר הפלאפון שלך מאובטח תמיד.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`6.3. יתכן כי על מנת לבצע פעולות מסוימות בפלטפורמה, תידרש לבחור אמצעי הזדהות בהתאם להנחיות החברה, כפי שיינתנו מעת לעת. החברה תהא רשאית לדרוש ממך להחליף את אמצעי ההזדהות מעת לעת ו/או להוסיף להאמצעי זיהוי נוספים כתנאי לביצוע פעולות מסוימות בפלטפורמה. כמו כן, יתכן כי לצורך ביצוע אחת או יותר מהפעולות בפלטפורמה, תישלח אליך סיסמא חד פעמית למכשיר בו נעשה שימוש לצורכי אימות זיהוי.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`6.4. הנך מתחייב לעשות כל שביכולתך על מנת לשמור על סודיות אמצעי ההזדהות, ולא לגלותם ו/או למסור אותם לכל צד שלישי`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`6.5. בכל מקרה בו יתעורר אצלך חשד לשימוש לרעה בפלטפורמה ו/או לאבדן של אחד מאמצעי הזיהוי אשר מאפשרים להזדהות מולה, הנך נדרש להודיע על כך מידית לחברה באמצעות דרכי יצירת הקשר מטה.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`7. שליחה וקבלת עדכונים`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`7.1. במסגרת הפלטפורמה ובמהלך השימוש בה (בהתאם לתנאי שימוש אלה והוראות הדין), יתאפשרו, וייתכן כי ידרשו לשם תפעולה, בין היתר, עדכונים בדחיפה ובאמצעות הודעות SMS והודעות אפליקציה לצורך דיוק השירות בכפוף למדיניות הפרטיות.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`8 בעלות וזכויות קניין רוחני`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`8.1. הפלטפורמה והתוכן המופיע בה, בין אם מתייחס לחברה ובין אם מתייחס לחברות המקושרות, לרבות עיצובים, איורים, תמונות, מפות, קטעי אודיו / וידאו, טקסט, גרפיקה, תוכנה, יישום, קוד מקור וכל חומר אחר וכיו"ב (להלן "התוכן" או "התכנים"). כמו גם כל זכויות הקניין הרוחני בקשר עם הפלטפורמה מכל מין וסוג שהוא (וזאת בין אם המדובר בזכויות רשומות ובין אם המדובר בזכויות שטרם נרשמו), הפטנטים, סימני המסחר, שמות המסחר, זכויות יוצרים, סודות מסחריים, השיטות, אופן הצגתה ועיצובה של הפלטפורמה וכן כל עניין ו/או פרט הקשורים בפלטפורמה מוגנים על-ידי דיני זכויות יוצרים ושייכים לחברה ו/או מי מטעמה ו/או לצד שלישי אשר העניק לחברה זכות שימוש מלאה בהם. משכך, חל איסור מוחלט על המפיץ ו/או צד שלישי כלשהו להכניס שינויים, לבצע הליך הנדסה לאחור, להעתיק, לפרסם, להפיץ, לשדר, להציג בפומבי, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות, למכור או למסור לצד שלישי וכיו"ב כל חלק מן התוכן מבלי לקבל את הסכמתה המפורשת של החברה, מראש ובכתב.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`8.2. סימני המסחר ומודעות הפרסומת של מפרסמים בפלטפורמה (אם ישנם), הינם קניינם של מפרסמים אלה בלבד, או של החברה בהתאם להסכם פנימי. מובהר כי גם בהם אין לעשות כל שימוש מבלי לקבל את הסכמתם המפורשת של המפרסמים או החברה, מראש ובכתב.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`9. תוכן הפלטפורמה ופרסומות`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`9.1. הפלטפורמה והתכנים המופיעים בה מוצעים לציבור ולמפיץ כמות שהם ובכפוף לזמינותם (As Is ו- As Available). החברה עושה מאמצים על מנת שהתוכן המופיע בפלטפורמה יהא נכון, מדויק ועדכני וזמין לשימוש. יחד עם זאת, החברה אינה מתחייבת לכך, ויתכן כי התוכן אינו שלם ו/או שנפלו בו טעויות טכניות או אחרות, או שלא יהיה זמין, ולכן החברה לא תישא באחריות כלשהי לעדכניות, דיוק ושלמות המידע המצוי בפלטפורמה, לרבות אך לא רק: שמות החברות המקושרות, סימני הזיהוי של החברות המקושרות וכל מאפיין אחר של גורם צד ג'. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`9.2. החברה שומרת לעצמה את הזכות, לפי שיקול דעתה המלא והבלעדי, לשנות בכל עת את הפלטפורמה, המידע והשירות המוענק במסגרתה, ובכלל זה על-ידי הוספה, גריעה, הפסקה או הגבלה של פעילותה עם חברות מקושרות ע"י נטרול אפשרות של שליחת הלינק של החברה איתה הופסקה הפעילות או תום התקציב, שינוי מבנה הפלטפורמה, היקפם וזמינותם של המידע והשירותים המוצעים בה וכל היבט אחר הכרוך בה, בשירות ובתפעול, מבלי להיזקק למתן הודעה מוקדמת. מובהר, כי למפיץ ו/או לצד שלישי כלשהו לא תהא כל טענה ו/או דרישה ו/או תביעה כלפי החברה בגין האמור.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`9.3. בפלטפורמה עשויים להיות מוצגים, תוכן, פרסומים, פרסומות, כתבות, מידע, שירותים, מוצרים ו/או כל תוכן אחר של צדדים שלישיים ו/או חברות מקושרות ו/או מטעמם, בין באמצעות הפניית המפיץ לאתרים של צדדים שלישיים ו/או באמצעות הצגתם של אלה בפלטפורמה (להלן "תוכן של צד שלישי"). מבלי לגרוע מהאמור, השימוש של המפיץ בתוכן של צד שלישי הינו בכפוף לתקנון ו/או תנאי השימוש ו/או מדיניות הפרטיות של אותם צדדים שלישיים, ככל שאלה קיימים. מובהר, כי כל חוות דעת, הבעת עמדה, עצה, הצהרה, הצעה, אינפורמציה, שירות ו/או כל תוכן אחר בתוכן של צד שלישי, הינם של אותו צד שלישי והחברה אינה מעורבת ביצירתם, עריכתם ו/או הפעלתם. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`9.4. נוסף על האמור, בפלטפורמה עשויים להיכלל גם קישורים לאתרי אינטרנט אחרים שאינם קשורים לפעילות הרצויה. הקישורים נועדו אך ורק לנוחותם ולידיעתם של המפיצים. קישורים אלה אינם בשליטתה של החברה והיא אינה מפקחת על תוכן האתרים המקושרים. ככל שהפלטפורמה מכילה קישורים אלה, אין בכך כדי להעיד על הסכמה ו/או אחריות של החברה לתכנים המופיעים באתרים המקושרים ו/או מהווה ערובה לאמינותם, עדכניותם, תקינותם או חוקיותם ו/או למדיניות הפרטיות ותנאי השימוש הננקטים על-ידי בעליהם. החברה אינה אחראית לכל תוצאה אשר תיגרם כתוצאה מהשימוש באתרים המקושרים ו/או מהסתמכות עליהם וממליצה לקרוא בעיון את תנאי השימוש ומדיניות הפרטיות של אתרים אלה, ככל שקיימים, ו/או לפנות לבעלי האתרים המקושרים בכל מקרה של טענה או בקשה בעניין. עוד מובהר, כי החברה רשאית, בהתאם לשיקול דעתה המלא והבלעדי, להסיר כל קישור מהפלטפורמה ו/או להוסיף קישורים נוספים וכי החברה אינה מתחייבת כי הקישורים לאתרי האינטרנט האמורים יהיו פעילים ויובילו לאתר אינטרנט פעיל.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`9.5. כל שימוש בתוכן הפלטפורמה, לרבות בתוכן המסופק על-ידי החברה ו/או בתוכן של צד שלישי, לרבות כל כניסה של המפיץ לאתרים של צדדים שלישיים על-ידי הפניה בפלטפורמה ו/או בפרסומות המופיעות בה, יתבצע על אחריותו הבלעדית של המפיץ, ולא תהא לו כל טענה ו/או דרישה ו/או תביעה כנגד החברה ו/או מי מטעמה בהקשר זה, לרבות בגין כל נזק ישיר או עקיף, הנובע משימוש ו/או כניסה ו/או הסתמכות על תוכן המופיע בפלטפורמה ו/או של צד שלישי ו/או בשל פגיעה בפרטיות בעקבות שימוש כאמור. המפיץ מוותר ויהא מנוע מלהעלות כל טענה ו/או דרישה ו/או תביעה בעניין זה כלפי החברה ו/או מי מטעמה.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`10. אחריות`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.1. החברה עושה מאמצים רבים על מנת לספק למפיצים מידע אמין ומדויק. עם זאת, הנתונים מושפעים מגורמים אשר לחברה אין בהם שליטה, והחברה לא תהיה אחראית לכל אי דיוקים ו/או נתונים שגויים המופיעים למפיצים על כלל סוגיהם, לרבות לינקים "שבורים" (לינקים שאינם מובילים ליעד תקין), תיאור חברות מקושרות שאינו מדויק וכדומה. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.2. החברה עושה כל שביכולתה על מנת לספק למפיץ חווית שימוש איכותית ובטוחה בפלטפורמה. יחד עם זאת, הפלטפורמה אינה חסינה מתקלות ו/או בעיות ועשויה שלא להיות למפיץ גישה לאפליקציה מעת לעת.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.3. החברה והחברות המקושרות: החברה הינה גורם המחבר בין חברות המעוניינות להרחיב את שרותיהן לבין מפיצים המעוניינים לסייע להם בכך תוך אפשרות לתגמול בגין הסיוע. לחברה אין שליטה על החברות המקושרות אליה. אי לכך, אין לחברה אחריות על טיב החברה המקושרת, כמו גם על כמות ההודעות שתשלח, תקינותן, לשונם, עמידתם בהוראות הדין כאלו או אחרות וכדומה. יובהר כי לחברה אין יכולת לוודא או לאמת את זהותם של העומדים מאחורי החברות המקושרות ואינה אחראית לעיסוקן של אותן החברות המקושרות. החברה לא תישא בכל אחריות, מפורשת או משתמעת, בקשר וללא קשר עם הפלטפורמה בדבר ההודעות השיווקיות שישלחו על ידי החברות המקושרות, לרבות מקרי הודעות שיווקיות שנשלחות שלא בהתאם לחוק, הודעות בעלות תוכן שאינו ראוי, ריבוי הודעות וכל הקשר אחר הנוגע להודעות עצמן. כמו גם על פעולות הננקטות מצדן של אותן החברות הנוגעות לאיסוף, שימוש ועיבוד מידע הנמסר להן בהתאם לדין בכלל ולחוק הגנת הפרטיות, התשמ"א-1981 בפרט.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.4. החברה והמפיץ: המפיץ מצהיר ומתחייב בזה, כי הוא אחראי באופן בלעדי ומלא לכל שימוש שהוא עושה בפלטפורמה או כחלק מהפעילות הרצויה, וכי ידוע לו שהחברה אינה אחראית, בין במישרין בין בעקיפין, לכל שימוש כאמור. החברה לא תהיה אחראית, ולא יכולה להיות אחראית לנוסח ההודעות שישלחו למכרי המפיץ, כמות ההודעות, היקפן או תדירותן.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.5. לנוחיותך, נשלח אלייך  "דף ההנחיות למפיץ"   אשר כולל את הכללים הבסיסים לצורך שליחת הודעות כדין, ואנו ממליצים לך לדבוק בו. החברה לא תוכל לסייע לך במקרה ותחרוג מכללים אלו ולא תישא באחריות כאמור לעיל. בכל שאלה או חשש – אתה מוזמן לפנות אלינו באמצעו תדרכי הקשר שבתחתית המסמך.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.6. המפיץ מתחייב בזאת לציית להוראות כל דין העשויות לחול על שימושו בפלטפורמה ו/או בתוכן ו/או השירותים המופיעים בה, לרבות תנאי שימוש אלה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.7. החברה לא תישא בכל אחריות, מפורשת או משתמעת, לא במישרין ולא בעקיפין, לא כצד ג' ולא כגורם מתווך לכל נזק שיגרם למי מהמפיצים או על ידי המפיצים כתוצאה מהתנהלות שאינה כדין ו/או בהתאם למסמכים הדיגיטליים על ידי מפיץ אחר, בין אם באמצעות הפלטפורמה, ובהקשר לשירותיה בכלל.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.8. ייתכן ותהיינה טעויות ו/או תקלות ו/או חוסרים ו/או אי דיוקים בתהליך העברת המידע בין מפיץ לחברה ו/או בין החברה למפיץ, בין שמקורן אצל המפיץ, בפלטפורמה ו/או בחברה מכל סיבה שהיא. בהקשר זה, המפיץ מוותר בזאת על כל טענה ו/או דרישה ו/או תביעה מכל סוג שהם כלפי החברה ו/או מי מטעמה ופוטר אותה מכל אחריות לכל נזק ו/או הפסד שהוא (כספי או אחר) אשר יכול שייגרם למפיץ כתוצאה ו/או בקשר עם האמור, וזאת למעט במקרה בו יוכח כי הנזק נגרם בשל פעולה ישירה בזדון של החברה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.9. מובהר, כי שיבוש בקליטת פרטי המפיץ במחשבי החברה ו/או הפלטפורמה מכל סיבה שהיא, לרבות תקלה הקשורה לחברה, לא יהווה עילה לכל טענה ו/או דרישה ו/או תביעה מצד המפיץ כלפי החברה.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.10. המפיץ מתחייב, כי יפצה וישפה את החברה ו/או כל מי מטעמה, מיד עם דרישתה הראשונה, בגין כל נזק, הפסד, אובדן רווח, תשלום ו/או הוצאה, פגיעה במוניטין, פגיעה כלכלית ו/או מסחרית, לרבות מתוקף כל דרישה, תביעה, פסק דין, פשרה, הוצאה לפועל, ולרבות הוצאות משפט, ועלויות הייעוץ המשפטי, הנובעים, במישרין ו/או בעקיפין, מהפרת תנאי השימוש ו/או כל מעשה ו/או מחדל של המפיץ אשר בעקבותיו תחויב החברה ו/או מי מטעמה בחיוב כלשהו.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.11. המפיץ מודע לכך שהחברה רשאית לשנות ו/או להפסיק ו/או לבטל בכל עת, בהתאם לשיקול דעתה המלא והבלעדי, את פעילות הפלטפורמה וזאת ללא כל צורך במתן הודעה מוקדמת על כך. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.12. בכל סתירה ו/או אי התאמה מכל מין וסוג שהוא בין כל תוכן בפלטפורמה לבין תנאי שימוש אלה, לרבות מדיניות הפרטיות, יגברו הוראות תנאי השימוש ו/או מדיניות הפרטיות , לפי העניין.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`10.13. אין בהגבלת האחריות בסעיף זה כדי לגרוע מהגבלת אחריות אחרת במסמכים הדיגיטליים.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`11. התיישנות`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`11. מבלי לגרוע מהאמור בתנאי השימוש, המפיץ מודע, מסכים ומאשר בזאת, כי תקופת ההתיישנות לגבי כל טענה ו/או דרישה ו/או תביעה כנגד החברה, תוגבל לתקופה של שישה (6) חודשים, והצדדים רואים בכך כהסכם לתקופת ההתיישנות כמשמעו בסעיף 19 לחוק ההתיישנות, התשי"ח-1958.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`12. סמכות שיפוט וברירת דין`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`12.1. על כל שימוש בפלטפורמה ו/או על כל טענה ו/או דרישה ו/או תביעה הנובעים משימוש זה ו/או בכל הקשור או הנובע מקבלת שירותי החברה ו/או בכל דבר ועניין אחר הקשורים באופן ישיר או עקיף לאפליקציה יחולו אך ורק דיני מדינת ישראל. סמכות השיפוט הבלעדית ביחס לאמור תהא נתונה לבית המשפט המוסמך בעיר תל אביב-יפו    ? , והדין המהותי והפרוצדוראלי אשר יחול יהיה הדין הישראלי, אשר יגבר על כל כללי ברירת דין המפנים לתחולת דין זר.`}</p>
        <p dir="rtl" style={styles.pointHeading}>{`13. שונות`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`13.1. המסמכים הדיגיטליים וכן דף ההנחיות למפיץ מהווים את ההסכם המלא בינך לבין החברה בכל הנוגע לאופן השימוש בפלטפורמה ומחליפים כל הבנה ו/או הסכמה אחרת, בעל-פה או בכתב, הנוגעות לשימוש בפלטפורמה ו/או בתוכן ו/או בשירותים המופיעים בה. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`13.2. אם תנאי מתנאי השימוש ימצא בלתי חוקי, בטל או בלתי אכיף מכל סיבה, תנאי זה ימחק מתנאי השימוש ומחיקתו לא תשפיע על חוקיות ותקפות תנאי השימוש הנותרים. במקרה שכזה, יראו בקיומו של תנאי דומה אחר אכיף במקום התנאי שנמחק או הוסר. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`13.3. כל שיהוי, ויתור, ארכה, איחור או הימנעות מצד החברה למימוש זכויותיה ו/או בדרישת קיום תנאי מתנאי שימוש אלה ו/או הסכמתה לסטות מתנאי השימוש לא יהוו תקדים, לא יחשבו לוויתור ו/או הסכמה מצד החברה ואין להסיק מהן גזירה שווה למקרה אחר. `}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`13.4. תנאי השימוש המפורטים לעיל אינם יוצרים ולא יפורשו כיוצרים כל שותפות, מיזם משותף, יחסי עובד מעביד, סוכן או שלוח בין המפיץ לבין החברה. `}</p>
        <p dir="rtl" style={styles.pointHeading}>{`14. יצירת קשר`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`14.1. בכל עניין, שאלה ו/או בקשה, אנא פנו אל Service@publico-app.com באמצעות טופס בכל תכתובת לחברה יש לכלול את פרטיך המלאים לרבות, כתובת ואימייל ליצירת קשר. החברה תשתדל להגיב לכל בקשה סבירה בזמן סביר.`}</p>
        <p
          dir="rtl"
          style={styles.pointDesb}
        >{`14.2. כל הודעה אשר תישלח על-ידי החברה למפיץ וזאת על פי הכתובת כפי שמילא המפיץ בעת הזנת פרטיו במקום הנדרש תיחשב כאילו הגיעה למענה לאחר 48 שעות.`}</p>
      </div>

      <div className={styles.CheckboxContainer}>
        <CheckBox setValue={setFirstTC} value={firstTC} />

        <p dir="rtl" className={cx(styles.des1, { [styles.selected]: firstTC })}>
          אני מאשר/ת שקראתי את התקנון
        </p>
      </div>

      <div className={styles.CheckboxContainer}>
        <CheckBox setValue={setSecondTC} value={secondTC} />
        <p dir="rtl" className={cx(styles.des1, { [styles.selected]: secondTC })}>
          אני מאשר/ת שליחת התקנון למייל שלי
        </p>
      </div>

      <div className={styles.CheckboxContainer}>
        <CheckBox setValue={setThirdTC} value={thirdTC} />
        <p dir="rtl" className={cx(styles.des1, { [styles.selected]: thirdTC })}>
          אני מאשר/ת שאני מעל גיל 18
        </p>
      </div>

      <Button
        disable={!firstTC || !secondTC || !thirdTC}
        buttonClassName={styles.btn}
        onClick={handleSubmit}
      >
        המשך
      </Button>
    </div>
  );
};

export default TCPage;
