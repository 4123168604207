import React, { useEffect } from 'react';

import cx from 'classnames';
import { get } from 'lodash';

import Button from '../button/Button';
import Levels from '../../pages/profile/levels/Levels';
import UserAvtar from './UserAvtar';
import useUserStore from '../../zustand/userStore';
import { addCharacterPropAction } from '../../actions/user.actions';

import styles from './newRankPopup.module.scss';

const dataArr = [
  {
    title: 'חולצה',
    image: require('../../assets/achievements/shirt.png'),
    style: styles.img1,
    index: 1,
  },
  {
    title: 'כובע',
    image: require('../../assets/achievements/hat.png'),
    style: styles.img2,
    index: 2,
  },
  {
    title: 'עניבה',
    image: require('../../assets/achievements/tie.png'),
    style: styles.img3,
    index: 3,
  },
  {
    title: 'שיער',
    subTitle: 'שלב 4',
    image: require('../../assets/achievements/hair.png'),
    isLocked: true,
    style: styles.img4,
    index: 4,
  },
  {
    title: 'גלימה',
    subTitle: 'שלב 5',
    image: require('../../assets/achievements/cape.png'),
    isLocked: true,
    style: styles.img5,
    index: 5,
  },

  {
    title: 'נעליים',
    image: require('../../assets/achievements/legs.png'),
    isLocked: true,
    style: styles.img7,
    index: 6,
  },
  {
    title: 'משקפיים',
    image: require('../../assets/achievements/sunglasses.png'),
    isLocked: true,
    style: styles.img6,
    index: 7,
  },
];

const NewRankPopup = ({ onClick }) => {
  const {
    user: { userData },
    updateUser,
  } = useUserStore();

  const character_props = get(userData, 'userData.character_props', []);

  const handleButtonClick = () => {
    if (onClick) onClick();
  };

  // const handleLevelClick = (index) => {
  //   if (userData.degree <= index + 1) return;
  //   updateUser({
  //     character_props: [...character_props, { id: index + 1 }],
  //   });
  //   addCharacterPropAction(index + 1);
  // };

  useEffect(() => {
    updateUser({
      character_props: [...character_props, { id: userData.degree }],
    });

    addCharacterPropAction(userData.degree);
  }, []);

  return (
    <div className={cx(styles.BirthdayPopup)}>
      <UserAvtar />

      <p className={styles.information}>שאפו על העבודה!</p>
      <p className={styles.wishes}>{`עלית שלב ועכשיו אתה יכול לעצב את הדמות שלך`}</p>
      <p className={styles.items}>בחר פריט</p>

      <div className={styles.icons}>
        <div className={styles.layer1}>
          <Levels
            title={dataArr[0].title}
            img={dataArr[0].image}
            level={`שלב 2`}
            imgStyle={styles.img1}
            opacity={userData.degree <= dataArr[0].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[0].index}
            // onClick={() => handleLevelClick(0)}
          />
          <Levels
            title={dataArr[1].title}
            img={dataArr[1].image}
            level={`שלב 3`}
            imgStyle={styles.img2}
            opacity={userData.degree <= dataArr[1].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[1].index}
            // onClick={() => handleLevelClick(1)}
          />
          <Levels
            title={dataArr[2].title}
            img={dataArr[2].image}
            level={`שלב 4`}
            imgStyle={styles.img3}
            opacity={userData.degree <= dataArr[2].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[2].index}
            // onClick={() => handleLevelClick(2)}
          />
          <Levels
            title={dataArr[3].title}
            img={dataArr[3].image}
            level={`שלב 5`}
            imgStyle={styles.img4}
            opacity={userData.degree <= dataArr[3].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[3].index}
            // onClick={() => handleLevelClick(3)}
          />
        </div>
        <div className={styles.line}></div>
        <div className={styles.layer2}>
          <Levels
            title={dataArr[4].title}
            img={dataArr[4].image}
            level={`שלב 6`}
            imgStyle={styles.img5}
            opacity={userData.degree <= dataArr[4].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[4].index}
          />
          <Levels
            title={dataArr[5].title}
            img={dataArr[5].image}
            level={`שלב 7`}
            imgStyle={styles.img6}
            opacity={userData.degree <= dataArr[5].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[5].index}
          />
          <Levels
            title={dataArr[6].title}
            img={dataArr[6].image}
            level={`שלב 8`}
            imgStyle={styles.img7}
            opacity={userData.degree <= dataArr[6].index ? 0.5 : 1}
            isLocked={userData.degree <= dataArr[6].index}
          />
        </div>
      </div>

      <div className={styles.btn} onClick={handleButtonClick}>
        <Button>אישור</Button>
      </div>
    </div>
  );
};

export default NewRankPopup;
