import React from 'react';

import styles from './PopupType4.module.scss';
import publico from '../../assets/publico.png';

const PopupType4 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.PopupType4}>
          <div className={styles.box}>
            <div className={styles.logo}>
              <img src={publico} alt="" />
            </div>
            <div className={styles.desc}>
              <p className={styles.text1}>נח קצת 😇</p>
              <p className={styles.text1}>נתראה במוצ"ש</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupType4;
