import * as React from 'react';
const HomeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7996 7.55667C15.8685 7.4815 15.9216 7.39329 15.9558 7.29727C15.99 7.20124 16.0046 7.09934 15.9988 6.99757C15.9929 6.8958 15.9668 6.79623 15.9218 6.70474C15.8769 6.61325 15.8141 6.53169 15.7371 6.46488L8.58681 0.208403C8.42332 0.0715558 8.21629 -0.00233898 8.00309 5.64627e-05C7.7899 0.0024519 7.58458 0.0809797 7.42421 0.221466L0.249885 6.79833C0.174207 6.86655 0.113095 6.94937 0.0702189 7.0418C0.0273427 7.13423 0.00358618 7.23437 0.000375567 7.33621C-0.00283505 7.43805 0.0145664 7.53949 0.0515353 7.63444C0.0885042 7.72939 0.144278 7.81589 0.215509 7.88874L0.39564 8.07575C0.530402 8.22476 0.717198 8.31638 0.917518 8.33173C1.11784 8.34708 1.31641 8.28499 1.4723 8.15825L2.00857 7.67698V14.7083C2.00857 14.9153 2.09079 15.1137 2.23713 15.2601C2.38347 15.4064 2.58195 15.4886 2.78891 15.4886H5.58508C5.79204 15.4886 5.99052 15.4064 6.13686 15.2601C6.2832 15.1137 6.36542 14.9153 6.36542 14.7083V9.79044H9.9323V14.7083C9.92651 14.8083 9.94114 14.9084 9.97531 15.0026C10.0095 15.0967 10.0625 15.183 10.131 15.256C10.1996 15.329 10.2823 15.3873 10.3742 15.4273C10.466 15.4673 10.565 15.4882 10.6652 15.4886H13.6291C13.8361 15.4886 14.0346 15.4064 14.1809 15.2601C14.3272 15.1137 14.4095 14.9153 14.4095 14.7083V7.7753L14.7402 8.06544C14.9224 8.22563 15.3053 8.09706 15.5954 7.77805L15.7996 7.55667Z"
      fill="#9EA6B4"
    />
  </svg>
);
export default HomeIcon;
