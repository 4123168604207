import React from 'react';
import styles from './BackdropScreen.module.scss';

const BackdropScreen = ({ children, onClick }) => {
  return (
    <div className={styles.BackdropScreen} onClick={onClick}>
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>
  );
};

export default BackdropScreen;
