import * as React from 'react';
const Decoration = (props) => (
  <svg
    width={284}
    height={206}
    viewBox="0 0 284 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={146} cy={162.279} r={43} fill="#3FE2D2" />
    <mask
      id="mask0_2242_110943"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={103}
      y={119}
      width={86}
      height={87}
    >
      <circle cx={146} cy={162.279} r={43} fill="white" />
    </mask>
    <g mask="url(#mask0_2242_110943)">
      <path
        d="M144.297 219.472V195.619L139.386 197.296V213.45L134.606 217.946C133.908 218.602 134.372 219.774 135.33 219.774H143.995C144.162 219.774 144.297 219.639 144.297 219.472Z"
        fill="#A573FE"
      />
      <path
        d="M148.83 219.472V195.619L153.741 197.296V213.45L158.521 217.946C159.219 218.602 158.755 219.774 157.797 219.774H149.132C148.965 219.774 148.83 219.639 148.83 219.472Z"
        fill="#A573FE"
      />
      <path
        d="M124.107 141.619C124.107 135.965 128.691 131.381 134.346 131.381H158.917C164.571 131.381 169.155 135.965 169.155 141.619V184.473C169.155 195.862 159.922 205.095 148.533 205.095H144.73C133.34 205.095 124.107 195.862 124.107 184.473V141.619Z"
        fill="#A573FE"
      />
      <path
        d="M119.381 116.971C119.381 128.304 128.568 137.492 139.901 137.492H162.014C162.014 126.159 152.827 116.971 141.494 116.971H119.381Z"
        fill="#F8DF2A"
      />
      <path
        d="M123.898 183.313H169.337V202.76C169.337 206.427 166.364 209.401 162.696 209.401H130.539C126.872 209.401 123.898 206.427 123.898 202.76V183.313Z"
        fill="#FF3B79"
      />
      <path
        d="M138.404 165.139C139.989 166.822 142.995 167.959 146.443 167.959C149.892 167.959 152.898 166.822 154.483 165.139"
        stroke="black"
        strokeWidth={2.4176}
        strokeLinecap="round"
      />
      <path
        d="M124.483 185.254C121.964 192.121 120.821 198.501 121.225 202.238"
        stroke="#FF3B79"
        strokeWidth={3.14575}
        strokeLinecap="round"
      />
      <path
        d="M168.655 185.408C171.175 192.274 172.317 198.654 171.914 202.392"
        stroke="#FF3B79"
        strokeWidth={3.14575}
        strokeLinecap="round"
      />
      <ellipse cx={138.946} cy={154.053} rx={6.64104} ry={6.69804} fill="white" />
      <ellipse cx={139.037} cy={154.218} rx={2.62146} ry={2.64396} fill="black" />
      <ellipse cx={155.373} cy={154.053} rx={6.64104} ry={6.69804} fill="white" />
      <ellipse cx={155.465} cy={154.218} rx={2.62146} ry={2.64396} fill="black" />
      <path
        d="M147.673 184.487C147.143 184.067 147.143 183.263 147.673 182.843L151.489 179.821C152.176 179.277 153.188 179.766 153.188 180.643L153.188 186.687C153.188 187.564 152.176 188.054 151.489 187.509L147.673 184.487Z"
        fill="black"
      />
      <path
        d="M146.995 184.487C147.525 184.067 147.525 183.263 146.995 182.843L143.179 179.821C142.492 179.277 141.48 179.766 141.48 180.643L141.48 186.687C141.48 187.564 142.492 188.054 143.179 187.509L146.995 184.487Z"
        fill="black"
      />
    </g>
    <path
      d="M117.334 116.971C117.334 128.304 126.521 137.492 137.854 137.492H159.967C159.967 126.159 150.78 116.971 139.447 116.971H117.334Z"
      fill="#F8DF2A"
    />
    <path
      d="M124.227 93.1722C124.532 91.5302 123.368 90.3622 122.248 89.2391C121.899 88.8887 121.577 88.5652 121.29 88.2238C119.66 86.2655 119.83 83.8874 121.729 82.0049C121.926 81.8077 122.141 81.6195 122.365 81.4133C123.386 80.4811 124.451 79.5131 124.371 78.104C124.308 76.7846 123.332 75.8771 122.383 74.9965C122.096 74.7269 121.818 74.4753 121.568 74.2058C120.753 73.3343 119.714 71.951 120.198 70.04C120.538 68.6942 121.442 67.9234 122.32 67.1705C122.571 66.9554 122.821 66.7403 123.054 66.5162C123.896 65.7005 124.774 64.4002 124.201 62.8379"
      stroke="#EBEBEB"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M125.77 64.1874H123.509C121.78 64.1874 120.557 62.497 121.098 60.8555L123.059 54.9102H126.22L128.181 60.8555C128.722 62.497 127.499 64.1874 125.77 64.1874Z"
      fill="#F5D400"
    />
    <path
      d="M146.278 24.7147C146.278 39.4227 136.59 56.2626 124.639 56.2626C112.688 56.2626 103 39.4227 103 24.7147C103 10.0067 112.688 3 124.639 3C136.59 3 146.278 10.0067 146.278 24.7147Z"
      fill="#FFDD65"
    />
    <path
      d="M136.672 12.7988C134.946 11.2158 132.756 10.0992 130.165 9.47937C129.183 9.24425 128.197 9.84995 127.963 10.8316C127.728 11.8132 128.333 12.799 129.315 13.0339C131.295 13.5073 132.939 14.3344 134.202 15.4925C135.45 16.6361 136.361 18.165 136.91 20.0369C137.143 20.8336 137.872 21.3502 138.662 21.3502C138.833 21.3502 139.006 21.326 139.178 21.2758C140.146 20.9915 140.701 19.9763 140.416 19.0078C139.677 16.4883 138.418 14.399 136.672 12.7988Z"
      fill="#E2CA2D"
    />
    <path
      d="M74.2072 150.275C74.5117 148.633 73.3475 147.465 72.2281 146.342C71.8789 145.991 71.5565 145.668 71.2699 145.326C69.64 143.368 69.8102 140.99 71.7087 139.107C71.9057 138.91 72.1206 138.722 72.3445 138.516C73.3654 137.584 74.4311 136.616 74.3505 135.207C74.2878 133.887 73.3117 132.98 72.3624 132.099C72.0759 131.829 71.7983 131.578 71.5475 131.308C70.7326 130.437 69.6938 129.054 70.1774 127.143C70.5177 125.797 71.4221 125.026 72.2998 124.273C72.5505 124.058 72.8012 123.843 73.0341 123.619C73.8759 122.803 74.7535 121.503 74.1804 119.94C73.858 119.07 73.1684 118.494 72.4341 117.892C72.1117 117.623 71.7803 117.353 71.4848 117.048C68.7266 114.235 70.5087 112.245 72.0848 110.497"
      stroke="#EBEBEB"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M72.0998 111.389H69.9047C68.2267 111.389 67.0397 109.748 67.5652 108.155L69.4682 102.384H72.5366L74.4395 108.155C74.9648 109.748 73.7779 111.389 72.0998 111.389Z"
      fill="#00A9CE"
    />
    <path
      d="M92.0061 73.0766C92.0061 87.3524 82.6028 103.697 71.0031 103.697C59.4033 103.697 50 87.3524 50 73.0766C50 58.8008 59.4033 52 71.0031 52C82.6028 52 92.0061 58.8008 92.0061 73.0766Z"
      fill="#25CDEC"
    />
    <path
      d="M82.2135 61.153C80.538 59.6168 78.413 58.5328 75.8975 57.9314C74.9447 57.7034 73.9879 58.2913 73.7599 59.2439C73.5322 60.1967 74.1199 61.1535 75.0724 61.3815C76.9943 61.841 78.5904 62.6438 79.8163 63.7678C81.0269 64.8776 81.9111 66.3618 82.4443 68.1787C82.6711 68.952 83.3784 69.4534 84.1454 69.4534C84.3107 69.4534 84.479 69.43 84.6455 69.381C85.5855 69.105 86.1238 68.1196 85.8478 67.1798C85.1301 64.7336 83.9072 62.706 82.2135 61.153Z"
      fill="#00A9CE"
    />
    <path
      d="M208.227 140.172C208.531 138.53 207.367 137.362 206.248 136.239C205.898 135.889 205.576 135.565 205.289 135.224C203.66 133.266 203.83 130.887 205.728 129.005C205.925 128.808 206.14 128.62 206.364 128.413C207.385 127.481 208.451 126.513 208.37 125.104C208.307 123.785 207.331 122.877 206.382 121.997C206.095 121.727 205.818 121.475 205.567 121.206C204.752 120.334 203.713 118.951 204.197 117.04C204.537 115.694 205.442 114.923 206.319 114.171C206.57 113.955 206.821 113.74 207.054 113.516C207.895 112.701 208.773 111.4 208.2 109.838C207.877 108.967 207.188 108.392 206.454 107.79C206.131 107.52 205.8 107.251 205.504 106.945C202.746 104.133 204.528 102.143 206.104 100.394C207.018 99.3722 207.967 98.3233 208.146 97.0939C208.388 95.4967 207.286 94.2839 206.113 92.9992C204.636 91.3731 202.961 89.5224 204.886 86.913C205.307 86.3481 205.854 85.8281 206.382 85.3351C207.904 83.9009 209.221 82.6729 207.716 80.4455C207.367 79.9335 206.866 79.4304 206.328 78.9003"
      stroke="#EBEBEB"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M208.602 80.1508H206.064C204.123 80.1508 202.751 78.2535 203.358 76.4112L205.559 69.7383H209.107L211.307 76.4112C211.915 78.2538 210.542 80.1508 208.602 80.1508Z"
      fill="#AD6BFF"
    />
    <path
      d="M231.574 36.372C231.574 52.8799 220.7 71.7806 207.287 71.7806C193.874 71.7806 183 52.8799 183 36.372C183 19.8641 193.874 12 207.287 12C220.7 12 231.574 19.8641 231.574 36.372Z"
      fill="#C89CFF"
    />
    <path
      d="M224.453 29.5533C223.623 26.7252 222.209 24.3803 220.25 22.5842C218.312 20.8078 215.855 19.5542 212.946 18.8588C211.844 18.5958 210.738 19.2748 210.475 20.3765C210.211 21.4783 210.891 22.5847 211.992 22.8483C214.215 23.3797 216.06 24.3081 217.478 25.6075C218.878 26.8914 219.9 28.6074 220.517 30.7084C220.779 31.6026 221.597 32.1823 222.484 32.1823C222.675 32.1823 222.869 32.1552 223.062 32.0989C224.149 31.7798 224.772 30.6403 224.453 29.5533Z"
      fill="#AD6BFF"
    />
    <path
      d="M39.1966 13.5479C42.123 13.5479 42.1279 9 39.1966 9C36.2702 9 36.2654 13.5479 39.1966 13.5479Z"
      fill="#FF8A14"
    />
    <path
      d="M257.197 31.5479C260.123 31.5479 260.128 27 257.197 27C254.27 27 254.265 31.5479 257.197 31.5479Z"
      fill="#FF8A14"
    />
    <path
      d="M185.197 91.5479C188.123 91.5479 188.128 87 185.197 87C182.27 87 182.265 91.5479 185.197 91.5479Z"
      fill="#FF8A14"
    />
    <path
      d="M270.197 64.5479C273.123 64.5479 273.128 60 270.197 60C267.27 60 267.265 64.5479 270.197 64.5479Z"
      fill="#FF8A14"
    />
    <path
      d="M159.197 6.54787C162.123 6.54787 162.128 2 159.197 2C156.27 2 156.265 6.54787 159.197 6.54787Z"
      fill="#FF8A14"
    />
    <path
      d="M94.1966 115.548C97.123 115.548 97.1279 111 94.1966 111C91.2702 111 91.2654 115.548 94.1966 115.548Z"
      fill="#FF8A14"
    />
    <path
      d="M161.886 37.9654C164.812 37.9654 164.817 33.4175 161.886 33.4175C158.96 33.4175 158.955 37.9654 161.886 37.9654Z"
      fill="#FF8A14"
    />
    <path
      d="M235.196 121.548C238.123 121.548 238.128 117 235.196 117C232.27 117 232.265 121.548 235.196 121.548Z"
      fill="#FF8A14"
    />
    <path
      d="M19.1966 82.5479C22.123 82.5479 22.1279 78 19.1966 78C16.2702 78 16.2654 82.5479 19.1966 82.5479Z"
      fill="#FF8A14"
    />
    <path
      d="M40.1966 121.548C43.123 121.548 43.1279 117 40.1966 117C37.2702 117 37.2654 121.548 40.1966 121.548Z"
      fill="#FF8A14"
    />
    <path
      d="M282.824 96.666C281.936 95.778 280.496 95.778 279.608 96.666L273.666 102.608C272.778 103.496 272.778 104.936 273.666 105.824C274.11 106.268 274.692 106.49 275.274 106.49C275.856 106.49 276.438 106.268 276.882 105.824L282.824 99.8817C283.712 98.9939 283.712 97.5541 282.824 96.666Z"
      fill="#00F5BC"
    />
    <path
      d="M17.8817 124.666C16.9936 123.778 15.5538 123.778 14.666 124.666C13.778 125.554 13.778 126.994 14.666 127.882L20.608 133.824C21.0519 134.268 21.634 134.49 22.2158 134.49C22.7976 134.49 23.3798 134.268 23.8236 133.824C24.7117 132.936 24.7117 131.496 23.8236 130.608L17.8817 124.666Z"
      fill="#FF6196"
    />
    <path
      d="M6.60798 48.666L0.666036 54.608C-0.222012 55.496 -0.222012 56.9359 0.666036 57.8236C1.10991 58.2675 1.69203 58.4897 2.27385 58.4897C2.85568 58.4897 3.43781 58.2678 3.88168 57.8236L9.82363 51.8817C10.7117 50.9936 10.7117 49.5538 9.82363 48.666C8.93558 47.778 7.49573 47.778 6.60798 48.666Z"
      fill="#00F5BC"
    />
    <path
      d="M170.466 84.0061C171.161 82.9605 170.877 81.5489 169.832 80.8536C168.786 80.158 167.375 80.4418 166.679 81.4875L162.025 88.4841C161.677 89.0067 161.574 89.6212 161.689 90.1917C161.804 90.7621 162.136 91.289 162.659 91.6366C163.705 92.3322 165.116 92.0484 165.812 91.0028L170.466 84.0061Z"
      fill="#00F5BC"
    />
    <path
      d="M228.882 148.666C227.994 147.778 226.554 147.778 225.666 148.666C224.778 149.554 224.778 150.994 225.666 151.882L231.608 157.824C232.052 158.268 232.634 158.49 233.216 158.49C233.798 158.49 234.38 158.268 234.824 157.824C235.712 156.936 235.712 155.496 234.824 154.608L228.882 148.666Z"
      fill="#00F5BC"
    />
    <path
      d="M74.7369 1.78577C73.9563 2.76959 74.121 4.2 75.1048 4.9803L81.6876 10.2034C82.6714 10.984 84.1018 10.8192 84.8821 9.83543C85.2723 9.34369 85.4265 8.73995 85.3599 8.16195C85.2933 7.58394 85.0062 7.03104 84.5142 6.6409L77.9314 1.41784C76.9479 0.636896 75.5175 0.801642 74.7369 1.78577Z"
      fill="#FF6196"
    />
    <path
      d="M237.647 77.4102C236.759 76.5221 235.319 76.5221 234.432 77.4102C233.544 78.2982 233.544 79.7381 234.432 80.6258L240.374 86.5678C240.817 87.0116 241.4 87.2339 241.981 87.2339C242.563 87.2339 243.145 87.012 243.589 86.5678C244.477 85.6797 244.477 84.2399 243.589 83.3521L237.647 77.4102Z"
      fill="#FF6196"
    />
    <path
      d="M52 161C51.8021 161 51.6237 160.881 51.5477 160.698C50.5249 158.229 49.7714 157.475 47.3024 156.452C47.1195 156.377 47 156.198 47 156C47 155.802 47.1193 155.623 47.3024 155.548C49.7714 154.525 50.5251 153.771 51.5477 151.302C51.6237 151.119 51.8021 151 52 151C52.1979 151 52.3765 151.119 52.4523 151.302C53.4749 153.771 54.2288 154.525 56.6976 155.548C56.8805 155.623 57 155.802 57 156C57 156.198 56.8805 156.377 56.6976 156.452C54.2288 157.475 53.4749 158.229 52.4523 160.698C52.3765 160.881 52.1979 161 52 161Z"
      fill="#FFDE03"
    />
    <path
      d="M95.5 137C95.3615 137 95.2366 136.916 95.1834 136.788C94.4674 135.06 93.94 134.533 92.2117 133.817C92.0836 133.764 92 133.639 92 133.5C92 133.361 92.0835 133.236 92.2117 133.183C93.94 132.467 94.4676 131.94 95.1834 130.211C95.2366 130.083 95.3615 130 95.5 130C95.6385 130 95.7636 130.083 95.8166 130.211C96.5324 131.94 97.0602 132.467 98.7883 133.183C98.9164 133.236 99 133.361 99 133.5C99 133.639 98.9164 133.764 98.7883 133.817C97.0602 134.533 96.5324 135.06 95.8166 136.788C95.7636 136.917 95.6385 137 95.5 137Z"
      fill="#FFDE03"
    />
    <path
      d="M205.5 160C205.361 160 205.237 159.916 205.183 159.788C204.467 158.06 203.94 157.533 202.212 156.817C202.084 156.764 202 156.639 202 156.5C202 156.361 202.083 156.236 202.212 156.183C203.94 155.467 204.468 154.94 205.183 153.211C205.237 153.083 205.361 153 205.5 153C205.639 153 205.764 153.083 205.817 153.211C206.532 154.94 207.06 155.467 208.788 156.183C208.916 156.236 209 156.361 209 156.5C209 156.639 208.916 156.764 208.788 156.817C207.06 157.533 206.532 158.06 205.817 159.788C205.764 159.917 205.639 160 205.5 160Z"
      fill="#FFDE03"
    />
    <path
      d="M249 60C248.802 60 248.624 59.8805 248.548 59.6977C247.525 57.229 246.771 56.4752 244.302 55.4522C244.119 55.3766 244 55.1979 244 54.9999C244 54.8019 244.119 54.6234 244.302 54.5476C246.771 53.5246 247.525 52.771 248.548 50.3021C248.624 50.1191 248.802 50 249 50C249.198 50 249.377 50.1191 249.452 50.3021C250.475 52.771 251.229 53.5246 253.698 54.5476C253.881 54.6234 254 54.8019 254 54.9999C254 55.1979 253.881 55.3766 253.698 55.4522C251.229 56.4752 250.475 57.229 249.452 59.6977C249.377 59.8807 249.198 60 249 60Z"
      fill="#FFDE03"
    />
    <path
      d="M44 42C43.8021 42 43.6237 41.8805 43.5477 41.6977C42.5249 39.229 41.7714 38.4752 39.3024 37.4522C39.1195 37.3766 39 37.1979 39 36.9999C39 36.8019 39.1193 36.6234 39.3024 36.5476C41.7714 35.5246 42.5251 34.771 43.5477 32.3021C43.6237 32.1191 43.8021 32 44 32C44.1979 32 44.3765 32.1191 44.4523 32.3021C45.4749 34.771 46.2288 35.5246 48.6976 36.5476C48.8805 36.6234 49 36.8019 49 36.9999C49 37.1979 48.8805 37.3766 48.6976 37.4522C46.2288 38.4752 45.4749 39.229 44.4523 41.6977C44.3765 41.8807 44.1979 42 44 42Z"
      fill="#FFDE03"
    />
  </svg>
);
export default Decoration;
