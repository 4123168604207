import React from 'react';

import cx from 'classnames';

import Icons from '../icons';
import { ICONS } from '../../constants/icons.constanst';

import styles from './passwordChecker.module.scss';

const PasswordChecker = ({ text, error, success, iconType = `${ICONS.GRAYSUCCESS}` }) => {
  return (
    <div className={cx(styles.PasswordChecker)}>
      <Icons type={iconType} />
      <p className={cx(styles.text, success && [styles.successText], error && [styles.errorText])}>
        {text}
      </p>
    </div>
  );
};

export default PasswordChecker;
