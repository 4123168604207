import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Button, Header2, Input } from '../../components';
import forgetPasswordSuccess from '../../assets/forgetPasswordSuccess.png';
import { ROUTES } from '../../constants/routes.constant';
import { validateEmail } from '../../helpers/general.helpers';
import { passwordReset } from '../../actions/user.actions';

import styles from './forgetPassword.module.scss';

const ForgetPasssword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);

  const [generalError, setGeneralError] = useState('');
  const [emailErr, setEmailErr] = useState('');

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailErr('הקלד כתובת אימייל תקינה ');
      return;
    }
    setIsLoading(true);
    const token = await passwordReset(email);

    if (isEmpty(token)) {
      setGeneralError('אימייל לא קיים');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    navigate(ROUTES.OTP_SCREEN, {
      state: { email, token, isForgetPassword: true },
    });
    setGeneralError('');
  };

  return (
    <div className={styles.ForgetPasssword}>
      <Header2
        isUser={true}
        title="שכחתי סיסמה"
        description={
          !mailSent
            ? 'כדי לאפס את הסיסמה יש להזין כתובת אימייל'
            : `לינק לאיפוס הסיסמה נשלח לאימייל שלך: \n ${email}`
        }
      />
      {!mailSent ? (
        <div>
          <Input
            placeholder={'אימייל'}
            className={styles.Input}
            setValue={(val) => {
              setEmail(val);
              setEmailErr('');
            }}
            error={emailErr}
            value={email}
          />
          {generalError && <p className={styles.generalError}>{generalError}</p>}
          <Button disable={!email} onClick={handleSubmit} isLoading={isLoading}>
            שלח
          </Button>
        </div>
      ) : (
        <div>
          <div className={styles.img}>
            <img src={forgetPasswordSuccess} alt="" />
          </div>
          <p className={styles.enteremail}>יש להיכנס לאימייל להמשך איפוס הסיסמה</p>
          <p className={styles.existingAcc}>
            {' לא קיבלתי, '}
            <span className={styles.login}> תשלחו לי אימייל חדש</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default ForgetPasssword;
