import React from 'react';

import { get, orderBy } from 'lodash';

import one from '../../assets/App_Icon_1024.png';
import ImageWrap from '../imagewrap/ImageWrap';
import ShareOption from '../icons/ShareOption';
import { getFormattedDate, numberWithCommas } from '../../helpers/general.helpers';
// import { BASE_URL } from '../../axios';

import styles from './NormalPackage.module.scss';
import { BASE_URL } from '../../axios';

const NormalPackage = ({ handleClick, data, isStatsScreen }) => {
  let date = new Date(get(data, 'created_at', '') * 1000);
  // console.log(`${BASE_URL}/v1/photos/leads/${data.id}.jpeg`, 'normal');

  date = getFormattedDate(date, '.');

  // const handleSort = () => {
  //   orderBy(<HotLeadItem />, date, 'asc');
  // };
  orderBy(date, 'asc');

  return (
    <div className={styles.NormalPackage}>
      <div className={styles.section1}>
        <div className={styles.subSection2}>
          <p className={styles.date}>{date}</p>
          <div className={styles.ContainerWithSvg}>
            <div className={styles.icon}>
              {/* i want to write api call in img  */}
              {data.photo ? (
                <img
                  crossOrigin="anonymous"
                  src={`${BASE_URL}v1/photos/leads/${data.photo}`}
                  alt=""
                />
              ) : (
                <img crossOrigin="anonymous" src={one} alt="" />
              )}
            </div>
            <div className={styles.description}>
              <div className={styles.part1}>
                <p className={styles.text2}>{get(data, 'company_name', '')}</p>
                <div className={styles.colorLine}></div>
                <p className={styles.text1}> {get(data, 'category', '')}</p>
              </div>
              <p className={styles.text3}> {get(data, 'subcategory', '')}</p>
            </div>
          </div>
        </div>
        <div className={styles.subSection1}>
          <div className={styles.number}> {numberWithCommas(get(data, 'valuation', ''))}</div>
          <p className={styles.text}>₪</p>
        </div>
      </div>
      <div className={styles.section2}>
        {isStatsScreen ? (
          <div className={styles.right}>
            <p style={{ marginRight: 5 }}>{get(data, 'clicks_count', '-')}</p>
            <p className={styles.countText}>לחיצות</p>
            <p className={styles.countText}>|</p>
            <p style={{ marginRight: 5 }}>{get(data, 'register_count', '-')}</p>
            <p className={styles.countText}>רשומים</p>
          </div>
        ) : (
          <div className={styles.right}>
            <p className={styles.text}>שיתופים:</p>
            <div className={styles.icon1}>
              <ImageWrap data={data} />
            </div>
          </div>
        )}
        {data?.enabled && (
          <div className={styles.share} onClick={() => handleClick(data)}>
            <ShareOption />
          </div>
        )}
      </div>
    </div>
  );
};

export default NormalPackage;
