import React, { useEffect } from 'react';
import styles from './Popup.module.scss';
import Button from '../button/Button';
import CrossIcon from '../icons/CrossIcon';
import one from '../../assets/tradingLeadlogo.png';
import Icons from '../icons';
import icons from '../icons';
import { ICONS } from '../../constants/icons.constanst';
import { get } from 'lodash';
import HotImage from '../../assets/App_Icon_1024.png';
import { numberWithCommas } from '../../helpers/general.helpers';
import { BASE_URL } from '../../axios';

const Popup = ({ handleClose, ClosedNextPage, isLoading, ...props }) => {
  const { data } = props;

  const [imnage, setImage] = React.useState(HotImage);

  useEffect(() => {
    if (data?.photo) {
      setImage(`${BASE_URL}v1/photos/leads/${data.photo}`);
    }
  }, [data]);

  // console.log(data);
  return (
    <div className={styles.Popup}>
      <div className={styles.navbar}>
        <div className={styles.count}>
          <span className={styles.number}>{numberWithCommas(get(data, 'valuation', ''))}</span>
          <span className={styles.text}>₪</span>
        </div>
        <p className={styles.title}>שיתוף ליד</p>
        <div className={styles.crossIcon} onClick={handleClose}>
          <CrossIcon />
          {/* <Icons type={ICONS.CALENDA} /> */}
        </div>
      </div>
      <div className={styles.leadDescription}>
        <div className={styles.imageContainer}>
          <img
            src={imnage}
            alt="lead"
            crossOrigin="anonymous"
            className={styles.image}
            onError={(e) => {
              setImage(HotImage);
            }}
          />
        </div>
        <div className={styles.description}>
          <div className={styles.part1}>
            <p className={styles.text2}>{get(data, 'company_name', '')}</p>
            <div className={styles.colorLine}></div>
            <p className={styles.text1}>{get(data, 'category', '')}</p>
          </div>
          <p className={styles.text3}>{get(data, 'subcategory', '')}</p>
        </div>
        <p className={styles.information}>{get(data, 'description', '')}</p>
      </div>
      <div className={styles.btn} onClick={() => ClosedNextPage(data)}>
        <Button isLoading={isLoading}>אני רוצה לקבל קישור</Button>
      </div>
    </div>
  );
};

export default Popup;
