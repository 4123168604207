import * as React from 'react';
const SVGComponent = (props) => (
  <svg
    width={150}
    height={275}
    viewBox="0 0 138 303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.0133 296.342V241.011L52.6218 244.899V282.372L41.5328 292.802C39.9141 294.325 40.9915 297.043 43.2138 297.043H63.3123C63.6995 297.043 64.0133 296.729 64.0133 296.342Z"
      fill="#222222"
    />
    <path
      d="M74.5291 296.342V241.011L85.9207 244.899V282.372L97.0097 292.802C98.6284 294.325 97.5509 297.043 95.3287 297.043H75.2302C74.843 297.043 74.5291 296.729 74.5291 296.342Z"
      fill="#222222"
    />
    <path
      d="M15.715 115.999C15.715 102.882 26.3479 92.2493 39.4644 92.2493H96.4631C109.58 92.2493 120.213 102.882 120.213 115.999V215.408C120.213 241.828 98.795 263.245 72.375 263.245H63.5525C37.1326 263.245 15.715 241.828 15.715 215.408V115.999Z"
      fill="#A573FE"
    />
    <g clipPath="url(#clip0_2636_102813)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11192 262.282C9.04193 262.433 10.6286 260.861 10.6559 258.77C10.7729 249.792 14.9479 234.218 22.6153 217.405C23.4514 215.571 22.7189 213.261 20.9791 212.244C19.2394 211.227 17.1513 211.889 16.3152 213.723C8.50044 230.859 3.80526 247.592 3.66669 258.22C3.63943 260.311 5.18191 262.13 7.11192 262.282Z"
        fill="#FF3B79"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.762 262.282C127.832 262.433 126.245 260.861 126.218 258.77C126.101 249.792 121.926 234.218 114.258 217.405C113.422 215.571 114.155 213.261 115.894 212.244C117.634 211.227 119.722 211.889 120.558 213.723C128.373 230.859 133.068 247.592 133.207 258.22C133.234 260.311 131.692 262.13 129.762 262.282Z"
        fill="#FF3B79"
      />
      <path
        d="M15.7148 212.716H120.212V257.826C120.212 266.334 113.315 273.231 104.807 273.231H31.1201C22.612 273.231 15.7148 266.334 15.7148 257.826V212.716Z"
        fill="#FF3B79"
      />
    </g>
    <path
      d="M49.4373 170.557C53.1125 174.463 60.0869 177.1 68.0859 177.1C76.0848 177.1 83.0592 174.463 86.7344 170.557"
      stroke="black"
      strokeWidth={5.60814}
      strokeLinecap="round"
    />
    <ellipse cx={49.164} cy={144.843} rx={15.4053} ry={15.5375} fill="white" />
    <ellipse cx={49.3766} cy={145.226} rx={6.08104} ry={6.13324} fill="black" />
    <ellipse cx={87.2745} cy={144.841} rx={15.4053} ry={15.5375} fill="white" />
    <ellipse cx={87.4871} cy={145.226} rx={6.08104} ry={6.13324} fill="black" />
    <path
      d="M113.352 40.4988C113.352 40.4988 94.7928 36.3715 68.3606 36.3715C41.9284 36.3715 23.3697 40.4988 23.3697 40.4988C23.3697 40.4988 34.055 83.8356 33.8675 94.3415H102.854C102.666 84.0232 113.352 40.4988 113.352 40.4988Z"
      fill="black"
    />
    <path
      d="M104.541 80.2692H32.1801C33.3048 86.2725 34.0547 91.5255 33.8672 94.152H102.853C102.853 91.5255 103.603 86.4601 104.541 80.2692Z"
      fill="#2ACAE6"
    />
    <path d="M114.664 91.338H22.0571V98.8422H114.664V91.338Z" fill="black" />
    <defs>
      <clipPath id="clip0_2636_102813">
        <rect width={129} height={60} fill="white" transform="translate(4.00032 213)" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
