import React from 'react';
import styles from './Snackbar.module.scss';
import CrossIcon from '../icons/CrossIcon';
import snackBarStore from '../../zustand/snackBarStore';
import leadsStore from '../../zustand/leadsStore';
import { useNavigate } from 'react-router-dom';

const Snackbar = () => {
  const { leadsLink } = leadsStore((state) => state);
  const { isOpen, message, closeSnackBar } = snackBarStore((state) => state);

  const navigate = useNavigate();

  return (
    isOpen && (
      <div className={styles.Snackbar} dir="ltr">
        <div className={styles.crossIcon} onClick={() => closeSnackBar()}>
          <CrossIcon />
        </div>
        <div className={styles.desc}>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/leads');
              closeSnackBar();
            }}
          >
            {/* <a href={leadsLink} target="_blank"> */}
            <p className={styles.Link}>׳הלידים שלי׳</p>
          </div>
          {/* </a> */}
          <p className={styles.information}>הקישור ששלחת נשמר בעמוד </p>
        </div>
      </div>
    )
  );
};

export default Snackbar;
