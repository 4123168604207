import styles from './ErrorPopup.module.scss';
import logo from '../../assets/App_Icon_1024.png';
import Button from '../button/Button';
import cx from 'classnames';
// import { useLayoutEffect, useState } from 'react';

const ErrorPopup = () => {
  // const [shouldShowPopup, setShouldShowPopup] = useState(true);
  // const [isMobile, setIsMobile] = useState(false);

  const handleAppButton = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      // open this link: https://apps.apple.com/us/app/publico/id6468648222
      window.location.href = 'https://apps.apple.com/us/app/publico/id6468648222';
    }

    if (isAndroid) {
      // open this link: https://play.google.com/store/apps/details?id=com.publico
      window.location.href = 'https://play.google.com/store/apps/details?id=com.publicoltd.publico';
    }
  };

  // useLayoutEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // if (!shouldShowPopup || !isMobile) {
  //   return null;
  // }

  return (
    <div className={cx(styles.ErrorPopup, styles.nonDispaly)}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <img crossOrigin="anonymous" src={logo} alt="Logo" />
        </div>
        <div className={styles.text}>Open in app</div>
        <Button onClick={handleAppButton} buttonClassName={styles.button}>
          Open in app
        </Button>
        {/* <Button onClick={() => setShouldShowPopup(false)} buttonClassName={styles.button2}>
          Continue in browser
        </Button> */}
      </div>
    </div>
  );
};

export default ErrorPopup;
