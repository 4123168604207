import React, { useRef, useState } from 'react';
import styles from './FilterPopup.module.scss';
//
import cx from 'classnames';
import MultiRangeSlider from 'multi-range-slider-react';
import { filter, get, map, orderBy } from 'lodash';

import CrossIcon from '../icons/CrossIcon';
import Search from '../icons/Search';
import Filter from '../icons/Filter';
import UpArrow from '../icons/UpArrow';
import Message from '../icons/Message';
import Button from '../button/Button';
import Menu from '../menubar/Menu';
import leadsStore from '../../zustand/leadsStore';
import filterSectionStore from '../../zustand/filterSection';

import { fetchLeadsData, fetchMyLeads } from '../../actions/leads.actions';

const sortArray = ['תאריך', 'שם החברה', 'פופולריות', 'רווח'];

const FilterPopup = ({ handleFilterPopupClose }) => {
  const filterOption = {
    תאריך: 'created_at',
    'שם החברה': 'company_name',
    פופולריות: 'shares_count',
    רווח: 'valuation',
  };

  const {
    setLeadData,
    // setHomeScreenFilter,
    // setStatsScreenFilter,
    allLeads,
    allMyLeads,
    myleads,
    setMyLeads,
  } = leadsStore();

  const {
    currentScreen,
    setHomeScreenFilter,
    setStatsScreenFilter,
    homeScreenFilter,
    statsScreenFilter,
  } = filterSectionStore();

  const inputRef = useRef(null);
  const [click, setClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [homeSearchKey, setHomeSearchKey] = useState(homeScreenFilter?.searchKey || '');
  const [statsSearchKey, setStatsSearchKey] = useState(statsScreenFilter?.searchKey || '');
  const [homeRangeValue, setHomeRangeValue] = useState({
    min: homeScreenFilter?.rangeMinValue || 0,
    max: homeScreenFilter?.rangeMaxValue || 2000,
  });
  const [statsRangeValue, setStatsRangeValue] = useState({
    min: statsScreenFilter?.rangeMinValue || 0,
    max: statsScreenFilter?.rangeMaxValue || 2000,
  });
  const [homeSortOption, setHomeSortOption] = useState(homeScreenFilter?.sortOption || '');
  const [statsSortOption, setStatsSortOption] = useState(statsScreenFilter?.sortOption || '');

  // for sorting
  const handleClick = (v) => {
    if (currentScreen === 'Home') {
      setHomeSortOption(v);
    }
    if (currentScreen === 'Stats') {
      setStatsSortOption(v);
    }

    //
    // setSort((prev) => !prev);
    // const data = orderBy(allLeads, (lead) => lead[filterOption[v]], sort ? 'asc' : 'desc');
    // setLeadData(data);
    // handleFilterPopupClose();
  };

  const handlefocus = () => {
    setClick(true);
  };
  //for searching
  const handleChange = (e) => {
    if (currentScreen === 'Home') {
      setHomeSearchKey(e.target.value);
      setHomeScreenFilter({ searchKey: e.target.value });
    } else if (currentScreen === 'Stats') {
      setStatsSearchKey(e.target.value);
      setStatsScreenFilter({ searchKey: e.target.value });
    }

    // setValue(e.target.value);
  };

  const leadsToShow = filter(
    allLeads,
    (lead) =>
      lead.company_name.toLowerCase().includes(homeSearchKey.toLowerCase()) ||
      lead.category.toLowerCase().includes(homeSearchKey.toLowerCase()),
  );

  const myLeadsToShow = filter(
    allMyLeads,
    (lead) =>
      lead.company_name.toLowerCase().includes(statsSearchKey.toLowerCase()) ||
      lead.category.toLowerCase().includes(statsSearchKey.toLowerCase()),
  );

  // console.log(click, 'click');

  const handleMenuitems = (value) => {
    if (currentScreen === 'Home') {
      setHomeSearchKey(value);
      setHomeScreenFilter({ searchKey: value });

      // const data = filter(allLeads, (item) => {
      //   if (item.company_name === value) return item;
      // });

      // setLeadData(data);
    } else if (currentScreen === 'Stats') {
      setStatsSearchKey(value);
      setStatsScreenFilter({ searchKey: value });

      // const data = filter(myleads, (item) => {
      //   if (item.company_name === value) return item;
      // });

      // setMyLeads(data);
    }

    setClick(false);

    // setValue(value);

    // handleFilterPopupClose();
  };

  const handleClearFilter = async () => {
    // setLeadData(allLeads);
    setIsLoading(true);
    if (currentScreen === 'Home') {
      setHomeScreenFilter({
        searchKey: '',
        rangeMinValue: 0,
        rangeMaxValue: 2000,
        sortOption: '',
      });
      setHomeSearchKey('');
      setHomeRangeValue({ min: 0, max: 2000 });
      setHomeSortOption('');

      const response = await fetchLeadsData();
      handleFilterPopupClose();

      setLeadData(response.data.leads);
    } else if (currentScreen === 'Stats') {
      setStatsScreenFilter({
        searchKey: '',
        rangeMinValue: 0,
        rangeMaxValue: 2000,
        sortOption: '',
      });
      setStatsSearchKey('');
      setStatsRangeValue({ min: 0, max: 2000 });
      setStatsSortOption('');

      const myLeads = await fetchMyLeads();

      const myLeadasData = orderBy(myLeads, (lead) => lead[filterOption[statsSortOption]], 'asc');

      setMyLeads(myLeadasData);

      handleFilterPopupClose();
    }
    setIsLoading(true);
    // setDomainIndex('');
    // setSortOptionIndex('');
    // setMenuItem('');
    // setRangeMinValue(0);
    // setRangeMaxValue(2000);
    // handleFilterPopupClose();
  };
  //submit pop
  const handleOkBtn = async () => {
    setIsLoading(true);
    const searchKey = currentScreen === 'Home' ? homeSearchKey : statsSearchKey;
    const sortOption = currentScreen === 'Home' ? homeSortOption : statsSortOption;

    const valuation =
      currentScreen === 'Home'
        ? `${homeRangeValue.min}-${homeRangeValue.max}`
        : `${statsRangeValue.min}-${statsRangeValue.max}`;

    // const valuation = `${minValue}-${maxValue}`;

    let data = {};
    if (currentScreen === 'Home') {
      const response = await fetchLeadsData(searchKey, valuation);
      data = get(response, 'data', []);

      const leadsData = orderBy(data.leads, (lead) => lead[filterOption[sortOption]], 'asc');
      // setLeadData(data);
      // handleFilterPopupClose();
      setHomeScreenFilter({
        searchKey,
        rangeMaxValue: homeRangeValue.max,
        rangeMinValue: homeRangeValue.min,
        sortOption: sortOption,
      });
      setLeadData(leadsData);
    } else if (currentScreen === 'Stats') {
      const myLeads = await fetchMyLeads(searchKey, valuation);

      const myLeadasData = orderBy(myLeads, (lead) => lead[filterOption[sortOption]], 'asc');

      setStatsScreenFilter({
        searchKey,
        rangeMaxValue: statsRangeValue.max,
        rangeMinValue: statsRangeValue.min,
        sortOption: sortOption,
      });
      setMyLeads(myLeadasData);
    }
    setIsLoading(false);
    // setDomainIndex(selectedDomain);
    // setSortOptionIndex(sortOption);
    // setMenuItem(value);
    // setRangeMinValue(minValue);
    // setRangeMaxValue(maxValue);
    handleFilterPopupClose();
  };

  const onSearchClick = () => {
    handlefocus();

    if (currentScreen === 'Home') {
      setHomeSearchKey('');
      setHomeScreenFilter({
        searchKey: '',
      });
    } else if (currentScreen === 'Stats') {
      setStatsSearchKey('');
      setStatsScreenFilter({
        searchKey: '',
      });
    }
  };

  return (
    <div className={styles.FilterPopup}>
      <div className={styles.navbar}>
        <p className={styles.title}>התאמה אישית</p>
        <div className={styles.crossIcon} onClick={handleFilterPopupClose}>
          <CrossIcon />
        </div>
      </div>
      <div className={styles.section1}>
        <div className={styles.header}>
          <div className={styles.filter}>
            <p className={styles.text2}>סינון</p>
            <div className={styles.icon}>
              <Filter />
            </div>
          </div>
          <p className={styles.text1} onClick={() => handleClearFilter()}>
            נקה הכל
          </p>
        </div>
        <div className={styles.search}>
          <input
            type="text"
            value={currentScreen === 'Home' ? homeSearchKey : statsSearchKey}
            placeholder="חיפוש"
            className={styles.searchbar}
            onChange={handleChange}
            // onClick={onSearchClick}
            onFocus={onSearchClick}
            ref={inputRef}
            onBlur={() => {
              setTimeout(() => setClick(false), 100);
            }}
          />

          {click && (
            <Menu
              arr={currentScreen === 'Home' ? leadsToShow : myLeadsToShow}
              setValue={handleMenuitems}
            />
          )}
          <div
            className={styles.searchIcon}
            onClick={() => {
              // inputRef set focused
              if (inputRef.current) inputRef.current.focus();
            }}
          >
            <Search />
          </div>
        </div>
      </div>

      <div className={styles.section2} dir="ltr">
        <p className={styles.title}>רווח</p>
        <div className={styles.messege}>
          <Message />
          <div className={styles.value}>
            <div className={styles.minValue}>
              <p className={styles.text}>₪</p>
              <p className={styles.number}>
                {currentScreen === 'Home' ? homeRangeValue.min : statsRangeValue.min}
              </p>
            </div>
            <div className={styles.dash}>-</div>
            <div className={styles.maxValue}>
              <p className={styles.text}> ₪</p>
              <p className={styles.number}>
                {currentScreen === 'Home' ? homeRangeValue.max : statsRangeValue.max}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.rangebar}>
          <p className={styles.number1}>0</p>
          <MultiRangeSlider
            min={0}
            max={2000}
            minValue={currentScreen === 'Home' ? homeRangeValue.min : statsRangeValue.min}
            maxValue={currentScreen === 'Home' ? homeRangeValue.max : statsRangeValue.max}
            className="FilterSliderange"
            minCaption=""
            maxCaption=""
            label={'false'}
            ruler={'false'}
            barInnerColor="#3FE2D2"
            thumbLeftColor="#ffffff"
            onChange={(e) => {
              if (currentScreen === 'Home') {
                setHomeRangeValue({ min: e.minValue, max: e.maxValue });
              } else if (currentScreen === 'Stats') {
                setStatsRangeValue({ min: e.minValue, max: e.maxValue });
              }
            }}
            // onInput={(e) => {
            //   console.log(e, 'e');
            //   if (currentScreen === 'Home') {
            //     setHomeRangeValue(e);
            //   } else if (currentScreen === 'Stats') {
            //     setStatsRangeValue(e);
            //   }

            //   setMinValue(e.minValue);
            //   setMaxValue(e.maxValue);
            // }}
          />
          <p className={styles.number2}>2000</p>
        </div>
      </div>

      {/* <div className={styles.section3}>
        <p className={styles.title}>תחום</p>
        <div className={styles.btn_box1}>
          <NormalButton
            title={'הכל'}
            isActive={btnTitle[3] === selectedDomain}
            setClick={handleSetDomain(btnTitle[3])}
          />
          <NormalButton
            title={'תקשורת'}
            isActive={btnTitle[2] === selectedDomain}
            setClick={handleSetDomain(btnTitle[2], 2)}
          />
          <NormalButton
            title={'ביטוח'}
            isActive={btnTitle[1] === selectedDomain}
            setClick={handleSetDomain(btnTitle[1])}
          />
          <NormalButton
            title={'קורסים'}
            isActive={btnTitle[0] === selectedDomain}
            setClick={handleSetDomain(btnTitle[0])}
          />
        </div>
        <div className={styles.btn_box2}>
          <NormalButton
            title={'לימודים'}
            isActive={btnTitle[4] === selectedDomain}
            setClick={handleSetDomain(btnTitle[4])}
          />
          <NormalButton
            title={'פיננסי'}
            isActive={btnTitle[5] === selectedDomain}
            setClick={handleSetDomain(btnTitle[5])}
          />
        </div>
        <div className={styles.colorBorder}></div>
      </div> */}

      <div className={styles.section4}>
        <div className={styles.icons}>
          <p className={styles.text}>מיון</p>
          <div className={styles.upArrow}>
            <UpArrow />
          </div>
        </div>
        <div className={styles.box}>
          {map(sortArray, (item, index) => {
            return (
              <div
                className={cx(styles.box1, {
                  [styles.active]:
                    item === (currentScreen === 'Home' ? homeSortOption : statsSortOption),
                })}
                onClick={() => handleClick(item)}
                key={`${item}${index}`}
              >
                <span className={styles.text}>{item}</span>
              </div>
            );
          })}

          {/* <div
            className={cx(styles.box1, {
              [styles.active]:
                sortArray[3] === (currentScreen === 'Home' ? homeSortOption : statsSortOption),
            })}
            onClick={() => handleClick(sortArray[3])}
          >
            <span className={styles.text}>{sortArray[3]}</span>
          </div>
          <div
            className={cx(styles.box1, {
              [styles.active]:
                sortArray[2] === (currentScreen === 'Home' ? homeSortOption : statsSortOption),
            })}
            onClick={() => handleClick(sortArray[2])}
          >
            <span className={styles.text}>{sortArray[2]}</span>
          </div>
          <div
            className={cx(styles.box1, {
              [styles.active]:
                sortArray[1] === (currentScreen === 'Home' ? homeSortOption : statsSortOption),
            })}
            onClick={() => handleClick(sortArray[1])}
          >
            <span className={styles.text}>{sortArray[1]}</span>
          </div>
          <div
            className={cx(styles.box4, {
              [styles.active]:
                sortArray[0] === (currentScreen === 'Home' ? homeSortOption : statsSortOption),
            })}
            onClick={() => handleClick(sortArray[0])}
          >
            <span className={styles.text}>{sortArray[0]}</span>
          </div> */}
        </div>
      </div>
      <div className={styles.btn} onClick={handleOkBtn}>
        <Button isLoading={isLoading}>אישור</Button>
      </div>
    </div>
  );
};

export default FilterPopup;
