import * as React from 'react';
const Failure = (props) => (
  <svg
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={5} cy={5} r={5} fill="#FF3B79" />
    <path d="M3 3L7 7M3 7L7 3" stroke="white" strokeLinecap="round" />
  </svg>
);
export default Failure;
