import React from 'react';

import cx from 'classnames';
import { get } from 'lodash';

import Button from '../button/Button';
import CrossIcon from '../icons/CrossIcon';
import Gift from '../icons/Gift';
import celebration from '../../assets/celebration.png';
import useUserStore from '../../zustand/userStore';

import styles from './withdrawPopup.module.scss';

const WithdrawPopup = ({ withdrawDiff, onClick }) => {
  const { user: userData } = useUserStore((state) => state);

  return (
    <div className={cx(styles.BirthdayPopup)}>
      <div className={styles.avtar}>
        <img src={celebration} alt="" />
      </div>

      <div className={styles.main}>
        <div className={styles.navbar}>
          <p className={styles.title}>{` בראבו ${get(userData, 'userData.firstname', '')} !  `} </p>
          <div className={styles.crossIcon} onClick={onClick}>
            <CrossIcon />
          </div>
        </div>
        <p className={styles.information}>העברנו לך</p>
        <div className={styles.numberContainer}>
          <p className={styles.count}>{withdrawDiff}</p>
          <p className={styles.sign}>₪</p>
        </div>
        <p className={styles.wishes}>
          {`רוצים לצבור עוד נקודות?שתפו את ההודעה וקבלו\n עוד 30 נקודות!`}
        </p>
        <div className={styles.boxSvg}>
          <Gift />
        </div>

        <div className={styles.btn}>
          <Button onClick={onClick}>שיתוף</Button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawPopup;
