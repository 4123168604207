import { every, isArray, isEmpty, size } from 'lodash';
import PrevArrow from '../components/icons/PrevArrow';
import NextArrow from '../components/icons/NextArrow';

export const validateEmail = (val) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(val);
};

export const getFormattedTime = (timestamp) => {
  let seconds = timestamp / 1000;
  const minutes = parseInt(seconds / 60);
  seconds = seconds - minutes * 60;
  return `${convertToTwoDigit(minutes)}:${convertToTwoDigit(parseInt(seconds))}`;
};

// this function will convert number to two digit
export const convertToTwoDigit = (value) => {
  if (parseInt(value) < 10) {
    return `0${value}`;
  }

  return value;
};

// import { logoutUser } from '../actions/user.actions';
// import useUserStore from '../zustand/userStore';

// const { flushUserData } = useUserStore((state) => state);

export const validatePassword = (val) => {
  const checkNumber = /^(?=.*\d).*$/;
  const checkLetter = /^(?=.*[a-zA-Z]).*$/;
  const checkSpecialCharacter = /^(?=.*[!@#$%^&*]).*$/;
  const isLengthOk = size(val) >= 8;
  return [
    checkLetter.test(val),
    checkNumber.test(val),
    checkSpecialCharacter.test(val),
    isLengthOk,
  ];
};

export const validateEmptyField = (val) => !isEmpty(val);

export const validateBirthDate = (date) => date <= new Date();

export const isEmptyError = (errors) => every(errors, (i) => isEmpty(i));

export const isEmptyObject = (value) =>
  every(value, (val) => {
    //check if value is array
    if (isArray(val)) return isEmptyError(val);
    if (typeof val === 'object') return isEmptyObject(val);
    return isEmpty(val);
  });

export const validateId = (val) => {
  if (isEmpty(val)) return false;
  const regex = /^[0-9]{9}$/;
  return regex.test(val);
};

// export const handleLogout = async () => {
//   await logoutUser();
//   flushUserData();
// };

/**
 *
 * @param {*} dateObj
 * @returns date in dd/mm/yyyy
 */
//seperator is how you want date month and year seprated from eachother
export const getFormattedDate = (dateObj, seperator = '/') => {
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  return `${convertToTwoDigit(date)}${seperator}${convertToTwoDigit(month + 1)}${seperator}${year}`;
};

export const LOCAL_STORAGE = {
  EMAIL_TOKEN: 'email_token',
  TOKEN: 'token',
  NO_OF_LEADS: 'no_of_leads',
  WITHDRAW: 'withdraw',
  SCORE: 'score',
  IS_BIRHTDAY_SHOWN: 'is_birhtday',
  DEGREE: 'degree',
};

export const getParsedTokenData = (string) => {
  try {
    if (string) {
      return JSON.parse(string);
    }
    throw Error;
  } catch (err) {
    return '';
  }
};

export const getTokenForEmail = async () => {
  const token = getParsedTokenData(localStorage.getItem(LOCAL_STORAGE.EMAIL_TOKEN));
  return token;
};

export const getToken = async () => {
  const token = getParsedTokenData(localStorage.getItem(LOCAL_STORAGE.TOKEN)).access_token;
  return token;
};

export const getRefreshToken = async () => {
  const token = getParsedTokenData(localStorage.getItem(LOCAL_STORAGE.TOKEN)).refresh_token;

  return token;
};

export const numberWithCommas = (x) => {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// datestring is the date string from the api in below format
// dd/mm/yyyy
export const getDateObjFromString = (dateString) => {
  if (isEmpty(dateString)) return new Date();
  const dateArr = dateString.split('/');
  const date = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
  return date;
};

// get greeting message based on time in hebrew
export const getGreetingMessage = () => {
  const date = new Date();
  const hours = date.getHours();
  if (hours < 12) {
    return 'בוקר טוב';
  }
  if (hours < 18) {
    return 'צהריים טובים';
  }
  return 'ערב טוב';
};

export const containsOnlyNumbers = (str) => /^\d+$/.test(str);

export const getFormattedDob = (dob) => {
  const d = dob;

  var d2 = d.substring(9, 11) + '/' + d.substring(6, 8) + '/' + d.substring(1, 5);

  return d2;
};

export const settings = (activeSlide, setActiveSlide) => {
  return {
    slidesToShow: 6,
    dots: true,
    dotsClass: 'slick-dots',
    prevArrow: <NextArrow />,
    nextArrow: <PrevArrow />,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,

    beforeChange: (current, next) => setActiveSlide(next),

    customPaging: (i) => {
      return (
        <div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            border: '1px solid #C7C7C7',
            backgroundColor: i === activeSlide ? '#858585' : '#ffffff',
          }}
        ></div>
      );
    },

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          infinite: true,
          dots: true,
          // adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
        },
      },
    ],
  };
};
