import * as React from 'react';
const Gift = (props) => (
  <svg
    width={149}
    height={139}
    viewBox="0 0 149 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M69.0504 138.101C107.186 138.101 138.101 107.186 138.101 69.0504C138.101 30.9149 107.186 0 69.0504 0C30.9149 0 0 30.9149 0 69.0504C0 107.186 30.9149 138.101 69.0504 138.101Z"
      fill="#F0F2F4"
    />
    <path
      d="M89.3096 121.189C88.5997 123.849 85.8676 125.43 83.2072 124.72L34.489 111.722C31.8286 111.012 30.2473 108.28 30.9571 105.619L40.2942 70.6242C40.4019 70.2205 40.6656 69.8762 41.0272 69.6669C41.3888 69.4576 41.8187 69.4005 42.2224 69.5083L97.5307 84.2651C97.9344 84.3728 98.2787 84.6365 98.488 84.9981C98.6973 85.3597 98.7544 85.7896 98.6467 86.1933L89.3096 121.189Z"
      fill="#FFEF5E"
    />
    <path
      d="M64.6934 57.271L84.1452 62.461L68.5753 120.816L49.1235 115.626L64.6934 57.271Z"
      fill="#FF6196"
    />
    <path
      d="M102.417 85.7017L37.2223 68.3072C36.7849 68.1905 36.4085 67.9172 36.1759 67.5475C35.9433 67.1778 35.8735 66.7419 35.9818 66.3357L38.9851 55.0795C39.624 52.6851 42.0829 51.262 44.4772 51.9008L104.299 67.8619C106.693 68.5007 108.116 70.9596 107.477 73.354L104.474 84.6102C104.366 85.0164 104.088 85.3596 103.702 85.5643C103.316 85.769 102.854 85.8184 102.417 85.7017Z"
      fill="#FFDE03"
    />
    <path
      d="M74.5874 57.801L63.2262 54.838C60.9799 54.253 58.9576 53.0149 57.4151 51.2804C55.8725 49.5458 54.8791 47.3928 54.5603 45.0935C54.2416 42.7943 54.6119 40.4522 55.6245 38.3634C56.637 36.2747 58.2463 34.5332 60.2487 33.3592C62.2511 32.1852 64.5568 31.6314 66.874 31.7679C69.1912 31.9044 71.4159 32.7251 73.2666 34.1261C75.1173 35.5272 76.5109 37.4456 77.2712 39.6388C78.0315 41.832 78.1242 44.2014 77.5377 46.4473L74.5874 57.801Z"
      stroke="#FF6196"
      strokeWidth={4.98559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.2753 57.9845L86.6025 61.075C88.8416 61.6868 91.2119 61.6207 93.4135 60.8851C95.6151 60.1495 97.5491 58.7775 98.9708 56.9427C100.393 55.1078 101.238 52.8925 101.401 50.577C101.563 48.2615 101.036 45.9498 99.8841 43.9343C98.7326 41.9188 97.0093 40.29 94.9321 39.2541C92.8549 38.2181 90.5171 37.8215 88.2144 38.1143C85.9117 38.4072 83.7476 39.3764 81.9959 40.8994C80.2441 42.4223 78.9833 44.4306 78.3731 46.6701L75.2753 57.9845Z"
      stroke="#FF6196"
      strokeWidth={4.98559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M131.664 58.2627C131.242 58.2627 130.861 57.98 130.699 57.5468C128.518 51.696 126.91 49.9095 121.645 47.4855C121.255 47.3057 121 46.8827 121 46.4138C121 45.9444 121.255 45.5214 121.645 45.3416C126.91 42.9176 128.518 41.1311 130.699 35.2808C130.861 34.8471 131.242 34.5649 131.664 34.5649C132.086 34.5649 132.467 34.8477 132.629 35.2808C134.81 41.1316 136.418 42.9176 141.684 45.3416C142.073 45.5208 142.328 45.9444 142.328 46.4138C142.328 46.8827 142.074 47.3057 141.684 47.4855C136.418 49.9095 134.81 51.6954 132.629 57.5468C132.467 57.98 132.086 58.2627 131.664 58.2627Z"
      fill="#FFDE03"
    />
    <path
      d="M37.2944 29.1529C36.9657 29.1529 36.6696 28.9548 36.5439 28.6513C34.8473 24.556 33.5972 23.3055 29.5016 21.609C29.198 21.483 29 21.1866 29 20.8585C29 20.5303 29.1984 20.2339 29.5016 20.1079C33.5972 18.4114 34.8473 17.1613 36.5439 13.0656C36.6696 12.7625 36.9657 12.5645 37.2944 12.5645C37.6227 12.5645 37.9188 12.7625 38.0446 13.0656C39.7411 17.1613 40.9912 18.4114 45.0872 20.1079C45.3909 20.2339 45.5884 20.5303 45.5884 20.8585C45.5884 21.1866 45.3905 21.483 45.0872 21.609C40.9912 23.3055 39.7411 24.556 38.0446 28.6513C37.9188 28.9548 37.6223 29.1529 37.2944 29.1529Z"
      fill="#FFDE03"
    />
    <path
      d="M19.9496 64.4637C19.7933 64.4637 19.6524 64.3693 19.5923 64.2249C18.7844 62.2748 18.1892 61.6793 16.2389 60.8713C16.0944 60.8116 16 60.6704 16 60.514C16 60.3576 16.0942 60.2166 16.2389 60.1567C18.1892 59.3486 18.7846 58.7533 19.5923 56.8031C19.6524 56.6585 19.7933 56.5645 19.9496 56.5645C20.106 56.5645 20.2471 56.6585 20.3069 56.8031C21.1147 58.7533 21.7102 59.3486 23.6604 60.1567C23.8049 60.2166 23.8992 60.3576 23.8992 60.514C23.8992 60.6704 23.8049 60.8116 23.6604 60.8713C21.7102 61.6793 21.1147 62.2748 20.3069 64.2249C20.2471 64.3695 20.106 64.4637 19.9496 64.4637Z"
      fill="#FFDE03"
    />
  </svg>
);
export default Gift;
