import React, { useMemo } from 'react';
import styles from './PopupType3.module.scss';
import Button from '../button/Button';
import CrossIcon from '../icons/CrossIcon';

import { get } from 'lodash';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes.constant';
import useUserStore from '../../zustand/userStore';
import { BANK_LIST } from '../../constants/banklist';

const getBankLable = (number) => {
  const bank = BANK_LIST.find((item) => item.value === `${number}`);
  return bank?.label;
};

const PopupType3 = ({ handleClose, ClosedNextPage, isLoading, title, ...props }) => {
  const { data } = props;
  const {
    user: { userData },
  } = useUserStore((state) => state);

  // const [imnage, setImage] = React.useState(HotImage);

  const navigate = useNavigate();

  const accountNumber = useMemo(() => {
    const accountNumber = get(userData, 'account', '');
    const sub = accountNumber?.length - 4;
    return accountNumber?.substring(0, accountNumber?.length - sub);
  }, [userData]);

  return (
    <div className={styles.PopupType3}>
      <div className={styles.navbar}>
        {/* <div className={styles.count}>
          <span className={styles.number}>{numberWithCommas(get(data, 'valuation', ''))}</span>
          <span className={styles.text}>₪</span>
        </div> */}
        <p className={styles.title}>{title}</p>
        <div className={styles.crossIcon} onClick={handleClose}>
          <CrossIcon />
          {/* <Icons type={ICONS.CALENDA} /> */}
        </div>
      </div>
      <div className={styles.leadDescription}>
        <div className={styles.description}>
          <div className={styles.part1}>
            <p className={styles.information}>
              <span> בַּנק</span> : {getBankLable(get(userData, 'bank', ''))}
            </p>
            <p className={styles.information}>
              <span>סְנִיף</span> : {get(userData, 'branch', '')}
            </p>
            <p className={styles.information}>
              <span>מספר חשבון</span> : {`${accountNumber}`}***
            </p>
            <p className={styles.information}>
              <span>תְעוּדַת זֶהוּת</span> : {get(userData, 'bank_acc_holder_id', '')}
            </p>
            <p className={styles.information}>
              <span>שֵׁם</span> : {get(userData, 'username', '')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.btn}>
        <Button
          buttonClassName={styles.button}
          isLoading={isLoading}
          onClick={() => ClosedNextPage(data)}
        >
          משיכה
        </Button>
        <Button
          buttonClassName={styles.button}
          isLoading={isLoading}
          onClick={() => {
            navigate(ROUTES.PAYMENT_TRANSFER);
          }}
        >
          לַעֲרוֹך
        </Button>
      </div>
    </div>
  );
};

export default PopupType3;
