import * as React from 'react';

const GraySuccess = (props) => (
  <svg
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={5} cy={5} r={5} fill="#777777" />
    <path d="M2.7998 4.92105L4.25814 6.5L7.2998 3.5" stroke="white" strokeLinecap="round" />
  </svg>
);
export default GraySuccess;
