import React, { useState, useEffect, useRef } from 'react';

import { get, isEmpty, size } from 'lodash';
import cx from 'classnames';
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-web';

import { Header1 } from '../../components';
import circulerProgress from '../../assets/CircularProgressBar.json';
import Icons from '../../components/icons';
import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constant';
import { getFormattedTime } from '../../helpers/general.helpers';
import {
  passwordReset,
  passwordResetEmailVerification,
  resentOTPAction,
  verfiyEmail,
} from '../../actions/user.actions';

import styles from './otpScreen.module.scss';

const OtpScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const email = get(location, 'state.email', '');

  const [otp, setOtp] = useState('');
  const [time, setTime] = useState(270000);

  const [authToken, setToken] = useState('');
  const [isSucess, setIsSucess] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [isOptSubmitted, setIsOptSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const lottieRef = useRef(null);
  const playerRef = useRef(null);

  const ref = useRef(null);

  const isForgotPasswordScreen = get(location, 'state.isForgetPassword', false);

  const handleRegisterFlow = async (otp) => {
    const dataToSend = {
      code: otp,
      token: authToken,
    };
    setIsLoading(true);
    const isSucess = await verfiyEmail(dataToSend);
    setIsOptSubmitted(true);
    setIsLoading(false);
    if (!isSucess) {
      setIsSucess(false);
      setIsError(true);
      return;
    }

    setIsSucess(true);
    setTimeout(() => {
      navigate(ROUTES.SIGN_UP_SUCCESS, { state: { ...location.state } });
    }, 300);
  };

  const handleForgotPasswordFlow = async (otp) => {
    const dataToSend = {
      code: otp,
      token: authToken,
    };
    setIsLoading(true);
    const token = await passwordResetEmailVerification(dataToSend);
    setIsOptSubmitted(true);
    setIsLoading(false);
    if (isEmpty(token)) {
      setIsSucess(false);
      setIsError(true);
      return;
    }
    setIsSucess(true);
    setTimeout(() => {
      navigate(ROUTES.RESET_PASSWORD, { state: { ...location.state, token } });
    }, 300);
  };

  const submitOTP = async (otp) => {
    if (isForgotPasswordScreen) {
      handleForgotPasswordFlow(otp);
    } else {
      handleRegisterFlow(otp);
    }
  };

  const handleResendOTP = async () => {
    ref.current += 1;
    setTime(270000);
    if (isForgotPasswordScreen) {
      const token = await passwordReset(email);
      setToken(token);
    } else {
      if (ref.current > 3) return;
      const otpToken = get(location, 'state.otpToken', '');
      await resentOTPAction(otpToken);
    }
  };

  useEffect(() => {
    if (location.state.isForgetPassword) {
      setToken(get(location, 'state.token', ''));
    } else {
      setToken(get(location, 'state.emailToken', ''));
    }
  }, [location]);

  useEffect(() => {
    const id = setInterval(() => {
      setTime((v) => {
        // const total = lottieRef.current.getDuration(true);
        // console.log(total);
        const current = (Math.max(270000 - v, 0) / 270000) * 30;
        // console.log(current);
        Lottie.goToAndStop(current, true);
        return Math.max(v - 1000, 0);
      });
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    lottieRef.current = Lottie.loadAnimation({
      container: playerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: circulerProgress,
    });
    // progressBarRef.current.value = 100;

    return () => {
      if (lottieRef.current) lottieRef.current.destroy();
    };
  }, []);

  // useEffect(() => {
  //   if (isEmpty(location.state)) {
  //     navigate(ROUTES.SIGN_UP);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.state]);

  return (
    <div className={styles.OtpScreen}>
      <Header1
        title={'אימות משתמש'}
        description={`אנא הזן את הקוד שנשלח למייל \n ${get(location, 'state.email', '')}`}
      />
      <div className={styles.otpContainer} dir="ltr">
        <OTPInput
          inputStyle={cx(
            styles.otp_code,
            { [styles.otp_success]: isSucess },
            { [styles.otp_failure]: iserror },
          )}
          containerStyle={styles.otpContainer}
          value={otp}
          onChange={(value) => {
            setIsSucess(false);
            setIsError(false);
            setOtp(value);
            if (size(value) === 5) {
              submitOTP(value);
            }
          }}
          numInputs={5}
          inputType="tel"
          renderInput={(props) => <input {...props} />}
        />
      </div>

      {time != '000000' ? (
        isOptSubmitted ? (
          isSucess ? (
            <Icons type={ICONS.SUCCESS} />
          ) : (
            <Icons type={ICONS.Failure} />
          )
        ) : (
          <div className={styles.base_timer}>
            <div ref={playerRef}></div>
            <span id="base-timer-label" className={styles.base_timer__label}>
              {getFormattedTime(time)}
            </span>
          </div>
        )
      ) : (
        <div className={styles.base_timer}>
          <svg
            className={styles.base_timer__svg}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className={styles.base_timer__circle}>
              <circle className={styles.base_timer__path_elapsed} cx="50" cy="50" r="45"></circle>
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                className={'styles.base_timer__path_remaining'}
                d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
              "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" className={styles.base_timer__endlabel}>
            00:00
          </span>
        </div>
      )}

      <p className={styles.existingAcc}>
        {'לא קיבלתי '}
        <span className={styles.login} onClick={handleResendOTP}>
          {' '}
          תשלחו לי קוד חדש
        </span>
      </p>
    </div>
  );
};

export default OtpScreen;
