import React, { useState, useEffect } from 'react';

import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from 'react-share';

import styles from './PopupType2.module.scss';
//
import cx from 'classnames';
import Button from '../button/Button';
import CrossIcon from '../icons/CrossIcon';
import Whatsapp from '../../assets/whatsapp.png';
import Facebook from '../../assets/facebook.png';
import Messanger from '../../assets/messenger.png';
import Email from '../../assets/email.png';
import Copied from '../icons/Copied';
import Success from '../icons/Success';
import leadsStore from '../../zustand/leadsStore';
import { get } from 'lodash';
import HotImage from '../../assets/App_Icon_1024.png';
import { numberWithCommas } from '../../helpers/general.helpers';
import { BASE_URL } from '../../axios';

const PopupType2 = ({ handleClosePopup, SecondPopupCloseMoveNext, ...props }) => {
  const { visible, setVisible, data, handleNextClick } = props;
  const { leadsLink } = leadsStore((state) => state);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const [image, setImage] = React.useState(HotImage);

  useEffect(() => {
    if (data?.photo) {
      setImage(`${BASE_URL}v1/photos/leads/${data.photo}`);
    }
    setIsLinkCopied(false);
  }, [data, leadsLink]);

  // const copyLinkToClipboard = () => {
  //   Clipboard.setString(leadsLink);
  //   setIsLinkCopied(true);
  // };
  const [link, setLink] = useState(true);
  const [click, setClick] = useState(false);

  const handleChange = () => {
    try {
      navigator.clipboard.writeText(leadsLink);
      setTimeout(() => setClick(true), 700);
      setLink(false);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div className={cx(styles.PopupType2, { [styles.Linkcopy]: true })}>
      <div className={styles.navbar}>
        <div className={styles.count}>
          <span className={styles.number}>{numberWithCommas(get(data, 'valuation', ''))}</span>
          <span className={styles.text}>₪</span>
        </div>
        <p className={styles.title}>שיתוף ליד</p>
        <div className={styles.crossIcon} onClick={handleClosePopup}>
          <CrossIcon />
        </div>
      </div>
      <div className={styles.leadDescription}>
        <div className={styles.imageContainer}>
          <img
            crossOrigin="anonymous"
            src={image}
            alt="lead"
            className={styles.image}
            onError={() => {
              setImage(HotImage);
            }}
          />
        </div>
        <div className={styles.description}>
          <div className={styles.part1}>
            <p className={styles.text2}>{get(data, 'company_name', '')} </p>
            <div className={styles.colorLine}></div>
            <p className={styles.text1}>{get(data, 'category', '')}</p>
          </div>
          <p className={styles.text3}>{get(data, 'subcategory', '')}</p>
        </div>
        <p className={styles.information}>{get(data, 'description', '')}</p>
        <button className={styles.btn2}>
          <p className={styles.btn_Title}>הקישור הועתק</p>
        </button>
        <div className={styles.Link}>
          <div className={styles.box}>
            <div className={styles.copyIcon} onClick={() => handleChange()}>
              <Copied />
            </div>
            <p className={styles.emailAdd}>{leadsLink}</p>
          </div>
          {click && (
            <div className={styles.err}>
              <div className={styles.icon}>
                <Success />
              </div>
              <p className={styles.error}>הקישור הועתק</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.colorLines}>
        <div className={styles.line1}></div>
        <p className={styles.text}>או</p>
        <div className={styles.line1}></div>
      </div>
      <div className={styles.section2}>
        <p className={styles.text}>שיתוף ברשתות חברתיות</p>
        <div className={styles.socialIcons}>
          <WhatsappShareButton url={leadsLink}>
            <div className={styles.icon1}>
              <img src={Whatsapp} alt="" />
            </div>
          </WhatsappShareButton>
          <FacebookShareButton url={leadsLink}>
            <div className={styles.icon1}>
              <img src={Facebook} alt="" />
            </div>
          </FacebookShareButton>
          {/* <FacebookMessengerShareButton url={leadsLink}>
            <div className={styles.icon1}>
              <img src={Messanger} alt="" />
            </div>
          </FacebookMessengerShareButton>
          <EmailShareButton url={leadsLink}>
            <div className={styles.icon1}>
              <img src={Email} alt="" />
            </div>
          </EmailShareButton> */}
        </div>
        <p className={styles.information}>
          מזכירים, את הקישור יש לשתף רק למי שרלוונטי לו, ואת הכסף תקבלו רק לאחר שתבוצע הרשמה דרך
          הקישור האישי שלך
        </p>
      </div>
      <div className={styles.btn} onClick={SecondPopupCloseMoveNext}>
        <Button>שלחתי!</Button>
      </div>
    </div>
  );
};

export default PopupType2;
