import { useState, useEffect } from 'react';

import { FacebookShareButton, WhatsappShareButton } from 'react-share';

import styles from './ProfileLinkPopup.module.scss';
//
import cx from 'classnames';
import Button from '../button/Button';
import CrossIcon from '../icons/CrossIcon';
import Whatsapp from '../../assets/whatsapp.png';
import Facebook from '../../assets/facebook.png';
import Copied from '../icons/Copied';
import Success from '../icons/Success';

const ProfileLinkPopup = ({ handleClosePopup, handleSendClick, ...props }) => {
  const { inviteCode } = props;

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useEffect(() => {
    setIsLinkCopied(false);
  }, []);
  // const copyLink = `https://app.publico-app.com/sign-up?invite=${inviteCode}`;
  const copyLink = inviteCode;
  const [link, setLink] = useState(true);
  const [click, setClick] = useState(false);

  const handleChange = () => {
    try {
      navigator.clipboard.writeText(copyLink);
      setTimeout(() => setClick(true), 700);
      setLink(false);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div className={cx(styles.ProfileLinkPopup, { [styles.Linkcopy]: true })}>
      <div className={styles.navbar}>
        <p className={styles.title}>שיתוף ליד</p>
        <div className={styles.crossIcon} onClick={handleClosePopup}>
          <CrossIcon />
        </div>
      </div>
      <div className={styles.leadDescription}>
        <div className={styles.Link}>
          <div className={styles.box}>
            <div className={styles.copyIcon} onClick={() => handleChange()}>
              <Copied />
            </div>
            <p className={styles.emailAdd}>{copyLink}</p>
          </div>
          {click && (
            <div className={styles.err}>
              <div className={styles.icon}>
                <Success />
              </div>
              <p className={styles.error}>הקישור הועתק</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.colorLines}>
        <div className={styles.line1}></div>
        <p className={styles.text}>או</p>
        <div className={styles.line1}></div>
      </div>
      <div className={styles.section2}>
        <p className={styles.text}>שיתוף ברשתות חברתיות</p>
        <div className={styles.socialIcons}>
          <WhatsappShareButton url={copyLink}>
            <div className={styles.icon1}>
              <img src={Whatsapp} alt="" />
            </div>
          </WhatsappShareButton>
          <FacebookShareButton url={copyLink}>
            <div className={styles.icon1}>
              <img src={Facebook} alt="" />
            </div>
          </FacebookShareButton>
          {/* <FacebookMessengerShareButton url={leadsLink}>
            <div className={styles.icon1}>
              <img src={Messanger} alt="" />
            </div>
          </FacebookMessengerShareButton>
          <EmailShareButton url={leadsLink}>
            <div className={styles.icon1}>
              <img src={Email} alt="" />
            </div>
          </EmailShareButton> */}
        </div>
        {/* <p className={styles.information}>
          מזכירים, את הקישור יש לשתף רק למי שרלוונטי לו, ואת הכסף תקבלו רק לאחר שתבוצע הרשמה דרך
          הקישור האישי שלך
        </p> */}
      </div>
      <div className={styles.btn} onClick={handleClosePopup}>
        <Button>שלחתי!</Button>
      </div>
    </div>
  );
};

export default ProfileLinkPopup;
