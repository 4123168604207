import * as React from 'react';
const PrevArrow = (props) => (
  <svg
    width={9}
    height={12}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ marginLeft: '-2%' }}
  >
    <path
      d="M6.98001 12L8.39001 10.59L3.81001 6L8.39001 1.41L6.98001 1.23266e-07L0.980013 6L6.98001 12Z"
      fill="#9EA6B4"
    />
  </svg>
);
export default PrevArrow;
