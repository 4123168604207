import React, { useState } from 'react';

import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import { Button as MuiButton } from '@mui/material';

import { Button, Input } from '../../components';
import { ROUTES } from '../../constants/routes.constant';
import { validateFields } from '../../helpers/profile.helpers';
import useUserStore from '../../zustand/userStore';
import { deleteUser, logoutUser, updateUser as updateUserAction } from '../../actions/user.actions';
import publico from '../../assets/publico.png';

import styles from './updatePersonalInfo.module.scss';

const UpdatePersonalInfo = () => {
  const navigate = useNavigate();
  const { user, updateUser, flushUserData } = useUserStore((state) => state);

  const userData = get(user, 'userData', {});

  const [firstName, setFirstName] = useState(userData.firstname);
  const [lastName, setLastName] = useState(userData.lastname);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    navigate(ROUTES.PROFILE);
  };

  const handleSubmit = async () => {
    //* update the data here for backend
    const { canMove, errors } = validateFields({
      firstName,
      lastName,
    });
    if (!canMove) {
      setErrors(errors);
      return;
    }
    setIsLoading(true);
    const isSucess = await updateUserAction({
      firstname: firstName,
      lastname: lastName,
    });
    setIsLoading(false);
    if (isSucess) {
      updateUser({
        firstname: firstName,
        lastname: lastName,
      });
      navigate(ROUTES.PROFILE);
    } else {
      setApiError('משהו השתבש');
    }
  };

  const handleLogout = async () => {
    await logoutUser();
    flushUserData();
  };

  const handleDelete = async () => {
    await logoutUser();
    flushUserData();
    deleteUser();
  };

  return (
    <div className={styles.UpdatePersonalInfo}>
      <div className={styles.img}>
        <img src={publico} alt="" />
      </div>
      <div className={styles.heading}>
        {/* <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTES.PROFILE);
          }}
        >
          <Icons type={ICONS.RIGHTARROW} />
        </div> */}
        <p className={styles.header}>פרטים אישיים</p>
      </div>

      <Input
        placeholder={'שם פרטי'}
        setValue={(val) => {
          // value inlcudes numbers return

          if (val.match(/\d+/g) != null) return;

          setFirstName(val);
          setErrors((prev) => ({
            ...prev,
            firstName: '',
          }));
        }}
        value={firstName}
        error={errors.firstName}
      />
      <Input
        placeholder={'שם משפחה'}
        setValue={(val) => {
          if (val.match(/\d+/g) != null) return;

          setLastName(val);
          setErrors((prev) => ({
            ...prev,
            lastName: '',
          }));
        }}
        value={lastName}
        error={errors.lastName}
      />
      <Input placeholder={'ת.ז'} value={userData.identity_id} disable />
      <Input placeholder={'מס׳ נייד'} value={userData.phone} disable />
      <Input placeholder={'תאריך לידה'} value={userData.birthday} disable />
      <Input placeholder={'אימייל'} value={userData.email} disable />
      <Input placeholder={'סיסמה'} value={'******'} disable />
      <div className={styles.logoutDeleteContainer}>
        <p className={styles.logout} onClick={handleLogout}>
          התנתקות
        </p>
        <p className={styles.delete} onClick={handleOpen}>
          מחק משתמש
        </p>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-describedby="parent-modal-description"
          sx={{
            display: 'flex', // Flex container for centering
            alignItems: 'flex-start', // Vertical alignment'
            justifyContent: 'center', // Horizontal alignment
            marginTop: '20px',
          }}
        >
          <Box
            sx={{
              width: 400,
              backgroundColor: 'white', // Background color of the modal
              padding: 2, // Padding inside the modal
              borderRadius: '5px', // Rounded corners
              textAlign: 'center',
            }}
          >
            <p id="parent-modal-description" style={{ marginBottom: '20px' }}>
              בקשה למחיקת משתמש מתבצעת עד 7 ימי עסקים. האם אתה בטוח כי ברצונך למחוק את המשתמש?
            </p>
            <div style={{ display: 'inline-block', flexDirection: 'row' }}>
              <MuiButton
                onClick={handleDelete}
                variant="contained"
                color="error"
                size="small"
                sx={{ mr: 1 }}
              >
                מחק
              </MuiButton>
              <MuiButton onClick={handleClose} variant="outlined" color="primary" size="small">
                בטל
              </MuiButton>
            </div>
          </Box>
        </Modal>
      </div>
      <p className={styles.apiError}>{apiError}</p>

      <Button onClick={handleSubmit} isLoading={isLoading}>
        שמירה
      </Button>

      <Button onClick={handleCancel} buttonClassName={styles.btn2}>
        לבטל ולחזור
      </Button>
    </div>
  );
};

export default UpdatePersonalInfo;
