import React from 'react';

import publico from '../../assets/publico.png';

import styles from './header1.module.scss';

const Header1 = ({ title, description }) => {
  return (
    <div>
      <div className={styles.img}>
        <img src={publico} alt="" />
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default Header1;
