import * as React from 'react';
const SVGComponent = (props) => (
  <svg
    width={150}
    height={275}
    viewBox="0 0 138 303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.0133 296.342V241.011L52.6218 244.899V282.372L41.5328 292.802C39.914 294.325 40.9915 297.043 43.2138 297.043H63.3123C63.6995 297.043 64.0133 296.729 64.0133 296.342Z"
      fill="#222222"
    />
    <path
      d="M74.5291 296.342V241.011L85.9207 244.899V282.372L97.0097 292.802C98.6284 294.325 97.5509 297.043 95.3287 297.043H75.2302C74.843 297.043 74.5291 296.729 74.5291 296.342Z"
      fill="#222222"
    />
    <path
      d="M15.7149 115.999C15.7149 102.882 26.3479 92.2493 39.4644 92.2493H96.4631C109.58 92.2493 120.213 102.882 120.213 115.999V215.408C120.213 241.828 98.7949 263.245 72.375 263.245H63.5525C37.1326 263.245 15.7149 241.828 15.7149 215.408V115.999Z"
      fill="#A573FE"
    />
    <g clipPath="url(#clip0_2636_102831)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11192 262.282C9.04193 262.433 10.6286 260.861 10.6559 258.77C10.7729 249.792 14.9479 234.218 22.6153 217.405C23.4514 215.571 22.7189 213.261 20.9791 212.244C19.2394 211.227 17.1513 211.889 16.3152 213.723C8.50044 230.859 3.80526 247.592 3.66669 258.22C3.63943 260.311 5.18191 262.13 7.11192 262.282Z"
        fill="#FF3B79"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.762 262.282C127.832 262.433 126.245 260.861 126.218 258.77C126.101 249.792 121.926 234.218 114.258 217.405C113.422 215.571 114.155 213.261 115.894 212.244C117.634 211.227 119.722 211.889 120.558 213.723C128.373 230.859 133.068 247.592 133.207 258.22C133.234 260.311 131.692 262.13 129.762 262.282Z"
        fill="#FF3B79"
      />
      <path
        d="M15.7148 212.716H120.212V257.826C120.212 266.334 113.315 273.231 104.807 273.231H31.1201C22.612 273.231 15.7148 266.334 15.7148 257.826V212.716Z"
        fill="#FF3B79"
      />
    </g>
    <path
      d="M49.4373 170.557C53.1125 174.463 60.0869 177.1 68.0859 177.1C76.0848 177.1 83.0592 174.463 86.7344 170.557"
      stroke="black"
      strokeWidth={5.60814}
      strokeLinecap="round"
    />
    <ellipse cx={49.164} cy={144.843} rx={15.4053} ry={15.5375} fill="white" />
    <ellipse cx={49.3766} cy={145.226} rx={6.08104} ry={6.13324} fill="black" />
    <ellipse cx={87.2745} cy={144.841} rx={15.4053} ry={15.5375} fill="white" />
    <ellipse cx={87.4871} cy={145.226} rx={6.08104} ry={6.13324} fill="black" />
    <path
      d="M72.3638 219.579H65.6438C65.6438 219.579 56.7905 240.822 57.0038 246.906C57.2171 253.952 69.0571 266.868 69.0571 266.868C69.0571 266.868 80.8971 253.952 81.1105 246.906C81.2171 240.928 72.3638 219.579 72.3638 219.579Z"
      fill="#2ACAE6"
    />
    <path
      d="M62.5504 235.164C62.5504 235.164 64.577 233.67 64.3637 232.389C64.257 231.962 63.937 231.642 63.4037 231.642C62.8704 231.642 62.4437 232.069 62.4437 232.602C62.4437 232.069 62.017 231.642 61.4837 231.642C61.2704 231.642 61.057 231.749 60.8437 231.855C60.737 232.069 60.6304 232.389 60.5237 232.602C60.737 233.883 62.5504 235.164 62.5504 235.164Z"
      fill="black"
    />
    <path
      d="M67.8837 225.343C67.7771 224.916 67.4571 224.596 66.9237 224.596C66.3904 224.596 65.9637 225.023 65.9637 225.557C65.9637 225.023 65.5371 224.596 65.0037 224.596C64.5771 224.596 64.1504 224.916 64.0437 225.343C63.8304 226.624 65.8571 228.118 65.8571 228.118C65.8571 228.118 68.0971 226.731 67.8837 225.343Z"
      fill="black"
    />
    <path
      d="M57.1105 245.839C57.1105 246.266 57.0038 246.586 57.1105 247.013C57.1105 247.227 57.1105 247.44 57.1105 247.654C57.3238 247.227 57.5371 246.907 57.4305 246.48C57.3238 246.159 57.2171 245.946 57.1105 245.839Z"
      fill="black"
    />
    <path
      d="M66.0703 242.103C66.0703 242.103 68.0969 240.608 67.8836 239.327C67.7769 238.9 67.4569 238.58 66.9236 238.58C66.3903 238.58 65.9636 239.007 65.9636 239.541C65.9636 239.007 65.5369 238.58 65.0036 238.58C64.5769 238.58 64.1503 238.9 64.0436 239.327C63.9369 240.715 66.0703 242.103 66.0703 242.103Z"
      fill="black"
    />
    <path
      d="M59.0302 242.103C59.0302 242.103 61.0569 240.608 60.8436 239.327C60.7369 238.9 60.4169 238.58 59.8836 238.58C59.3502 238.58 58.9236 239.007 58.9236 239.541C58.9236 239.221 58.8169 239.007 58.6036 238.794C58.3902 239.648 58.1769 240.395 57.9636 241.142C58.4969 241.783 59.0302 242.103 59.0302 242.103Z"
      fill="black"
    />
    <path
      d="M69.4839 232.495C69.4839 231.962 69.0572 231.535 68.5239 231.535C68.0972 231.535 67.6705 231.855 67.5639 232.282C67.3505 233.243 68.5239 234.31 69.0572 234.737C69.2706 234.844 69.3772 234.95 69.3772 234.95C69.3772 234.95 71.4039 233.456 71.1905 232.175C71.0839 231.748 70.7639 231.428 70.2305 231.428C69.9105 231.641 69.4839 232.068 69.4839 232.495Z"
      fill="black"
    />
    <path
      d="M73.9633 238.58C73.43 238.58 73.0033 239.007 73.0033 239.541C73.0033 239.007 72.5766 238.58 72.0433 238.58C71.6166 238.58 71.19 238.9 71.0833 239.327C70.87 240.608 72.8966 242.103 72.8966 242.103C72.8966 242.103 74.9233 240.608 74.71 239.327C74.8166 239.007 74.39 238.58 73.9633 238.58Z"
      fill="black"
    />
    <path
      d="M59.9901 252.671C59.5635 252.671 59.2435 252.991 59.1368 253.311C59.4568 253.952 59.7768 254.592 60.2035 255.233C60.6301 254.699 61.0568 254.059 60.9501 253.525C60.7368 252.991 60.4168 252.671 59.9901 252.671Z"
      fill="black"
    />
    <path
      d="M76.5236 232.495C76.5236 231.961 76.0969 231.534 75.5636 231.534C75.1369 231.534 74.7102 231.855 74.6036 232.282C74.3902 233.563 76.4169 235.057 76.4169 235.057C76.4169 235.057 77.1636 234.523 77.6969 233.883C77.4836 233.136 77.1636 232.388 76.9502 231.641C76.7369 231.855 76.5236 232.175 76.5236 232.495Z"
      fill="black"
    />
    <path
      d="M73.0033 225.557C73.0033 225.023 72.5766 224.596 72.0433 224.596C71.6166 224.596 71.19 224.916 71.0833 225.343C70.87 226.624 72.8966 228.118 72.8966 228.118C72.8966 228.118 74.4966 227.051 74.71 225.877C74.6033 225.45 74.39 225.13 74.2833 224.703C74.1766 224.596 73.9633 224.596 73.8566 224.596C73.43 224.596 73.0033 225.023 73.0033 225.557Z"
      fill="black"
    />
    <path
      d="M80.0434 242.102C80.0434 242.102 80.15 241.996 80.2567 241.996C80.0434 240.928 79.7234 239.861 79.2967 238.687C79.19 238.687 79.19 238.687 79.0834 238.687C78.6567 238.687 78.23 239.007 78.1234 239.434C78.0167 240.715 80.0434 242.102 80.0434 242.102Z"
      fill="black"
    />
    <path
      d="M64.3637 260.462C64.257 260.035 63.937 259.715 63.4037 259.715C63.297 259.715 63.1903 259.715 63.0837 259.822C63.4037 260.355 63.8303 260.782 64.1503 261.316C64.3637 260.889 64.3637 260.676 64.3637 260.462Z"
      fill="black"
    />
    <path
      d="M69.2704 220.86C69.4838 220.967 69.5904 221.074 69.5904 221.074C69.5904 221.074 70.5504 220.433 71.0838 219.579H68.0971C68.3104 220.113 68.8438 220.647 69.2704 220.86Z"
      fill="black"
    />
    <path
      d="M69.5906 263.131C69.5906 263.131 71.6172 261.637 71.4039 260.356C71.2972 259.929 70.9772 259.608 70.4439 259.608C69.9106 259.608 69.4839 260.035 69.4839 260.569C69.4839 260.035 69.0572 259.608 68.5239 259.608C68.0972 259.608 67.6705 259.929 67.5639 260.356C67.3505 261.316 68.5239 262.384 69.0572 262.811C69.3772 263.131 69.5906 263.131 69.5906 263.131Z"
      fill="black"
    />
    <path
      d="M79.0837 252.671C78.6571 252.671 78.2304 252.991 78.1237 253.418C78.1237 253.738 78.1237 254.059 78.3371 254.379C78.6571 253.845 78.9771 253.205 79.1904 252.671C79.1904 252.671 79.1904 252.671 79.0837 252.671Z"
      fill="black"
    />
    <path
      d="M66.0701 256.193C66.0701 256.193 68.0968 254.699 67.8835 253.418C67.7768 252.991 67.4568 252.671 66.9235 252.671C66.3901 252.671 65.9635 253.098 65.9635 253.632C65.9635 253.098 65.5368 252.671 65.0035 252.671C64.5768 252.671 64.1501 252.991 64.0435 253.418C63.9368 254.699 66.0701 256.193 66.0701 256.193Z"
      fill="black"
    />
    <path
      d="M69.2698 248.934C69.4832 249.041 69.5898 249.148 69.5898 249.148C69.5898 249.148 71.6165 247.653 71.4032 246.372C71.2965 245.945 70.9765 245.625 70.4432 245.625C69.9098 245.625 69.4832 246.052 69.4832 246.586C69.4832 246.052 69.0565 245.625 68.5232 245.625C68.0965 245.625 67.6698 245.945 67.5632 246.372C67.5632 247.44 68.6298 248.4 69.2698 248.934Z"
      fill="black"
    />
    <path
      d="M64.3637 246.372C64.2571 245.945 63.9371 245.625 63.4037 245.625C62.8704 245.625 62.4437 246.052 62.4437 246.586C62.4437 246.052 62.0171 245.625 61.4837 245.625C61.0571 245.625 60.6304 245.945 60.5237 246.372C60.3104 247.653 62.3371 249.148 62.3371 249.148C62.3371 249.148 64.5771 247.76 64.3637 246.372Z"
      fill="black"
    />
    <path
      d="M73.0039 256.193C73.0039 256.193 75.0305 254.699 74.8172 253.418C74.7105 252.991 74.3905 252.671 73.8572 252.671C73.3239 252.671 72.8972 253.098 72.8972 253.632C72.8972 253.098 72.4705 252.671 71.9372 252.671C71.5105 252.671 71.0839 252.991 70.9772 253.418C70.9772 254.699 73.0039 256.193 73.0039 256.193Z"
      fill="black"
    />
    <path
      d="M76.5234 249.148C76.5234 249.148 78.5501 247.653 78.3368 246.372C78.2301 245.945 77.9101 245.625 77.3768 245.625C76.8434 245.625 76.4168 246.052 76.4168 246.586C76.4168 246.052 75.9901 245.625 75.4568 245.625C75.0301 245.625 74.6034 245.945 74.4968 246.372C74.4968 247.76 76.5234 249.148 76.5234 249.148Z"
      fill="black"
    />
    <path
      d="M73.7504 218.725C73.7504 220.006 72.7904 220.967 71.5104 220.967H66.4971C65.2171 220.967 64.2571 220.006 64.2571 218.725V214.242C64.2571 212.961 65.3237 212 66.4971 212H71.4037C72.6837 212 73.6437 212.961 73.6437 214.242V218.725H73.7504Z"
      fill="#2ACAE6"
    />
    <path
      d="M113.352 40.4988C113.352 40.4988 94.7928 36.3715 68.3606 36.3715C41.9284 36.3715 23.3697 40.4988 23.3697 40.4988C23.3697 40.4988 34.055 83.8356 33.8675 94.3415H102.854C102.666 84.0232 113.352 40.4988 113.352 40.4988Z"
      fill="black"
    />
    <path
      d="M104.541 80.2692H32.1801C33.3048 86.2725 34.0547 91.5255 33.8672 94.152H102.853C102.853 91.5255 103.603 86.4601 104.541 80.2692Z"
      fill="#2ACAE6"
    />
    <path d="M114.664 91.338H22.0571V98.8422H114.664V91.338Z" fill="black" />
    <defs>
      <clipPath id="clip0_2636_102831">
        <rect width={129} height={60} fill="white" transform="translate(4.00032 213)" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
