import React, { useEffect, useState } from 'react';
import styles from './TradingPackage.module.scss';
//
import one from '../../assets/App_Icon_1024.png';
import ShareIcon from '../icons/ShareIcon';
import Background from '../icons/background';
import ImageWrap from '../imagewrap/ImageWrap';
import { numberWithCommas } from '../../helpers/general.helpers';
import { get } from 'lodash';
import { BASE_URL } from '../../axios';

const TradingPackage = ({ handleclick, data, ...props }) => {
  // const { data } = props;

  // console.log(data);
  const [imnage, setImage] = useState(one);
  //

  useEffect(() => {
    if (data?.photo) {
      setImage(`${BASE_URL}v1/photos/leads/${data.photo}`);
    }
  }, [data]);

  return (
    <div className={styles.TradingPackage} dir="rtl">
      <div className={styles.icon}>
        <img
          crossOrigin="anonymous"
          src={imnage}
          alt=""
          onError={() => {
            setImage(one);
          }}
        />
      </div>
      <div className={styles.description}>
        <span className={styles.text1}>{get(data, 'company_name', '')} </span>
        <span className={styles.text2}>{get(data, 'category', '')}</span>
      </div>
      <div className={styles.subSection1}>
        <div className={styles.number}>{numberWithCommas(get(data, 'valuation', ''))}</div>
        <p className={styles.text}>₪</p>
      </div>
      <div className={styles.colorLine}></div>
      <div className={styles.section1}>
        <p className={styles.text}> </p>
        <div className={styles.icon2}>
          <ImageWrap data={data} />
        </div>
      </div>
      <div className={styles.shareContainer} onClick={() => handleclick(data)}>
        <div className={styles.main}>
          <span className={styles.text}>שיתוף</span>
          <div className={styles.shareIcon}>
            <ShareIcon />
          </div>
        </div>

        <div className={styles.background}>
          <Background />
        </div>
      </div>
    </div>
  );
};

export default TradingPackage;
