import React, { useState } from 'react';

import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Button, Header2, Input } from '../../components';
import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constant';
import { validateEmail } from '../../helpers/general.helpers';
import useUserStore from '../../zustand/userStore';
import { fetchUser, loginUser, sabhatBlockUserAction } from '../../actions/user.actions';

import styles from './signIn.module.scss';
import moment from 'moment-timezone';

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser } = useUserStore((state) => state);

  const [formValues, setFormValues] = useState({});
  const [canSeePassword, setCanSeePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (id) => (value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });

    setErrors({
      ...errors,
      [id]: '',
    });
  };

  const handleSubmit = async () => {
    if (!validateEmail(formValues.email)) {
      setErrors({
        ...errors,
        email: 'הקלד כתובת אימייל תקינה ',
      });
      return;
    }
    setIsLoading(true);
    const isLoginSucess = await loginUser({
      email: get(formValues, 'email', ''),
      password: get(formValues, 'password', ''),
    });
    if (!isLoginSucess) {
      setGeneralError('אימייל או סיסמה שגויים');
      setIsLoading(false);
      return;
    }
    var currentTimeZone = moment.tz.guess();
    const userData = await fetchUser();
    const isShabat = await sabhatBlockUserAction({ timezone_id: currentTimeZone });
    setGeneralError('');
    setUser({ isNewUser: false, isAuthenticated: true, userData: { ...userData, isShabat } });
    setIsLoading(false);
    navigate(ROUTES.HOME_PAGE);
  };

  return (
    <div className={styles.SignIn}>
      <Header2 isUser={true} title={'התחברות'} description={'באמצעות אימייל וסיסמה'} />
      <Input
        placeholder={'אימייל'}
        setValue={handleChange('email')}
        value={formValues.email}
        error={errors.email}
      />
      <Input
        type={canSeePassword ? 'text' : 'password'}
        placeholder={'סיסמה'}
        endIcon={canSeePassword ? ICONS.CLOSEEYE : ICONS.EYE}
        handleIconClick={() => {
          setCanSeePassword((prev) => !prev);
        }}
        className={styles.input}
        setValue={handleChange('password')}
        value={formValues.password}
      />
      {generalError && <p className={styles.generalError}>{generalError}</p>}

      <p onClick={() => navigate(ROUTES.FORGET_PASSWORD)} className={styles.forget}>
        שכחתי סיסמה
      </p>
      <Button
        disable={!formValues.email || !formValues.password}
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        התחברות
      </Button>
      <p className={styles.existingAcc}>
        {'עדיין אין לי חשבון, '}
        <span className={styles.login} onClick={() => navigate(ROUTES.SIGN_UP)}>
          להירשם עכשיו
        </span>
      </p>
    </div>
  );
};

export default SignIn;
