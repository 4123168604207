import React, { useEffect } from 'react';
import useUserStore from '../../zustand/userStore';
import { map } from 'lodash';

import Level1 from '../icons/Level1.js';
import Level2 from '../icons/Level2.js';
import Level3 from '../icons/Level3.js';
import Level4 from '../icons/Level4.js';
import Level5 from '../icons/Level5.js';
import Level6 from '../icons/Level6.js';
import Level7 from '../icons/Level7.js';
import NewRank from '../icons/NewRank.js';

import styles from './newRankPopup.module.scss';

const UserAvtar = ({ height, width }) => {
  const {
    user: { userData },
  } = useUserStore();

  // const propsIds = map(userData.character_props, (i) => parseInt(i.id, 10));
  // const maxProps = Math.max(...propsIds);
  const degree = userData.degree;

  // const degree = 6;

  // const [degree, setDegree] = React.useState(0);

  // console.log(degree);

  // useEffect(() => {
  //   const d = setInterval(() => {
  //     setDegree((v) => (v + 1) % 9);
  //   }, 2000);

  //   return () => {
  //     clearInterval(d);
  //   };
  // }, []);

  const getTop = () => {
    if (degree <= 2) {
      return -140;
    }
    if (degree <= 3) {
      return -153;
    }
    if (degree <= 4) {
      return -153;
    }
    if (degree <= 5) {
      return -153;
    }
    if (degree <= 6) {
      return -174;
    }
    if (degree <= 7) {
      return -180;
    }
    return -175;
  };

  const renderUserAvatar = () => {
    if (degree <= 1) {
      return <NewRank height={height || 244} width={width || 138} />;
    }
    if (degree <= 2) {
      // console.log('object');
      return <Level1 height={height || 275} width={width || 150} />; // -153
    }
    if (degree <= 3) {
      return <Level2 height={height || 275} width={width || 150} />; // -153
    }
    if (degree <= 4) {
      return <Level3 height={height || 275} width={width || 150} />; // -153
    }
    if (degree <= 5) {
      return <Level4 height={height || 275} width={width || 180} />; // -153
    }
    if (degree <= 6) {
      return <Level5 height={height || 320} width={width || 190} />; // -180
    }
    if (degree <= 7) {
      return <Level6 height={height || 320} width={width || 190} />; // -180
    }
    return <Level7 height={height || 320} width={width || 190} />; // -175
  };

  return (
    <div
      className={styles.avtar}
      style={{
        top: `${getTop()}px`,
      }}
    >
      {renderUserAvatar()}
    </div>
  );
};

export default UserAvtar;
