import { ICONS } from '../../constants/icons.constanst';

import Success from './Success';
import Failure from './Failure';
import Calendar from './Calendar';
import Eye from './Eye';
import GraySuccess from './GraySuccess';
import CloseEye from './CloseEye';
import LeftArrow from './LeftArrow';
import ShareIcon from './ShareIcon';
import RightArrow from './RightArrow';
import ProfileUpperLayerIcon from './ProfileUpperLayerIcons';
import Lock from './Lock';
import DirectionIcon from './DirectionIcon';
import DownArrow from './DownArrow';

export const ICON_NAME_VS_CMP = {
  [ICONS.EYE]: Eye,
  [ICONS.CLOSEEYE]: CloseEye,
  [ICONS.SUCCESS]: Success,
  [ICONS.GRAYSUCCESS]: GraySuccess,
  [ICONS.CALENDAR]: Calendar,
  [ICONS.Failure]: Failure,
  [ICONS.LEFTARROW]: LeftArrow,
  [ICONS.RIGHTARROW]: RightArrow,
  [ICONS.SHAREICON]: ShareIcon,
  [ICONS.PROFILEUPPERLAYERICON]: ProfileUpperLayerIcon,
  [ICONS.LOCK]: Lock,
  [ICONS.DIRECTIONICON]: DirectionIcon,
  [ICONS.DOWNARROW]: DownArrow,
};
