export const ICONS = {
  EYE: 'Eye',
  CLOSEEYE: 'CloseEye',
  CALENDAR: 'Calendar',
  SUCCESS: 'Success',
  Failure: 'Failure',
  GRAYSUCCESS: 'GraySucces',
  LEFTARROW: 'LeftArrow',
  RIGHTARROW: 'RightArrow',
  SHAREICON: 'ShareIcon',
  PROFILEUPPERLAYERICON: 'ProfileUpperLayerIcon',
  LOCK: 'Lock',
  DIRECTIONICON: 'DirectionIcon',
  DOWNARROW: 'DownArrow',
};
