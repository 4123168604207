import * as React from 'react';

const ProfileUpperLayerIcon = (props) => (
  <svg
    width={56}
    height={47}
    viewBox="0 0 56 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.85107 24.4578C7.85107 27.3755 8.42577 30.2647 9.54236 32.9604C10.6589 35.6561 12.2955 38.1054 14.3587 40.1686C16.4219 42.2318 18.8713 43.8684 21.5669 44.985C24.2626 46.1016 27.1518 46.6763 30.0696 46.6763C32.9874 46.6763 35.8766 46.1016 38.5722 44.985C41.2679 43.8684 43.7173 42.2318 45.7804 40.1686C47.8436 38.1054 49.4802 35.6561 50.5968 32.9604C51.7134 30.2647 52.2881 27.3755 52.2881 24.4578C52.2881 21.54 51.7134 18.6508 50.5968 15.9551C49.4802 13.2594 47.8436 10.8101 45.7804 8.74691C43.7173 6.68373 41.2679 5.04713 38.5722 3.93054C35.8766 2.81396 32.9874 2.23926 30.0696 2.23926C27.1518 2.23926 24.2626 2.81396 21.5669 3.93054C18.8713 5.04713 16.4219 6.68373 14.3587 8.74691C12.2955 10.8101 10.6589 13.2594 9.54236 15.9551C8.42577 18.6508 7.85107 21.54 7.85107 24.4578Z"
      fill="white"
    />
    <path
      d="M12.8766 30.9552C12.8766 31.1757 13.0764 31.394 13.4644 31.5977C13.8525 31.8014 14.4213 31.9864 15.1384 32.1423C15.8555 32.2982 16.7068 32.4219 17.6437 32.5063C18.5806 32.5906 19.5847 32.6341 20.5988 32.6341C21.6129 32.6341 22.6171 32.5906 23.554 32.5063C24.4909 32.4219 25.3421 32.2982 26.0592 32.1423C26.7763 31.9864 27.3451 31.8014 27.7332 31.5977C28.1212 31.394 28.321 31.1757 28.321 30.9552C28.321 30.7347 28.1212 30.5164 27.7332 30.3127C27.3451 30.1091 26.7763 29.924 26.0592 29.7681C25.3421 29.6122 24.4909 29.4885 23.554 29.4042C22.6171 29.3198 21.6129 29.2764 20.5988 29.2764C19.5847 29.2764 18.5806 29.3198 17.6437 29.4042C16.7068 29.4885 15.8555 29.6122 15.1384 29.7681C14.4213 29.924 13.8525 30.1091 13.4644 30.3127C13.0764 30.5164 12.8766 30.7347 12.8766 30.9552Z"
      fill="#E1E1E1"
    />
    <path
      d="M19.5917 18.1963V25.2469C19.5917 25.6922 19.7686 26.1192 20.0834 26.4341C20.3983 26.7489 20.8253 26.9258 21.2706 26.9258H43.0935C43.5388 26.9258 43.9658 26.7489 44.2806 26.4341C44.5955 26.1192 44.7724 25.6922 44.7724 25.2469V18.1963H19.5917Z"
      fill="white"
    />
    <path
      d="M19.5917 18.1963V25.2469C19.5917 25.6922 19.7686 26.1192 20.0834 26.4341C20.3983 26.7489 20.8253 26.9258 21.2706 26.9258H43.0935C43.5388 26.9258 43.9658 26.7489 44.2806 26.4341C44.5955 26.1192 44.7724 25.6922 44.7724 25.2469V18.1963H19.5917Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5917 13.4971H44.7724V12.4908C44.7724 12.2704 44.7289 12.0521 44.6446 11.8484C44.5602 11.6448 44.4365 11.4597 44.2806 11.3039C44.1247 11.148 43.9396 11.0244 43.7359 10.9401C43.5323 10.8558 43.314 10.8124 43.0935 10.8125H21.2706C21.0501 10.8124 20.8318 10.8558 20.6281 10.9401C20.4245 11.0244 20.2394 11.148 20.0835 11.3039C19.9276 11.4597 19.8039 11.6448 19.7195 11.8484C19.6352 12.0521 19.5917 12.2704 19.5917 12.4908V13.4971Z"
      fill="white"
    />
    <path
      d="M19.5917 13.4971H44.7724V12.4908C44.7724 12.2704 44.7289 12.0521 44.6446 11.8484C44.5602 11.6448 44.4365 11.4597 44.2806 11.3039C44.1247 11.148 43.9396 11.0244 43.7359 10.9401C43.5323 10.8558 43.314 10.8124 43.0935 10.8125H21.2706C21.0501 10.8124 20.8318 10.8558 20.6281 10.9401C20.4245 11.0244 20.2394 11.148 20.0835 11.3039C19.9276 11.4597 19.8039 11.6448 19.7195 11.8484C19.6352 12.0521 19.5917 12.2704 19.5917 12.4908V13.4971Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8273 10.9062L34.0813 15.0091L39.6713 13.4388L37.8537 6.9744C37.83 6.88951 37.7898 6.81014 37.7353 6.74081C37.6809 6.67148 37.6133 6.61357 37.5365 6.57038C37.4597 6.52719 37.3751 6.49958 37.2876 6.48912C37.2 6.47865 37.1113 6.48555 37.0265 6.50941L21.5959 10.8458C21.6743 10.8606 21.7516 10.8808 21.8273 10.9062Z"
      fill="#E1E1E1"
    />
    <path
      d="M21.8273 10.9062L34.0813 15.0091L39.6713 13.4388L37.8537 6.9744C37.83 6.88951 37.7898 6.81014 37.7353 6.74081C37.6809 6.67148 37.6133 6.61357 37.5365 6.57038C37.4597 6.52719 37.3751 6.49958 37.2876 6.48912C37.2 6.47865 37.1113 6.48555 37.0265 6.50941L21.5959 10.8458C21.6743 10.8606 21.7516 10.8808 21.8273 10.9062Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0856 15.8467L27.5416 19.9336L25.7245 13.4724C25.7005 13.3875 25.6936 13.2986 25.704 13.211C25.7144 13.1233 25.742 13.0386 25.7852 12.9616C25.8283 12.8847 25.8863 12.8169 25.9557 12.7624C26.025 12.7078 26.1045 12.6674 26.1895 12.6436L39.4413 8.91943C39.6119 8.87158 39.7946 8.89325 39.9493 8.97969C40.104 9.06613 40.2182 9.21031 40.2669 9.38071L42.0856 15.8467Z"
      fill="#E1E1E1"
    />
    <path
      d="M42.0856 15.8467L27.5416 19.9336L25.7245 13.4724C25.7005 13.3875 25.6936 13.2986 25.704 13.211C25.7144 13.1233 25.742 13.0386 25.7852 12.9616C25.8283 12.8847 25.8863 12.8169 25.9557 12.7624C26.025 12.7078 26.1045 12.6674 26.1895 12.6436L39.4413 8.91943C39.6119 8.87158 39.7946 8.89325 39.9493 8.97969C40.104 9.06613 40.2182 9.21031 40.2669 9.38071L42.0856 15.8467Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.5918 13.4971H44.7719V16.1832H19.5918V13.4971Z" fill="white" />
    <path
      d="M19.5918 13.4971H44.7719V16.1832H19.5918V13.4971Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.5917 16.1826H44.7718V18.1972H19.5917V16.1826Z" fill="white" />
    <path
      d="M19.5917 16.1826H44.7718V18.1972H19.5917V16.1826Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5917 12.4898C19.592 12.2215 19.6565 11.9572 19.7799 11.719C19.9032 11.4808 20.0818 11.2755 20.3007 11.1205C20.5197 10.9655 20.7725 10.8651 21.0382 10.8278C21.3039 10.7905 21.5746 10.8174 21.8277 10.9062L36.9337 15.9645C37.261 16.0739 37.5457 16.2835 37.7473 16.5636C37.949 16.8437 38.0575 17.1801 38.0575 17.5252V30.284C38.0573 30.5527 37.9933 30.8174 37.8706 31.0565C37.748 31.2956 37.5702 31.502 37.3521 31.6589C37.1339 31.8157 36.8816 31.9184 36.616 31.9586C36.3503 31.9987 36.0789 31.9751 35.8241 31.8897L20.7383 26.8405C20.4044 26.7286 20.114 26.5146 19.9083 26.2288C19.7025 25.9429 19.5918 25.5996 19.5917 25.2474V12.4898Z"
      fill="white"
    />
    <path
      d="M19.5917 12.4898C19.592 12.2215 19.6565 11.9572 19.7799 11.719C19.9032 11.4808 20.0818 11.2755 20.3007 11.1205C20.5197 10.9655 20.7725 10.8651 21.0382 10.8278C21.3039 10.7905 21.5746 10.8174 21.8277 10.9062L36.9337 15.9645C37.261 16.0739 37.5457 16.2835 37.7473 16.5636C37.949 16.8437 38.0575 17.1801 38.0575 17.5252V30.284C38.0573 30.5527 37.9933 30.8174 37.8706 31.0565C37.748 31.2956 37.5702 31.502 37.3521 31.6589C37.1339 31.8157 36.8816 31.9184 36.616 31.9586C36.3503 31.9987 36.0789 31.9751 35.8241 31.8897L20.7383 26.8405C20.4044 26.7286 20.114 26.5146 19.9083 26.2288C19.7025 25.9429 19.5918 25.5996 19.5917 25.2474V12.4898Z"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2705 14.1455L21.9071 14.3584"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.1646 14.7803L35.1104 18.7809"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.7392 18.9922L36.3758 19.2051"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.1829 27.3359L29.8194 27.5494"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.0034 27.9463L35.1471 29.3339"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.7392 29.5322L36.3758 29.7457"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2271 30.9549C14.9602 30.9549 14.7042 30.8489 14.5155 30.6602C14.3268 30.4715 14.2208 30.2155 14.2208 29.9486C14.2208 29.6818 14.3268 29.4258 14.5155 29.2371C14.7042 29.0484 14.9602 28.9424 15.2271 28.9424H19.5915C19.8584 28.9424 20.1144 29.0484 20.3031 29.2371C20.4918 29.4258 20.5978 29.6818 20.5978 29.9486C20.5978 30.2155 20.4918 30.4715 20.3031 30.6602C20.1144 30.8489 19.8584 30.9549 19.5915 30.9549H15.2271Z"
      fill="white"
    />
    <path
      d="M15.2271 30.9549C14.9602 30.9549 14.7042 30.8489 14.5155 30.6602C14.3268 30.4715 14.2208 30.2155 14.2208 29.9486C14.2208 29.6818 14.3268 29.4258 14.5155 29.2371C14.7042 29.0484 14.9602 28.9424 15.2271 28.9424H19.5915C19.8584 28.9424 20.1144 29.0484 20.3031 29.2371C20.4918 29.4258 20.5978 29.6818 20.5978 29.9486C20.5978 30.2155 20.4918 30.4715 20.3031 30.6602C20.1144 30.8489 19.8584 30.9549 19.5915 30.9549H15.2271Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M21.6058 30.9549C21.339 30.9549 21.083 30.8489 20.8943 30.6602C20.7056 30.4715 20.5996 30.2155 20.5996 29.9486C20.5996 29.6818 20.7056 29.4258 20.8943 29.2371C21.083 29.0484 21.339 28.9424 21.6058 28.9424H25.9677C26.2345 28.9424 26.4905 29.0484 26.6792 29.2371C26.8679 29.4258 26.9739 29.6818 26.9739 29.9486C26.9739 30.2155 26.8679 30.4715 26.6792 30.6602C26.4905 30.8489 26.2345 30.9549 25.9677 30.9549H21.6058Z"
      fill="white"
    />
    <path
      d="M21.6058 30.9549C21.339 30.9549 21.083 30.8489 20.8943 30.6602C20.7056 30.4715 20.5996 30.2155 20.5996 29.9486C20.5996 29.6818 20.7056 29.4258 20.8943 29.2371C21.083 29.0484 21.339 28.9424 21.6058 28.9424H25.9677C26.2345 28.9424 26.4905 29.0484 26.6792 29.2371C26.8679 29.4258 26.9739 29.6818 26.9739 29.9486C26.9739 30.2155 26.8679 30.4715 26.6792 30.6602C26.4905 30.8489 26.2345 30.9549 25.9677 30.9549H21.6058Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M14.5555 28.9412C14.2886 28.9412 14.0327 28.8352 13.844 28.6465C13.6553 28.4578 13.5493 28.2018 13.5493 27.935C13.5493 27.6681 13.6553 27.4121 13.844 27.2234C14.0327 27.0347 14.2886 26.9287 14.5555 26.9287H18.9205C19.1874 26.9287 19.4433 27.0347 19.632 27.2234C19.8208 27.4121 19.9268 27.6681 19.9268 27.935C19.9268 28.2018 19.8208 28.4578 19.632 28.6465C19.4433 28.8352 19.1874 28.9412 18.9205 28.9412H14.5555Z"
      fill="white"
    />
    <path
      d="M14.5555 28.9412C14.2886 28.9412 14.0327 28.8352 13.844 28.6465C13.6553 28.4578 13.5493 28.2018 13.5493 27.935C13.5493 27.6681 13.6553 27.4121 13.844 27.2234C14.0327 27.0347 14.2886 26.9287 14.5555 26.9287H18.9205C19.1874 26.9287 19.4433 27.0347 19.632 27.2234C19.8208 27.4121 19.9268 27.6681 19.9268 27.935C19.9268 28.2018 19.8208 28.4578 19.632 28.6465C19.4433 28.8352 19.1874 28.9412 18.9205 28.9412H14.5555Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M20.9368 28.9412C20.8047 28.9412 20.6738 28.9152 20.5517 28.8646C20.4297 28.814 20.3187 28.7399 20.2253 28.6465C20.1319 28.5531 20.0577 28.4421 20.0072 28.32C19.9566 28.198 19.9306 28.0671 19.9306 27.935C19.9306 27.8028 19.9566 27.672 20.0072 27.5499C20.0577 27.4278 20.1319 27.3169 20.2253 27.2234C20.3187 27.13 20.4297 27.0559 20.5517 27.0053C20.6738 26.9547 20.8047 26.9287 20.9368 26.9287H25.3013C25.4334 26.9287 25.5643 26.9547 25.6864 27.0053C25.8085 27.0559 25.9194 27.13 26.0128 27.2234C26.1063 27.3169 26.1804 27.4278 26.231 27.5499C26.2815 27.672 26.3076 27.8028 26.3076 27.935C26.3076 28.0671 26.2815 28.198 26.231 28.32C26.1804 28.4421 26.1063 28.5531 26.0128 28.6465C25.9194 28.7399 25.8085 28.814 25.6864 28.8646C25.5643 28.9152 25.4334 28.9412 25.3013 28.9412H20.9368Z"
      fill="white"
    />
    <path
      d="M20.9368 28.9412C20.8047 28.9412 20.6738 28.9152 20.5517 28.8646C20.4297 28.814 20.3187 28.7399 20.2253 28.6465C20.1319 28.5531 20.0577 28.4421 20.0072 28.32C19.9566 28.198 19.9306 28.0671 19.9306 27.935C19.9306 27.8028 19.9566 27.672 20.0072 27.5499C20.0577 27.4278 20.1319 27.3169 20.2253 27.2234C20.3187 27.13 20.4297 27.0559 20.5517 27.0053C20.6738 26.9547 20.8047 26.9287 20.9368 26.9287H25.3013C25.4334 26.9287 25.5643 26.9547 25.6864 27.0053C25.8085 27.0559 25.9194 27.13 26.0128 27.2234C26.1063 27.3169 26.1804 27.4278 26.231 27.5499C26.2815 27.672 26.3076 27.8028 26.3076 27.935C26.3076 28.0671 26.2815 28.198 26.231 28.32C26.1804 28.4421 26.1063 28.5531 26.0128 28.6465C25.9194 28.7399 25.8085 28.814 25.6864 28.8646C25.5643 28.9152 25.4334 28.9412 25.3013 28.9412H20.9368Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M15.5629 26.9266C15.296 26.9266 15.0401 26.8205 14.8514 26.6318C14.6627 26.4431 14.5566 26.1872 14.5566 25.9203C14.5566 25.6534 14.6627 25.3975 14.8514 25.2088C15.0401 25.0201 15.296 24.9141 15.5629 24.9141H19.9274C20.1942 24.9141 20.4502 25.0201 20.6389 25.2088C20.8276 25.3975 20.9336 25.6534 20.9336 25.9203C20.9336 26.1872 20.8276 26.4431 20.6389 26.6318C20.4502 26.8205 20.1942 26.9266 19.9274 26.9266H15.5629Z"
      fill="white"
    />
    <path
      d="M15.5629 26.9266C15.296 26.9266 15.0401 26.8205 14.8514 26.6318C14.6627 26.4431 14.5566 26.1872 14.5566 25.9203C14.5566 25.6534 14.6627 25.3975 14.8514 25.2088C15.0401 25.0201 15.296 24.9141 15.5629 24.9141H19.9274C20.1942 24.9141 20.4502 25.0201 20.6389 25.2088C20.8276 25.3975 20.9336 25.6534 20.9336 25.9203C20.9336 26.1872 20.8276 26.4431 20.6389 26.6318C20.4502 26.8205 20.1942 26.9266 19.9274 26.9266H15.5629Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M21.9433 26.9266C21.6764 26.9266 21.4204 26.8205 21.2317 26.6318C21.043 26.4431 20.937 26.1872 20.937 25.9203C20.937 25.6534 21.043 25.3975 21.2317 25.2088C21.4204 25.0201 21.6764 24.9141 21.9433 24.9141H26.3077C26.5746 24.9141 26.8306 25.0201 27.0193 25.2088C27.208 25.3975 27.314 25.6534 27.314 25.9203C27.314 26.1872 27.208 26.4431 27.0193 26.6318C26.8306 26.8205 26.5746 26.9266 26.3077 26.9266H21.9433Z"
      fill="white"
    />
    <path
      d="M21.9433 26.9266C21.6764 26.9266 21.4204 26.8205 21.2317 26.6318C21.043 26.4431 20.937 26.1872 20.937 25.9203C20.937 25.6534 21.043 25.3975 21.2317 25.2088C21.4204 25.0201 21.6764 24.9141 21.9433 24.9141H26.3077C26.5746 24.9141 26.8306 25.0201 27.0193 25.2088C27.208 25.3975 27.314 25.6534 27.314 25.9203C27.314 26.1872 27.208 26.4431 27.0193 26.6318C26.8306 26.8205 26.5746 26.9266 26.3077 26.9266H21.9433Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M16.2343 24.9129C15.9674 24.9129 15.7115 24.8069 15.5228 24.6182C15.334 24.4295 15.228 24.1735 15.228 23.9066C15.228 23.6398 15.334 23.3838 15.5228 23.1951C15.7115 23.0064 15.9674 22.9004 16.2343 22.9004H20.5988C20.8656 22.9004 21.1216 23.0064 21.3103 23.1951C21.499 23.3838 21.605 23.6398 21.605 23.9066C21.605 24.1735 21.499 24.4295 21.3103 24.6182C21.1216 24.8069 20.8656 24.9129 20.5988 24.9129H16.2343Z"
      fill="white"
    />
    <path
      d="M16.2343 24.9129C15.9674 24.9129 15.7115 24.8069 15.5228 24.6182C15.334 24.4295 15.228 24.1735 15.228 23.9066C15.228 23.6398 15.334 23.3838 15.5228 23.1951C15.7115 23.0064 15.9674 22.9004 16.2343 22.9004H20.5988C20.8656 22.9004 21.1216 23.0064 21.3103 23.1951C21.499 23.3838 21.605 23.6398 21.605 23.9066C21.605 24.1735 21.499 24.4295 21.3103 24.6182C21.1216 24.8069 20.8656 24.9129 20.5988 24.9129H16.2343Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M15.2271 22.8973C14.9602 22.8973 14.7042 22.7913 14.5155 22.6025C14.3268 22.4138 14.2208 22.1579 14.2208 21.891C14.2208 21.6241 14.3268 21.3682 14.5155 21.1795C14.7042 20.9908 14.9602 20.8848 15.2271 20.8848H19.5915C19.8584 20.8848 20.1144 20.9908 20.3031 21.1795C20.4918 21.3682 20.5978 21.6241 20.5978 21.891C20.5978 22.1579 20.4918 22.4138 20.3031 22.6025C20.1144 22.7913 19.8584 22.8973 19.5915 22.8973H15.2271Z"
      fill="white"
    />
    <path
      d="M15.2271 22.8973C14.9602 22.8973 14.7042 22.7913 14.5155 22.6025C14.3268 22.4138 14.2208 22.1579 14.2208 21.891C14.2208 21.6241 14.3268 21.3682 14.5155 21.1795C14.7042 20.9908 14.9602 20.8848 15.2271 20.8848H19.5915C19.8584 20.8848 20.1144 20.9908 20.3031 21.1795C20.4918 21.3682 20.5978 21.6241 20.5978 21.891C20.5978 22.1579 20.4918 22.4138 20.3031 22.6025C20.1144 22.7913 19.8584 22.8973 19.5915 22.8973H15.2271Z"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M51.4631 7.5065C51.5311 7.21219 51.6804 6.9429 51.894 6.72931C52.1076 6.51572 52.3768 6.36644 52.6712 6.29847L55.0946 5.73921C55.139 5.72885 55.1785 5.70379 55.2068 5.66812C55.2351 5.63245 55.2505 5.58826 55.2505 5.54273C55.2505 5.49719 55.2351 5.453 55.2068 5.41733C55.1785 5.38166 55.139 5.35661 55.0946 5.34624L52.6706 4.78698C52.3763 4.71923 52.107 4.57008 51.8934 4.35656C51.6797 4.14305 51.5305 3.87377 51.4626 3.57948L50.9033 1.15653C50.8932 1.11207 50.8683 1.07237 50.8326 1.04393C50.797 1.01549 50.7527 1 50.7071 1C50.6615 1 50.6173 1.01549 50.5816 1.04393C50.546 1.07237 50.521 1.11207 50.5109 1.15653L49.9522 3.57948C49.8842 3.87379 49.7349 4.14308 49.5213 4.35667C49.3077 4.57026 49.0384 4.71954 48.7441 4.78751L46.3212 5.34624C46.2768 5.35661 46.2373 5.38166 46.209 5.41733C46.1807 5.453 46.1653 5.49719 46.1653 5.54273C46.1653 5.58826 46.1807 5.63245 46.209 5.66812C46.2373 5.70379 46.2768 5.72885 46.3212 5.73921L48.7441 6.29847C49.0384 6.36644 49.3077 6.51572 49.5213 6.72931C49.7349 6.9429 49.8842 7.21219 49.9522 7.5065L50.5114 9.93051C50.5216 9.97497 50.5465 10.0147 50.5821 10.0431C50.6178 10.0716 50.662 10.087 50.7076 10.087C50.7532 10.087 50.7975 10.0716 50.8331 10.0431C50.8688 10.0147 50.8937 9.97497 50.9039 9.93051L51.4631 7.5065Z"
      fill="white"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.62223 19.0821L6.51874 18.4498C6.55427 18.438 6.58519 18.4154 6.6071 18.385C6.62901 18.3547 6.64081 18.3182 6.64081 18.2808C6.64081 18.2434 6.62901 18.2069 6.6071 18.1766C6.58519 18.1463 6.55427 18.1236 6.51874 18.1119L4.96012 17.5923C4.85523 17.5574 4.75991 17.4985 4.68169 17.4204C4.60347 17.3423 4.54449 17.247 4.50942 17.1422L3.98988 15.583C3.97813 15.5475 3.95548 15.5166 3.92515 15.4946C3.89482 15.4727 3.85835 15.4609 3.82093 15.4609C3.78352 15.4609 3.74705 15.4727 3.71672 15.4946C3.68639 15.5166 3.66374 15.5475 3.65199 15.583L3.13245 17.1416C3.09748 17.2466 3.03855 17.342 2.96032 17.4202C2.88209 17.4984 2.78671 17.5574 2.68175 17.5923L1.12206 18.1129C1.08654 18.1247 1.05562 18.1473 1.03371 18.1777C1.01179 18.208 1 18.2445 1 18.2819C1 18.3193 1.01179 18.3558 1.03371 18.3861C1.05562 18.4164 1.08654 18.4391 1.12206 18.4508L2.68175 18.9709C2.78667 19.0059 2.88201 19.0649 2.96023 19.1431C3.03845 19.2213 3.09741 19.3167 3.13245 19.4216L3.65199 20.9813C3.66374 21.0168 3.68639 21.0477 3.71672 21.0696C3.74705 21.0915 3.78352 21.1033 3.82093 21.1033C3.85835 21.1033 3.89482 21.0915 3.92515 21.0696C3.95548 21.0477 3.97813 21.0168 3.98988 20.9813L4.62223 19.0821Z"
      fill="white"
      stroke="black"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ProfileUpperLayerIcon;
