import * as React from 'react';
const DirectionIcon = (props) => (
  <svg
    width={7}
    height={12}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.5 0L6.53109 5.25H0.468911L3.5 0Z" fill="#FFDE03" />
    <path d="M3.5 12L0.468912 6.75L6.53109 6.75L3.5 12Z" fill="#9EA6B4" />
  </svg>
);
export default DirectionIcon;
