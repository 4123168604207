import * as React from 'react';
const ShareOption = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#3FE2D2" />
    <path
      d="M9.82309 11.5975C9.64123 11.2332 9.34169 10.941 8.97304 10.7683C8.60439 10.5956 8.18823 10.5524 7.79201 10.6459C7.39579 10.7393 7.04273 10.9639 6.79005 11.2832C6.53738 11.6024 6.3999 11.9977 6.3999 12.4049C6.3999 12.812 6.53738 13.2073 6.79005 13.5265C7.04273 13.8458 7.39579 14.0704 7.79201 14.1638C8.18823 14.2573 8.60439 14.2141 8.97304 14.0414C9.34169 13.8687 9.64123 13.5765 9.82309 13.2122"
      stroke="white"
      strokeWidth={1.1135}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9846 10.8236C15.3789 10.8246 15.7628 10.6968 16.0779 10.4597C16.3931 10.2225 16.6222 9.889 16.7306 9.50974C16.8389 9.13049 16.8206 8.72622 16.6783 8.35834C16.5361 7.99046 16.2777 7.67906 15.9424 7.47146C15.6071 7.26385 15.2132 7.17136 14.8205 7.20805C14.4279 7.24474 14.058 7.4086 13.7669 7.67474C13.4758 7.94087 13.2796 8.29475 13.2079 8.68262C13.1362 9.0705 13.1931 9.47117 13.3698 9.82379"
      stroke="white"
      strokeWidth={1.1135}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3698 14.9847C13.194 15.3365 13.1378 15.736 13.2096 16.1226C13.2814 16.5092 13.4773 16.8619 13.7676 17.127C14.0579 17.3922 14.4268 17.5554 14.8182 17.5919C15.2096 17.6284 15.6023 17.5363 15.9366 17.3294C16.2709 17.1224 16.5287 16.8121 16.6707 16.4454C16.8127 16.0788 16.8313 15.6758 16.7236 15.2976C16.616 14.9194 16.3879 14.5866 16.074 14.3498C15.7602 14.113 15.3777 13.985 14.9845 13.9854"
      stroke="white"
      strokeWidth={1.1135}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9842 10.8231C14.6488 10.8228 14.3201 10.7291 14.0349 10.5525C13.7497 10.3759 13.5193 10.1234 13.3695 9.82324L9.82324 11.5966C9.94976 11.8469 10.0157 12.1236 10.0157 12.4041C10.0157 12.6847 9.94976 12.9613 9.82324 13.2117L13.3695 14.9855C13.5193 14.6853 13.7497 14.4328 14.0349 14.2562C14.3201 14.0797 14.6488 13.986 14.9842 13.9857"
      stroke="white"
      strokeWidth={1.1135}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ShareOption;
