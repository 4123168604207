import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components';
import Icons from '../../../components/icons';
import { ICONS } from '../../../constants/icons.constanst';
import BigMan from '../../../assets/BigMan.png';

import styles from './noLeads.module.scss';
import { ROUTES } from '../../../constants/routes.constant';

const NoLeads = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.NoMoney}>
      {/* <div className={styles.upperLayer}>
        <div className={styles.icon}>
          <Icons type={ICONS.PROFILEUPPERLAYERICON} />
        </div>

        <div className={styles.dataLayer}>
          <div>
            <p className={styles.wallet}>צברת בארנק</p>
            <div className={styles.number}>
              <p className={styles.value}>0</p>
              <p className={styles.sign}>₪</p>
            </div>
          </div>
          <Button buttonClassName={styles.btn}>משיכה</Button>
        </div>

        <div className={styles.secondLayer}>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}></div>
            <p className={styles.number}>₪50</p>
          </div>
          <p className={styles.text}>* יש לצבור ₪50 כדי למשוך</p>
        </div>
      </div> */}
      <div className={styles.lowerLayer}>
        <p className={styles.noLeads}>{`עדיין אין רווחים? \nלמה אתה מחכה, תתחיל להרוויח!`}</p>
        <Button buttonClassName={styles.btn1} onClick={() => navigate(ROUTES.HOME_PAGE)}>
          קדימה
        </Button>
        <div className={styles.imgs}>
          <img src={BigMan} alt="" />
        </div>
      </div>
    </div>
  );
};

export default NoLeads;
