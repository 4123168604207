import * as React from 'react';
const Avtar = (props) => (
  <svg
    width={86}
    height={87}
    viewBox="0 0 86 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={43} cy={43.2783} r={43} fill="#3FE2D2" />
    <mask
      id="mask0_2494_10986"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={86}
      height={87}
    >
      <circle cx={43} cy={43.2783} r={43} fill="white" />
    </mask>
    <g mask="url(#mask0_2494_10986)">
      <path
        d="M41.9267 103.337V79.4844L37.0159 81.1607V97.3148L32.2356 101.811C31.5378 102.468 32.0023 103.639 32.9603 103.639H41.6245C41.7914 103.639 41.9267 103.504 41.9267 103.337Z"
        fill="#222222"
      />
      <path
        d="M46.46 103.337V79.4844L51.3708 81.1607V97.3148L56.1511 101.811C56.8489 102.468 56.3844 103.639 55.4264 103.639H46.7622C46.5953 103.639 46.46 103.504 46.46 103.337Z"
        fill="#222222"
      />
      <path
        d="M22.724 66.3589C19.3869 73.6766 17.475 80.6401 17.4199 84.8662"
        stroke="#222222"
        strokeWidth={3.14575}
        strokeLinecap="round"
      />
      <path
        d="M64.9459 66.3589C68.283 73.6766 70.1949 80.6401 70.25 84.8662"
        stroke="#222222"
        strokeWidth={3.14575}
        strokeLinecap="round"
      />
      <path
        d="M21.1074 23.4378C21.1074 17.7835 25.6912 13.1997 31.3455 13.1997H55.9169C61.5713 13.1997 66.155 17.7835 66.155 23.4378V66.2918C66.155 77.6811 56.9222 86.914 45.5329 86.914H41.7296C30.3403 86.914 21.1074 77.6811 21.1074 66.2918V23.4378Z"
        fill="#A573FE"
      />
      <path
        d="M21.1074 65.1309H66.155V84.5769C66.155 88.2447 63.1817 91.218 59.514 91.218H27.7485C24.0807 91.218 21.1074 88.2447 21.1074 84.5769V65.1309Z"
        fill="#222222"
      />
      <path
        d="M35.6445 46.9575C37.2289 48.6412 40.2354 49.7777 43.6837 49.7777C47.1319 49.7777 50.1385 48.6412 51.7228 46.9575"
        stroke="black"
        strokeWidth={2.4176}
        strokeLinecap="round"
      />
      <ellipse cx={35.5248} cy={35.8728} rx={6.64104} ry={6.69804} fill="white" />
      <ellipse cx={35.6166} cy={36.038} rx={2.62146} ry={2.64396} fill="black" />
      <ellipse cx={51.9535} cy={35.8719} rx={6.64104} ry={6.69804} fill="white" />
      <ellipse cx={52.0453} cy={36.038} rx={2.62146} ry={2.64396} fill="black" />
    </g>
  </svg>
);
export default Avtar;
