export interface UserData {
  active_leads_count?: number;
  balance?: number;
  birthday?: string;
  clicks_count?: number;
  created_at?: number;
  degree?: number;
  email?: string;
  firstname?: string;
  id?: string;
  identity_id?: string;
  lastname?: string;
  phone?: string;
  register_count?: number;
  score?: number;
  shares_count?: number;
  tax?: number;
  total_leads_count?: number;
  username?: string;
  withdraw?: number;
  bank?: number;
  branch?: number;
  account?: string;
  owner_name?: string;
  bank_acc_holder_id?: string;
}

export interface User {
  isAuthenticated?: boolean;
  isNewUser?: boolean;
  isLoading?: boolean;
  userData?: UserData;
}

export const EMPTY_USER: User = {
  isAuthenticated: false,
  isNewUser: false,
  isLoading: false,
  userData: {
    active_leads_count: 0,
    balance: 0,
    birthday: '',
    clicks_count: 0,
    created_at: 0,
    degree: 0,
    email: '',
    firstname: '',
    id: '',
    identity_id: '',
    lastname: '',
    phone: '',
    register_count: 0,
    score: 0,
    shares_count: 0,
    tax: 0,
    total_leads_count: 0,
    username: '',
    withdraw: 0,
    bank: 0,
    branch: 0,
    account: '',
    owner_name: '',
    bank_acc_holder_id: '',
  },
};
